import { IpPermission } from "@aws-sdk/client-ec2"
import _ from "lodash"
import { AwsResource } from "../../../../../common/model/common"
import { SecurityGroupResource } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  tableSection,
} from "../../asset-content"

const ipPermissionsTable = (
  title: string,
  asset: AwsResource,
  permissionsData: () => ReadonlyArray<IpPermission> | undefined,
) => {
  const permissions = permissionsData() ?? []

  return tableSection(() => {
    const data = () => {
      return permissions
        .map((p) => {
          const permissions = []
          if (p.IpRanges) {
            permissions.push(
              ...p.IpRanges.map((ipRange) => ({
                ...p,
                ...ipRange,
              })),
            )
          }

          if (p.Ipv6Ranges) {
            permissions.push(
              ...p.Ipv6Ranges.map((ipv6Range) => ({
                ...p,
                ...ipv6Range,
              })),
            )
          }

          if (p.UserIdGroupPairs) {
            permissions.push(
              ...p.UserIdGroupPairs.map((pair) => ({
                ...p,
                ...pair,
              })),
            )
          }

          if (p.PrefixListIds) {
            permissions.push(
              ...p.PrefixListIds.map((prefix) => ({
                ...p,
                ...prefix,
              })),
            )
          }

          return permissions
        })
        .flat()
    }

    return {
      asset,
      title,
      enabled: !_.isEmpty(permissions),
      data,
    }
  })
}

export const awsVpcSecurityGroupsContent = assetContent<SecurityGroupResource>({
  type: "vpc/security-group",
  list: {
    exclude: ["Description"],
    filtered: ["VpcId"],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["IpPermissionsEgress", "IpPermissions"],
    }),
    ipPermissionsTable("IP Permissions", asset, () => asset.IpPermissions),
    ipPermissionsTable(
      "IP Permissions Egress",
      asset,
      () => asset.IpPermissionsEgress,
    ),
  ],
})
