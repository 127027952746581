import { Button, Divider, Layout, Menu, MenuProps, Typography } from "antd"
import { Auth } from "aws-amplify"
import { toHeaderCase } from "js-convert-case"
import React, { useContext, useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { ScrollToTop } from "./ScrollToTop"
import { UserSessionContext } from "./UserSessionContext"
import { ThemeContext } from "./context/ClientsContext"
import { storage } from "./storage/Storage"

const { Header, Content, Footer } = Layout

const buildInfo = {
  version: process.env.METAMESH_VERSION ?? "local",
  timestamp: process.env.METAMESH_TIMESTAMP ?? Date.now(),
  commit: process.env.METAMESH_COMMIT ?? "commit",
}

export const AuthenticatedContent: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { themeId, setThemeId } = useContext(ThemeContext)
  const session = useContext(UserSessionContext)
  const [topMenuItemKeys, setTopMenuItemKeys] = useState<string[]>([])

  useEffect(() => {
    document.body.style.backgroundColor =
      themeId === "dark" ? "#000000" : "#f5f5f5"
  }, [themeId])

  useEffect(() => {
    session.roleProvider().then((role) => {
      if (role === "admin") {
        setTopMenuItemKeys(["clients", "admin"])
      } else {
        setTopMenuItemKeys(["clients"])
      }
    })
  }, [session])

  const topMenuItems: MenuProps["items"] = topMenuItemKeys.map((key) => ({
    key,
    label: toHeaderCase(key),
    onClick: () => {
      navigate(`/app/${key}`)
    },
  }))

  const topMenuSelectedItemKey = topMenuItemKeys.find((key) =>
    location.pathname.startsWith(`/app/${key}`),
  )

  return (
    <Layout>
      <Header style={{ display: "flex", alignItems: "center" }}>
        <Layout>
          <Menu
            mode="horizontal"
            theme="dark"
            selectedKeys={[topMenuSelectedItemKey]}
            items={topMenuItems}
          />
        </Layout>
      </Header>
      <Content style={{ padding: "0 50px" }}>
        <Outlet />
      </Content>
      <Footer style={{ textAlign: "center" }}>
        <Typography.Text style={{ marginRight: "5px" }}>
          {buildInfo.version}
        </Typography.Text>
        <Divider type="vertical" />
        <Button type="text" size="small" onClick={() => Auth.signOut()}>
          Sign Out
        </Button>
        <Divider type="vertical" />
        <Button
          size="small"
          type="text"
          onClick={() => {
            const newThemeId = themeId === "dark" ? "light" : "dark"
            setThemeId(newThemeId)
            storage.setThemeId(newThemeId)
          }}
        >
          {themeId === "dark"
            ? "Switch to Light theme"
            : "Switch to Dark theme"}
        </Button>
        <Divider type="vertical" />
        Hit
        <Typography.Text style={{ marginLeft: "5px" }} keyboard>
          Command + k
        </Typography.Text>
        <Typography.Text style={{ marginLeft: "5px" }}>
          for quick search
        </Typography.Text>
      </Footer>
      <ScrollToTop />
    </Layout>
  )
}
