import { RuleAsset } from "../../../../../common/model/data/aws-data"
import { assetContent, basicAssetDetails } from "../../asset-content"

export const awsCloudwatchEventRules = assetContent<RuleAsset, RuleAsset>({
  type: "cloudwatch-events/rule",
  list: {
    exclude: ["EventPattern", "Description"],
    filtered: ["State", "ManagedBy"],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["EventPattern"],
      additional: [
        {
          label: "Event Pattern",
          value: () => JSON.parse(asset.EventPattern),
          render: (pattern) => (
            <pre>{JSON.stringify(pattern, undefined, 2)}</pre>
          ),
        },
      ],
    }),
  ],
})
