import _ from "lodash"
import md5 from "md5"
import { Link } from "react-router-dom"
import { RestApiStageAsset } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
  tableSection,
} from "../../asset-content"

export const awsApigwRestApiStages = assetContent<
  RestApiStageAsset,
  RestApiStageAsset
>({
  type: "apigw/rest-api-stage",
  list: {
    filtered: ["cacheClusterEnabled", "cacheClusterStatus", "tracingEnabled"],
    exclude: ["description"],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["methodSettings", "accessLogSettings", "restApiId"],
      additional: [
        {
          label: "REST API Id",
          value: () => asset.restApiId,
          render: (restApiId: string) => (
            <Link to={`../../../rest-api/${md5(restApiId)}`} relative="path">
              {restApiId}
            </Link>
          ),
        },
      ],
    }),
    descriptionSection({
      asset: asset.accessLogSettings,
      title: "Access Log Settings",
    }),
    tableSection({
      asset,
      title: "Method Settings",
      enabled: !_.isEmpty(asset.methodSettings),
      data: () =>
        Object.entries(asset.methodSettings).map(([method, settings]) => ({
          method,
          ...settings,
        })),
    }),
  ],
})
