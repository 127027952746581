import md5 from "md5"
import React, { useContext, useEffect, useReducer, useRef } from "react"
import { Outlet, useParams } from "react-router-dom"
import { AwsResource } from "../../../common/model/common"
import { ApiContext } from "../ApiContext"
import { AwsSearchContext } from "../UserSessionContext"
import {
  AssetsListingContext,
  assetsListingReducer,
  AssetsListingReducerContext,
  AssetsListingState,
  SelectedClientContext,
  SelectedClientReadyState,
} from "../context/ClientsContext"

const createInitialListingState = (): AssetsListingState => ({
  status: "loading",
  assets: [],
})

export const AssetsListContainerContent: React.FC = () => {
  const [assetsListingState, dispatchAssetsListing] = useReducer(
    assetsListingReducer,
    undefined,
    createInitialListingState,
  )
  const api = useContext(ApiContext)
  const awsSearchState = useContext(AwsSearchContext)
  const selectedClient = useContext(SelectedClientContext)
  const { assetFamily, assetType } = useParams()
  const currentDependencies = useRef("")

  useEffect(() => {
    if (!(selectedClient instanceof SelectedClientReadyState)) {
      return
    }

    const { id, syncJobs } = selectedClient.client

    const dependencies = md5(
      JSON.stringify({ assetFamily, assetType, awsSearchState, id, syncJobs }),
    )

    // TODO: Is there a better way to prevent this from executing on every render?
    if (currentDependencies.current === dependencies) {
      return
    }

    currentDependencies.current = dependencies

    dispatchAssetsListing({ action: "loading", assetFamily, assetType })

    api
      .listAwsResourcesByType({
        clientId: id,
        syncJob: syncJobs[0],
        assetFamily,
        assetType,
      })
      .then((r) => {
        const isDefined = (a: AwsResource): boolean =>
          a !== undefined && a !== null

        const byAccountId = (a: AwsResource): boolean =>
          awsSearchState.accountIds.length === 0 ||
          awsSearchState.accountIds.includes(a._meta.accountId)

        const byRegion = (a: AwsResource): boolean =>
          awsSearchState.regions.length === 0 ||
          awsSearchState.regions.includes(a._meta.region)

        const byOrganization = (a: AwsResource): boolean =>
          awsSearchState.organizationIds.length === 0 ||
          awsSearchState.organizationIds.includes(a._meta.organizationId)

        const byAssetGroup = (a: AwsResource): boolean =>
          awsSearchState.assetGroups.length === 0 ||
          awsSearchState.assetGroups.some((group) =>
            a._meta.groups?.includes(group),
          )

        const assetData = r
          .filter(isDefined)
          .filter(byOrganization)
          .filter(byAccountId)
          .filter(byRegion)
          .filter(byAssetGroup)

        dispatchAssetsListing({
          action: "ready",
          assets: assetData,
        })
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error)
      })
  }, [assetFamily, assetType, selectedClient, api, awsSearchState])

  return (
    <AssetsListingContext.Provider value={assetsListingState}>
      <AssetsListingReducerContext.Provider value={dispatchAssetsListing}>
        <Outlet />
      </AssetsListingReducerContext.Provider>
    </AssetsListingContext.Provider>
  )
}
