import { ClassicLoadBalancerResource } from "../../../../../common/model/data/aws-data"
import { assetContent } from "../../asset-content"

export const awsElbClassicLoadBalancers = assetContent<
  ClassicLoadBalancerResource,
  ClassicLoadBalancerResource
>({
  type: "elb/classic-load-balancer",
  list: {
    exclude: ["CanonicalHostedZoneNameID", "CanonicalHostedZoneName"],
    filtered: ["VPCId", "Scheme"],
  },
})
