import { HttpClient } from "./http-client"
import { extractData } from "./response-mappers"

const BILLING_PATH = "/api/billing/assets"

export interface BillingApi {
  getAssetCosts: (billingId: string) => Promise<any>
}

export const billingApiUsing = ({ get }: HttpClient): BillingApi => ({
  getAssetCosts: (billingId) =>
    get(`${BILLING_PATH}/${billingId}`).then(extractData),
})
