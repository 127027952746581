import { RepositoryAsset } from "../../../../../common/model/data/aws-data"
import { assetContent } from "../../asset-content"

export const awsEcrRepositories = assetContent<
  RepositoryAsset,
  RepositoryAsset
>({
  type: "ecr/repository",
  list: { filtered: ["imageTagMutability"] },
})
