import { RuleAsset } from "../../../../../common/model/data/aws-data"
import { assetContent } from "../../asset-content"

export const awsEventRules = assetContent<RuleAsset, RuleAsset>({
  type: "cloudwatch-events/rule",
  list: {
    filtered: ["State"],
    exclude: ["Description", "EventPattern"],
  },
})
