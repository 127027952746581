import { Breadcrumb, Col, Row } from "antd"
import React, { useContext } from "react"
import { Link, useParams } from "react-router-dom"
import { assetFamilyDisplayName, assetTypeDisplayName } from "../content/texts"
import { AwsSummaryContext } from "../context/ClientsContext"

export const AssetsFamilyContent: React.FC = () => {
  const { assetFamily } = useParams()
  const summary = useContext(AwsSummaryContext)

  if (!summary) {
    return <div></div>
  }

  const assetTypes = new Set<string>()

  for (const orgSummary of Array.from(
    Object.values(summary.organizationsData),
  )) {
    for (const accountSummary of Array.from(Object.values(orgSummary))) {
      for (const regionSummary of Array.from(Object.values(accountSummary))) {
        for (const [family, familyData] of Array.from(
          Object.entries(regionSummary),
        )) {
          if (family === assetFamily) {
            Object.keys(familyData).forEach((assetType) =>
              assetTypes.add(assetType),
            )
          }
        }
      }
    }
  }

  return (
    <div>
      <Row>
        <Col span={20}>
          <Breadcrumb
            style={{ paddingTop: "20px" }}
            items={[
              {
                title: assetFamilyDisplayName(assetFamily),
              },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <ul>
          {Array.from(assetTypes)
            .sort()
            .map((assetType) => {
              return (
                <li>
                  <Link to={assetType}>
                    {assetTypeDisplayName(assetFamily, assetType)}
                  </Link>
                </li>
              )
            })}
        </ul>
      </Row>
    </div>
  )
}
