import { Col, Row, Spin, Statistic, Typography } from "antd"
import React, { useContext, useEffect, useState } from "react"
import CountUp from "react-countup"
import { AwsAuditSummary } from "../../../common/model/content/aws-content"
import { ApiContext } from "../ApiContext"
import {
  AwsSummaryContext,
  SelectedClientContext,
  SelectedClientReadyState,
} from "../context/ClientsContext"

const formatter = (value: number) => <CountUp end={value} separator="" />
const currencyFormatter = (value: number) => (
  <CountUp end={value} separator="" decimalPlaces={2} prefix="$" />
)

export const AwsProductSummaryPage: React.FC = (props) => {
  const api = useContext(ApiContext)
  const summary = useContext(AwsSummaryContext)
  const selectedClient = useContext(SelectedClientContext)
  const [auditSummary, setAuditSummary] = useState<AwsAuditSummary>()

  useEffect(() => {
    if (!(selectedClient instanceof SelectedClientReadyState)) {
      return
    }

    const { id, syncJobs } = selectedClient.client

    api
      .getAwsAuditSummary({ clientId: id, syncJob: syncJobs[0] })
      .then((data) => {
        setAuditSummary(data)
      })
  }, [api, selectedClient])

  if (!summary || !auditSummary) {
    return (
      <div style={{ paddingTop: "20px" }}>
        <Spin delay={500} />
      </div>
    )
  }

  return (
    <div>
      <Typography.Title level={3}>Assets</Typography.Title>
      <Row gutter={16} style={{ paddingTop: "20px" }}>
        <Col span={8}>
          <Statistic
            title="Assets"
            value={summary.assets}
            formatter={formatter}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Accounts"
            value={Object.keys(summary.accounts).length}
            formatter={formatter}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Regions"
            value={summary.regions.length}
            formatter={formatter}
          />
        </Col>
      </Row>

      <Typography.Title level={3}>Audit</Typography.Title>
      <Row gutter={16} style={{ paddingTop: "20px" }}>
        <Col span={8}>
          <Statistic
            title="Total"
            value={auditSummary.total}
            formatter={formatter}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title="Potential Monthly Savings"
            value={auditSummary.cost}
            formatter={currencyFormatter}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Low"
            value={auditSummary.low}
            formatter={formatter}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Medium"
            value={auditSummary.medium}
            formatter={formatter}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="High"
            value={auditSummary.high}
            formatter={formatter}
          />
        </Col>
      </Row>
    </div>
  )
}
