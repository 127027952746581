import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { App, Button, List } from "antd"
import React, { ReactNode, useContext } from "react"
import {
  AwsAccountProductConfig,
  AwsOrganizationProductConfig,
  ProductConfig,
} from "../../../../common/model/asset-data-sources"
import { ApiContext } from "../../ApiContext"
import {
  DescriptionSpec,
  SimpleDescriptions,
} from "../../components/SimpleDescriptions"

type AwsOrganizationAssetDataSourceContentProps = {
  dataSource: AwsOrganizationProductConfig
  onDataSourceDeleted: () => Promise<void>
  openEditDrawer: (dataSource: AwsOrganizationProductConfig) => void
}

const AwsOrganizationAssetDataSourceContent: React.FC<
  AwsOrganizationAssetDataSourceContentProps
> = ({ dataSource, openEditDrawer, onDataSourceDeleted }) => {
  const { modal, notification } = App.useApp()
  const api = useContext(ApiContext)

  const properties: DescriptionSpec<AwsOrganizationProductConfig>[] = [
    "id",
    "clientId",
    "product",
    "type",
    "organizationName",
    "organizationAdminAccountId",
    "accountReaderRoleName",
    "organizationReaderRoleName",
    "includedAccountIds",
    "excludedAccountIds",
    { key: "includedOUPaths", label: "Included OU Paths" },
    "regions",
    "inheritTags",
    "syncWindow",
    "billingConfigId",
  ]

  const showConfirmDeleteModal = async () => {
    const confirmed = await modal.confirm({
      title: "Delete data source",
      content: "Delete this data source?",
    })

    if (confirmed) {
      api
        .deleteAssetDataSource(dataSource.clientId, dataSource.id)
        .then(() => {
          notification.success({ message: "Data source deleted" })
          onDataSourceDeleted()
        })
        .catch((e) => {
          console.error(e)
          notification.error({
            message: "Failed to delete data source",
            description: e.message,
          })
        })
    }
  }

  return (
    <List.Item
      key={dataSource.id}
      actions={[
        <Button
          type="text"
          title="Edit"
          onClick={() => openEditDrawer(dataSource)}
          icon={<EditOutlined />}
        />,
        <Button
          type="text"
          title="Delete"
          onClick={showConfirmDeleteModal}
          icon={<DeleteOutlined />}
        />,
      ]}
    >
      <List.Item.Meta title={dataSource.name} />
      <SimpleDescriptions properties={properties} value={dataSource} />
    </List.Item>
  )
}

type AwsAccountAssetDataSourceContentProps = {
  dataSource: AwsAccountProductConfig
  onDataSourceDeleted: () => Promise<void>
  openEditDrawer: (dataSource: AwsAccountProductConfig) => void
}

const AwsAccountAssetDataSourceContent: React.FC<
  AwsAccountAssetDataSourceContentProps
> = ({ dataSource, openEditDrawer, onDataSourceDeleted }) => {
  const { modal, notification } = App.useApp()
  const api = useContext(ApiContext)

  const properties: DescriptionSpec<AwsAccountProductConfig>[] = [
    "id",
    "clientId",
    "product",
    "type",
    "accountReaderRoleName",
    "regions",
    "syncWindow",
    "billingConfigId",
  ]

  const showConfirmDeleteModal = async () => {
    const confirmed = await modal.confirm({
      title: "Delete data source",
      content: "Delete this data source?",
    })

    if (confirmed) {
      api
        .deleteAssetDataSource(dataSource.clientId, dataSource.id)
        .then(() => {
          notification.success({ message: "Data source deleted" })
          onDataSourceDeleted()
        })
        .catch((e) => {
          console.error(e)
          notification.error({
            message: "Failed to delete data source",
            description: e.message,
          })
        })
    }
  }

  return (
    <List.Item
      key={dataSource.id}
      actions={[
        <Button
          type="text"
          title="Edit"
          onClick={() => openEditDrawer(dataSource)}
          icon={<EditOutlined />}
        />,
        <Button
          type="text"
          title="Delete"
          onClick={showConfirmDeleteModal}
          icon={<DeleteOutlined />}
        />,
      ]}
    >
      <List.Item.Meta title={dataSource.name} />
      <SimpleDescriptions properties={properties} value={dataSource} />
    </List.Item>
  )
}

type RenderAssetDataSourcesProps = {
  dataSource: ProductConfig
  onDataSourceDeleted: () => Promise<void>
  openEditDrawer: (dataSource: ProductConfig) => void
}

export const renderAssetDataSources = ({
  dataSource,
  onDataSourceDeleted,
  openEditDrawer,
}: RenderAssetDataSourcesProps): ReactNode => {
  if (dataSource.product === "aws") {
    if (dataSource.type === "organization") {
      return (
        <AwsOrganizationAssetDataSourceContent
          dataSource={dataSource as AwsOrganizationProductConfig}
          onDataSourceDeleted={onDataSourceDeleted}
          openEditDrawer={openEditDrawer}
        />
      )
    } else if (dataSource.type === "account") {
      return (
        <AwsAccountAssetDataSourceContent
          dataSource={dataSource as AwsAccountProductConfig}
          onDataSourceDeleted={onDataSourceDeleted}
          openEditDrawer={openEditDrawer}
        />
      )
    } else {
      throw new Error(
        `Unknown asset data source type '${dataSource.type}' for product '${dataSource.product}'`,
      )
    }
  } else {
    throw new Error(`Unknown asset data source product '${dataSource.product}'`)
  }
}
