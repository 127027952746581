import {
  AssetDataSourcesApi,
  assetDataSourcesApiUsing,
} from "./asset-data-sources-api"
import {
  AssetGroupRulesApi,
  assetGroupRulesApiUsing,
} from "./asset-group-rules-api"
import { AssetsApi, assetsApiUsing } from "./assets-api"
import {
  AuditSettingsApi,
  assetAuditSettingsApiUsing,
} from "./audit-settings-api"
import { BillingApi, billingApiUsing } from "./billing-api"
import {
  BillingConfigsApi,
  billingConfigsApiUsing,
} from "./billing-configs-api"
import { ClientsApi, clientsApiUsing } from "./clients-api"
import { ContentApi, contentApiUsing } from "./content-api"
import { httpClientUsing } from "./http-client"
import { SyncJobApi, syncJobApiUsing } from "./sync-job-api"
import { UsersApi, usersApiUsing } from "./users-api"

export type Api = ClientsApi &
  UsersApi &
  SyncJobApi &
  ContentApi &
  BillingApi &
  AssetDataSourcesApi &
  BillingConfigsApi &
  AssetGroupRulesApi &
  AssetsApi &
  AuditSettingsApi

export const createApiUsing = (authTokenProvider: () => Promise<string>) => {
  const httpClient = httpClientUsing(authTokenProvider)
  return {
    ...clientsApiUsing(httpClient),
    ...usersApiUsing(httpClient),
    ...syncJobApiUsing(httpClient),
    ...contentApiUsing(httpClient),
    ...billingApiUsing(httpClient),
    ...assetDataSourcesApiUsing(httpClient),
    ...billingConfigsApiUsing(httpClient),
    ...assetGroupRulesApiUsing(httpClient),
    ...assetsApiUsing(httpClient),
    ...assetAuditSettingsApiUsing(httpClient),
  }
}
