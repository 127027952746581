import { ImageAsset } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  tableSection,
} from "../../asset-content"

export const awsEc2Images = assetContent<ImageAsset, ImageAsset>({
  type: "ec2/image",
  list: {
    filtered: [
      "Architecture",
      "ImageType",
      "Public",
      "State",
      "EnaSupport",
      "Hypervisor",
      "RootDeviceType",
      "SriovNetSupport",
      "VirtualizationType",
    ],
    exclude: ["Description"],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["BlockDeviceMappings"],
    }),
    tableSection({
      asset,
      title: "Block Device Mappings",
      data: () =>
        asset.BlockDeviceMappings.map((d) => {
          return {
            DeviceName: d.DeviceName,
            ...d.Ebs,
          }
        }),
    }),
  ],
})

/*
[
  {
    "DeviceName": "/dev/sda1",
    "Ebs": {
      "DeleteOnTermination": true,
      "SnapshotId": "snap-0358c001fd65daa70",
      "VolumeSize": 100,
      "VolumeType": "gp2",
      "Encrypted": true
    }
  }
]
 */
