import { ConfigRuleAsset } from "../../../../../common/model/data/aws-data"
import { assetContent, basicAssetDetails } from "../../asset-content"

export const awsConfigRules = assetContent<ConfigRuleAsset, ConfigRuleAsset>({
  type: "config/rule",
  list: {
    filtered: ["ConfigRuleState"],
    exclude: ["Description"],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: [],
    }),
  ],
})
