import React, { useContext } from "react"
import { withErrorBoundary } from "react-error-boundary"
import { AssetDetailsContext } from "../context/ClientsContext"
import { ErrorFallback } from "../errors/ErrorFallback"

const AssetDetailsRawContentInternal: React.FC = () => {
  const assetDetailsState = useContext(AssetDetailsContext)

  return (
    <div>
      <pre>{JSON.stringify(assetDetailsState.asset, undefined, 2)}</pre>
    </div>
  )
}

export const AssetDetailsRawContent = withErrorBoundary(
  AssetDetailsRawContentInternal,
  { FallbackComponent: ErrorFallback },
)
