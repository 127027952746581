import { IdentityPoolAsset } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  tableSection,
} from "../../asset-content"

export const awsCognitoIdentityPools = assetContent<
  IdentityPoolAsset,
  IdentityPoolAsset
>({
  type: "cognito/identity-pool",
  list: {
    filtered: ["AllowUnauthenticatedIdentities", "AllowClassicFlow"],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["CognitoIdentityProviders"],
    }),
    tableSection({
      asset: asset.CognitoIdentityProviders,
      title: "Cognito Identity Providers",
      data: () => asset.CognitoIdentityProviders,
    }),
  ],
})
