import {
  AuditSettingDto,
  UpdateAuditSettingRequest,
} from "../../../common/model/api"
import { HttpClient } from "./http-client"
import { extractData } from "./response-mappers"

const basePath = (clientId: string) => `/api/clients/${clientId}/audit-settings`

export interface AuditSettingsApi {
  listAuditSettings: (
    clientId: string,
  ) => Promise<ReadonlyArray<AuditSettingDto>>
  deleteAuditSetting: (
    clientId: string,
    product: string,
    assetType: string,
    rule: string,
    name: string,
  ) => Promise<void>
  updateAuditSetting: (
    clientId: string,
    request: UpdateAuditSettingRequest,
  ) => Promise<void>
}

export const assetAuditSettingsApiUsing = ({
  get,
  put,
  del,
}: HttpClient): AuditSettingsApi => ({
  listAuditSettings: (clientId) => get(basePath(clientId)).then(extractData),
  deleteAuditSetting: (clientId, product, assetType, rule, name) =>
    del(`${basePath(clientId)}/${assetType}/${rule}/${name}`),
  updateAuditSetting: (clientId, request) =>
    put(`${basePath(clientId)}`, {
      settings: [request],
    }),
})
