import { BeanstalkApplicationVersionAsset } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
} from "../../asset-content"

export const awsBeanstalkApplicationVersions = assetContent<
  BeanstalkApplicationVersionAsset,
  BeanstalkApplicationVersionAsset
>({
  type: "beanstalk/application-version",
  list: {
    exclude: ["Description"],
    filtered: ["ApplicationName", "Status"],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["SourceBundle"],
    }),
    descriptionSection({
      asset: asset.SourceBundle,
      title: "Source Bundle",
    }),
  ],
})
