import { DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import {
  App,
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  List,
  Select,
  Space,
  Typography,
} from "antd"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
  CreateAwsSimpleAssetGroupRuleRequest,
  UpdateAwsSimpleAssetGroupRuleRequest,
} from "../../../../common/model/api"
import {
  AssetGroupRule,
  AwsSimpleAssetGroupRule,
  AwsSimpleCondition,
  PropertyType,
} from "../../../../common/model/asset-group-rules"
import { ApiContext } from "../../ApiContext"
import { renderAssetGroupRule, renderOperator } from "./AssetGroupRules"

interface AssetGroupRuleFormProps {
  editedRule?: AssetGroupRule
  onRuleUpdated: () => Promise<void>
  cancel: () => void
  clientId: string
}

interface EditAssetGroupRuleFormProps {
  editedRule: AssetGroupRule
  onRuleUpdated: () => Promise<void>
  cancel: () => void
}

interface CreateAssetGroupRuleFormProps {
  onRuleCreated: () => Promise<void>
  cancel: () => void
  clientId: string
}

const CreateAssetGroupRuleForm: React.FC<CreateAssetGroupRuleFormProps> = ({
  cancel,
  clientId,
  onRuleCreated,
}) => {
  const api = useContext(ApiContext)
  const { notification } = App.useApp()

  const [conditions, setConditions] = useState<Array<AwsSimpleCondition>>([])

  const [editRuleForm] = Form.useForm<EditRuleForm>()
  const [form] = Form.useForm<AddConditionForm>()
  const propertyValue = Form.useWatch("property", form)
  const operatorValue = Form.useWatch("operator", form)

  const [editing, setEditing] = useState(false)

  const openNewConditionForm = () => {
    form.resetFields()
    setEditing(true)
  }

  const closeNewConditionForm = () => {
    setEditing(false)
    form.resetFields()
  }

  const tagNameField =
    propertyValue === "tag" ? (
      <Form.Item<AddConditionForm>
        name="tagName"
        label="Tag Name"
        rules={[{ required: true, message: "Please enter tag name" }]}
      >
        <Input placeholder="Tag Name" />
      </Form.Item>
    ) : (
      <></>
    )

  const valuesField =
    operatorValue === "equals" ? (
      <Form.Item<AddConditionForm>
        name="values"
        label="Values"
        rules={[{ required: true, message: "Enter at least one value" }]}
      >
        <Select mode="tags" placeholder="Values" tokenSeparators={[",", " "]} />
      </Form.Item>
    ) : (
      <></>
    )

  const onFinish = (request: AddConditionForm) => {
    let propertyType: PropertyType =
      request.property === "tag" ? `tags.${request.tagName}` : request.property

    const newCondition: AwsSimpleCondition = {
      operator: request.operator,
      property: propertyType,
      values: request.values,
    }

    setConditions([...conditions, newCondition])
    closeNewConditionForm()
  }

  const removeCondition = (index: number) => {
    setConditions(conditions.filter((condition, i) => i !== index))
  }

  const editForm = editing ? (
    <>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item<AddConditionForm>
          name="property"
          label="Property"
          rules={[{ required: true, message: "Please select property" }]}
        >
          <Select placeholder="Property">
            <Select.Option value="tag">Tag</Select.Option>
            <Select.Option value="accountId">Account Id</Select.Option>
            <Select.Option value="organizationId">
              Organization Id
            </Select.Option>
          </Select>
        </Form.Item>
        {tagNameField}
        <Form.Item<AddConditionForm>
          name="operator"
          label="Operator"
          rules={[{ required: true, message: "Please select operator" }]}
        >
          <Select placeholder="Operator">
            <Select.Option value="equals">Equals</Select.Option>
            <Select.Option value="exists">Exists</Select.Option>
          </Select>
        </Form.Item>
        {valuesField}
        <Form.Item>
          <Space>
            <Button onClick={closeNewConditionForm}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              Save Condition
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  ) : (
    <>
      <Button onClick={openNewConditionForm}>Add Condition</Button>
    </>
  )

  const createAssetGroupRule = (request: EditRuleForm) => {
    const req: CreateAwsSimpleAssetGroupRuleRequest = {
      clientId,
      product: "aws",
      type: "simple",
      groups: request.groups,
      conditions,
    }

    api
      .createAssetGroupRule(req)
      .then(() => {
        notification.success({ message: "Asset group rule created" })
        return onRuleCreated()
      })
      .catch((e) => {
        console.error(e)
        notification.error({
          message: "Failed to create asset group rule",
          description: e.message,
        })
      })
  }

  return (
    <>
      <Form
        form={editRuleForm}
        layout="vertical"
        initialValues={{ groups: [] }}
        onFinish={createAssetGroupRule}
      >
        <Form.Item<EditRuleForm>
          name="groups"
          label="Groups"
          rules={[{ required: true, message: "Enter at least one group" }]}
        >
          <Select
            mode="tags"
            placeholder="Values"
            tokenSeparators={[",", " "]}
          />
        </Form.Item>
      </Form>
      <Typography.Title level={4}>Conditions</Typography.Title>
      <List bordered style={{ margin: "20px 0" }}>
        {conditions.map((condition, index) => {
          return (
            <List.Item
              actions={[
                <Button
                  type="text"
                  title="Delete"
                  onClick={() => {
                    removeCondition(index)
                  }}
                  icon={<DeleteOutlined />}
                />,
              ]}
              key={index}
            >
              {renderOperator(condition)}
            </List.Item>
          )
        })}
      </List>
      {editForm}
      <Divider />
      <Space>
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={() => editRuleForm.submit()} type="primary">
          Create Rule
        </Button>
      </Space>
    </>
  )
}

interface AddConditionForm {
  property: "accountId" | "organizationId" | "tag"
  operator: "equals" | "exists"
  tagName?: string
  values: string[]
}

interface EditRuleForm {
  groups: string[]
}

const EditAssetGroupRuleForm: React.FC<EditAssetGroupRuleFormProps> = ({
  editedRule,
  onRuleUpdated,
  cancel,
}) => {
  const api = useContext(ApiContext)
  const { notification } = App.useApp()

  // TODO: Only AwsSimpleAssetGroupRule are supported for now
  if (editedRule.product !== "aws" || editedRule.type !== "simple") {
    throw new Error("Unsupported asset group rule")
  }

  const rule = editedRule as AwsSimpleAssetGroupRule

  const [conditions, setConditions] = useState<Array<AwsSimpleCondition>>(
    rule.conditions.slice(),
  )

  const [editRuleForm] = Form.useForm<EditRuleForm>()
  const [form] = Form.useForm<AddConditionForm>()
  const propertyValue = Form.useWatch("property", form)
  const operatorValue = Form.useWatch("operator", form)

  const [editing, setEditing] = useState(false)

  const openNewConditionForm = () => {
    form.resetFields()
    setEditing(true)
  }

  const closeNewConditionForm = () => {
    setEditing(false)
    form.resetFields()
  }

  const tagNameField =
    propertyValue === "tag" ? (
      <Form.Item<AddConditionForm>
        name="tagName"
        label="Tag Name"
        rules={[{ required: true, message: "Please enter tag name" }]}
      >
        <Input placeholder="Tag Name" />
      </Form.Item>
    ) : (
      <></>
    )

  const valuesField =
    operatorValue === "equals" ? (
      <Form.Item<AddConditionForm>
        name="values"
        label="Values"
        rules={[{ required: true, message: "Enter at least one value" }]}
      >
        <Select mode="tags" placeholder="Values" tokenSeparators={[",", " "]} />
      </Form.Item>
    ) : (
      <></>
    )

  const onFinish = (request: AddConditionForm) => {
    let propertyType: PropertyType =
      request.property === "tag" ? `tags.${request.tagName}` : request.property

    const newCondition: AwsSimpleCondition = {
      operator: request.operator,
      property: propertyType,
      values: request.values,
    }

    setConditions([...conditions, newCondition])
    closeNewConditionForm()
  }

  const removeCondition = (index: number) => {
    setConditions(conditions.filter((condition, i) => i !== index))
  }

  const editForm = editing ? (
    <>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item<AddConditionForm>
          name="property"
          label="Property"
          rules={[{ required: true, message: "Please select property" }]}
        >
          <Select placeholder="Property">
            <Select.Option value="tag">Tag</Select.Option>
            <Select.Option value="accountId">Account Id</Select.Option>
            <Select.Option value="organizationId">
              Organization Id
            </Select.Option>
          </Select>
        </Form.Item>
        {tagNameField}
        <Form.Item<AddConditionForm>
          name="operator"
          label="Operator"
          rules={[{ required: true, message: "Please select operator" }]}
        >
          <Select placeholder="Operator">
            <Select.Option value="equals">Equals</Select.Option>
            <Select.Option value="exists">Exists</Select.Option>
          </Select>
        </Form.Item>
        {valuesField}
        <Form.Item>
          <Space>
            <Button onClick={closeNewConditionForm}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              Save Condition
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  ) : (
    <>
      <Button onClick={openNewConditionForm}>Add Condition</Button>
    </>
  )

  const updateAssetGroupRule = (request: EditRuleForm) => {
    const req: UpdateAwsSimpleAssetGroupRuleRequest = {
      id: rule.id,
      clientId: rule.clientId,
      groups: request.groups,
      conditions,
    }

    api
      .updateAssetGroupRule(rule.clientId, rule.id, req)
      .then(() => {
        notification.success({ message: "Asset group rule updated" })
        return onRuleUpdated()
      })
      .catch((e) => {
        console.error(e)
        notification.error({
          message: "Failed to update asset group rule",
          description: e.message,
        })
      })
  }

  return (
    <>
      <Form
        form={editRuleForm}
        layout="vertical"
        initialValues={{ groups: rule.groups }}
        onFinish={updateAssetGroupRule}
      >
        <Form.Item<EditRuleForm>
          name="groups"
          label="Groups"
          rules={[{ required: true, message: "Enter at least one group" }]}
        >
          <Select
            mode="tags"
            placeholder="Values"
            tokenSeparators={[",", " "]}
          />
        </Form.Item>
      </Form>
      <Typography.Title level={4}>Conditions</Typography.Title>
      <List bordered style={{ margin: "20px 0" }}>
        {conditions.map((condition, index) => {
          return (
            <List.Item
              actions={[
                <Button
                  type="text"
                  title="Delete"
                  onClick={() => {
                    removeCondition(index)
                  }}
                  icon={<DeleteOutlined />}
                />,
              ]}
              key={index}
            >
              {renderOperator(condition)}
            </List.Item>
          )
        })}
      </List>
      {editForm}
      <Divider />
      <Space>
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={() => editRuleForm.submit()} type="primary">
          Update Rule
        </Button>
      </Space>
    </>
  )
}

const AssetGroupRuleForm: React.FC<AssetGroupRuleFormProps> = (props) => {
  return props.editedRule ? (
    <EditAssetGroupRuleForm
      editedRule={props.editedRule}
      onRuleUpdated={props.onRuleUpdated}
      cancel={props.cancel}
    />
  ) : (
    <CreateAssetGroupRuleForm
      onRuleCreated={props.onRuleUpdated}
      cancel={props.cancel}
      clientId={props.clientId}
    />
  )
}

export const ClientAssetGroupRulesContent: React.FC = () => {
  const api = useContext(ApiContext)
  const { clientId } = useParams()
  const [rules, setRules] = useState<ReadonlyArray<AssetGroupRule>>([])
  const [drawerMode, setDrawerMode] = useState("none")
  const [editedRule, setEditedRule] = useState<AssetGroupRule>()

  const openCreateDrawer = () => {
    setDrawerMode("create")
  }

  const openEditDrawer = (rule: AssetGroupRule) => {
    setEditedRule(JSON.parse(JSON.stringify(rule))) // TODO: Use proper clone
    setDrawerMode("edit")
  }

  const closeDrawer = () => {
    setDrawerMode("none")
    setEditedRule(undefined)
  }

  useEffect(() => {
    api
      .listAssetGroupRules(clientId)
      .then((data) => {
        setRules(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [clientId, api])

  const onRulesChanged = async () => {
    api
      .listAssetGroupRules(clientId)
      .then((data) => {
        setRules(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onRuleDeleted = async () => {
    await onRulesChanged()
  }

  const onRuleUpdated = async () => {
    closeDrawer()
    await onRulesChanged()
  }

  const drawerTitle =
    drawerMode === "create" ? "New Asset Group Rule" : "Edit Asset Group Rule"

  return (
    <>
      <List
        itemLayout="vertical"
        dataSource={rules.slice()}
        renderItem={(rule) =>
          renderAssetGroupRule({
            rule,
            onRuleDeleted,
            openEditDrawer,
          })
        }
      />
      <Divider />
      <Button icon={<PlusOutlined />} onClick={openCreateDrawer}>
        New Rule
      </Button>
      <Drawer
        title={drawerTitle}
        width={720}
        onClose={closeDrawer}
        open={drawerMode !== "none"}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <AssetGroupRuleForm
          editedRule={editedRule}
          onRuleUpdated={onRuleUpdated}
          cancel={closeDrawer}
          clientId={clientId}
        />
      </Drawer>
    </>
  )
}
