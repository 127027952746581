import { ReservedInstancesAsset } from "../../../../../common/model/data/aws-data"
import { assetContent } from "../../asset-content"

export const awsEc2ReservedInstances = assetContent<
  ReservedInstancesAsset,
  ReservedInstancesAsset
>({
  type: "ec2/reserved-instance",
  list: {
    exclude: ["display-name"],
    filtered: [
      "State",
      "AvailabilityZone",
      "InstanceCount",
      "InstanceType",
      "OfferingType",
      "OfferingClass",
      "Scope",
      "ProductDescription",
      "CurrencyCode",
      "InstanceTenancy",
    ],
  },
})
