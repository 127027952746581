import { createContext } from "react"

export const UserSessionContext = createContext<UserSession>({
  username: "",
  roleProvider: () => Promise.reject("Role not initialised"),
  firstName: "",
  lastName: "",
  accessTokenProvider: () => Promise.reject("Access token not initialised"),
  idTokenProvider: () => Promise.reject("Access token not initialised"),
})

export interface UserSession {
  username: string
  roleProvider: () => Promise<string>
  firstName: string
  lastName: string
  accessTokenProvider: () => Promise<string>
  idTokenProvider: () => Promise<string>
}

export const AwsSearchContext = createContext<AwsSearchState>({
  accountIds: [],
  organizationIds: [],
  regions: [],
  assetGroups: [],
})

export interface AwsSearchState {
  accountIds: string[]
  organizationIds: string[]
  regions: string[]
  assetGroups: string[]
}
