import dt from "date-and-time"

export const booleanValue = (value: unknown): string =>
  value === true ? "Yes" : "No"

export const timestampValue = (value: unknown): string =>
  dt.format(new Date(`${value}`), "YYYY-MM-DD HH:mm")

export const numericTimestampValue = (value: unknown): number =>
  new Date(`${value}`).getTime()
