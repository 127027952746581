import { Table, Typography } from "antd"
import _ from "lodash"
import React, { useContext } from "react"
import { withErrorBoundary } from "react-error-boundary"
import { Link } from "react-router-dom"
import { AssetDetailsContext } from "../context/ClientsContext"
import { ErrorFallback } from "../errors/ErrorFallback"
const { Text } = Typography

const AssetDetailsDependenciesContentInternal: React.FC = () => {
  const assetDetailsState = useContext(AssetDetailsContext)

  if (_.isEmpty(assetDetailsState.asset._meta.relations?.dependencies)) {
    return <Text disabled={true}>Dependencies data not available</Text>
  }

  const items = Object.entries(
    assetDetailsState.asset._meta.relations.dependencies,
  ).flatMap(([type, relations]) => {
    return Object.entries(relations)
      .map(([id, displayName]) => ({
        type,
        id,
        displayName,
      }))
      .sort((a, b) => a.displayName.localeCompare(b.displayName))
  })

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: (text, asset) => (
        <Link to={`../../../../${asset.type}/${asset.id}`} relative="path">
          {asset.id}
        </Link>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
      filters: _.uniqBy(
        items.map((a) => ({ text: a.type, value: a.type })),
        (a) => a.value,
      ).sort((a, b) => a.value.localeCompare(b.value)),
      filtered: true,
      onFilter: (v, asset) => asset.type === v,
    },
    {
      title: "Display Name",
      dataIndex: "displayName",
      key: "displayName",
      sorter: (a, b) => a.displayName.localeCompare(b.displayName),
    },
  ]

  return (
    <div>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={items}
        style={{ paddingTop: "20px" }}
        scroll={{
          y: 600,
          x: "max-content",
          scrollToFirstRowOnChange: true,
        }}
      />
    </div>
  )
}

export const AssetDetailsDependenciesContent = withErrorBoundary(
  AssetDetailsDependenciesContentInternal,
  { FallbackComponent: ErrorFallback },
)
