import { Table, Typography } from "antd"
import _ from "lodash"
import React, { useContext } from "react"
import { withErrorBoundary } from "react-error-boundary"
import { AssetDetailsContext } from "../context/ClientsContext"
import { ErrorFallback } from "../errors/ErrorFallback"

const AssetDetailsAuditContentInternal: React.FC = () => {
  const assetDetailsState = useContext(AssetDetailsContext)

  if (_.isEmpty(assetDetailsState.asset._meta.audit)) {
    return (
      <Typography.Text disabled={true}>
        Audit data not available
      </Typography.Text>
    )
  }

  if (assetDetailsState.asset._meta.audit.findingCount === 0) {
    return <Typography.Text disabled={true}>No audit findings</Typography.Text>
  }

  const items = (assetDetailsState.asset._meta.audit.findings ?? [])
    .slice()
    .sort((a, b) => a.severity.localeCompare(b.severity))

  const columns = [
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      sorter: (a, b) => a.severity.localeCompare(b.severity),
    },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      sorter: (a, b) => {
        if (!a.cost && !b.cost) {
          return 0
        }
        if (a.cost && !b.cost) {
          return 1
        }
        if (!a.cost && b.cost) {
          return -1
        }

        return a.cost - b.cost
      },
      render: (v) => {
        if (typeof v === "number") {
          return v.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        }

        return "-"
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
  ]

  return (
    <div>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={items}
        style={{ paddingTop: "20px" }}
        scroll={{
          y: 600,
          x: "max-content",
          scrollToFirstRowOnChange: true,
        }}
      />
    </div>
  )
}

export const AssetDetailsAuditContent = withErrorBoundary(
  AssetDetailsAuditContentInternal,
  { FallbackComponent: ErrorFallback },
)
