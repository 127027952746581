import { Ec2Resource } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
  tableSection,
} from "../../asset-content"

export const awsEc2Instances = assetContent<Ec2Resource, Ec2Resource>({
  type: "ec2/instance",
  list: {
    exclude: ["AmiLaunchIndex", "StateTransitionReason", "ClientToken"],
    filtered: [
      "InstanceType",
      "Platform",
      "SubnetId",
      "VpcId",
      "Architecture",
      "EnaSupport",
      "EbsOptimized",
      "RootDeviceType",
      "SourceDestCheck",
      "VirtualizationType",
      "PlatformDetails",
    ],
    additional: [
      { label: "State", value: (asset) => asset.State?.Name, filter: true },
    ],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: [
        "NetworkInterfaces",
        "IamInstanceProfile",
        "SecurityGroups",
        "PrivateDnsNameOptions",
        "Placement",
        "MetadataOptions",
        "CpuOptions",
        "Monitoring",
        "MaintenanceOptions",
        "State",
        "HibernationOptions",
        "EnclaveOptions",
        "CapacityReservationSpecification",
        "BlockDeviceMappings",
      ],
      additional: [
        {
          label: "Monitoring",
          value: () => asset.Monitoring.State,
        },
        {
          label: "Maintenance Options",
          value: () => asset.MaintenanceOptions?.AutoRecovery,
        },
        {
          label: "State",
          value: () => asset.State?.Name,
        },
        {
          label: "Hibernation Configured",
          value: () => asset.HibernationOptions?.Configured,
        },
        {
          label: "Enclave Enabled",
          value: () => asset.EnclaveOptions?.Enabled,
        },
      ],
    }),
    descriptionSection({
      asset: asset.IamInstanceProfile,
      title: "IAM Instance Profile",
    }),
    descriptionSection({
      asset: asset.PrivateDnsNameOptions,
      title: "Private Dns Name Options",
    }),
    descriptionSection({
      asset: asset.CpuOptions,
      title: "Cpu Options",
    }),
    descriptionSection({
      asset: asset.Placement,
      title: "Placement",
    }),
    descriptionSection({
      asset: asset.MetadataOptions,
      title: "Metadata Options",
    }),
    descriptionSection({
      asset: asset.CapacityReservationSpecification,
      title: "Capacity Reservation Specification",
    }),
    tableSection({
      asset,
      title: "Security Groups",
      data: () => asset.SecurityGroups,
    }),
    tableSection({
      asset,
      title: "Block Device Mappings",
      data: () =>
        asset.BlockDeviceMappings.map((d) => {
          return {
            DeviceName: d.DeviceName,
            ...d.Ebs,
          }
        }),
    }),
    tableSection({
      asset,
      title: "Network Interfaces",
      columns: [
        "Description",
        "NetworkInterfaceId",
        "OwnerId",
        "PrivateDnsName",
        "PrivateIpAddress",
        "VpcId",
        "OwnerId",
        "Status",
      ],
      data: () => asset.NetworkInterfaces!,
    }),
  ],
})
/*
[
  {
    "DeviceName": "/dev/xvda",
    "Ebs": {
      "AttachTime": "2023-01-16T12:28:52.000Z",
      "DeleteOnTermination": true,
      "Status": "attached",
      "VolumeId": "vol-076f88ff5893f3aad"
    }
  },
  {
    "DeviceName": "/dev/xvdba",
    "Ebs": {
      "AttachTime": "2023-06-20T09:23:05.000Z",
      "DeleteOnTermination": false,
      "Status": "attached",
      "VolumeId": "vol-05249b1add9f54519"
    }
  }
]
 */
