import { SubnetResource } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
} from "../../asset-content"

export const awsVpcSubnets = assetContent<SubnetResource>({
  type: "vpc/subnet",
  list: {
    filtered: [
      "AvailabilityZone",
      "AvailabilityZoneId",
      "MapPublicIpOnLaunch",
      "MapCustomerOwnedIpOnLaunch",
      "DefaultForAz",
      "VpcId",
      "AssignIpv6AddressOnCreation",
      "Ipv6Native",
      "EnableDns64",
    ],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["PrivateDnsNameOptionsOnLaunch"],
    }),
    descriptionSection({
      asset: asset.PrivateDnsNameOptionsOnLaunch,
      title: "Private Dns Name Options On Launch",
    }),
  ],
})
