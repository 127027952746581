import { ApiResource } from "../../../../../common/model/data/aws-data"
import { assetContent } from "../../asset-content"

export const awsApigwApis = assetContent<ApiResource, ApiResource>({
  type: "apigw/api",
  list: {
    exclude: [
      "RouteSelectionExpression",
      "Description",
      "ApiKeySelectionExpression",
    ],
    filtered: ["ProtocolType"],
  },
})
