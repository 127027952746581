import { toHeaderCase } from "js-convert-case"
import {
  AwsAccountDetailsAsset,
  AwsAccountListAsset,
} from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  barChartSection,
  basicAssetDetails,
  tableSection,
} from "../../asset-content"

const alternateContact = (asset: AwsAccountListAsset, dataIndex: string) => {
  return {
    asset,
    title: toHeaderCase(dataIndex),
    enabled: asset[dataIndex] !== undefined,
    items: [
      {
        value: () => asset[dataIndex].Title,
        label: "Title",
      },
      {
        value: () => asset[dataIndex].Name,
        label: "Name",
      },
      {
        value: () => asset[dataIndex].EmailAddress,
        label: "Email",
      },
      {
        value: () => asset[dataIndex].PhoneNumber,
        label: "Phone",
      },
    ],
  }
}

export const awsAccountAccounts = assetContent<
  AwsAccountListAsset,
  AwsAccountDetailsAsset
>({
  type: "account/account",
  list: {
    exclude: ["display-name", "organizationId"],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: [
        "billingContact",
        "securityContact",
        "operationsContact",
        "monthlyCosts",
        "dailyCosts",
        "serviceMonthlyCosts",
        "regions",
      ],
    }),
    alternateContact(asset, "billingContact"),
    alternateContact(asset, "securityContact"),
    alternateContact(asset, "operationsContact"),
    tableSection({ asset, title: "Regions", data: () => asset.regions }),
    barChartSection({
      title: "Monthly Costs",
      xAxisKey: "date",
      valueKey: "value",
      description: "Account's monthly costs in USD.",
      data: () => asset.monthlyCosts.slice(),
    }),
    barChartSection({
      title: "Daily Costs",
      xAxisKey: "date",
      valueKey: "value",
      description: "Account's daily costs of the current month in USD.",
      data: () => asset.dailyCosts.slice(),
    }),
  ],
})
