import { Typography } from "antd"
import React from "react"
import { Link } from "react-router-dom"
import { useClientsState } from "../context/ClientsContext"

export const ClientSelectorContent: React.FC = () => {
  const clientsState = useClientsState()

  if (clientsState.clients.length === 0) {
    return <div></div>
  }

  return (
    <div>
      <Typography.Title level={2}>Clients</Typography.Title>
      <ul>
        {clientsState.clients.map((client) => (
          <li key={client.id}>
            <Link to={`${client.id}/aws`}>{client.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
