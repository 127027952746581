import { Form, Select, Spin, Typography } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { withErrorBoundary } from "react-error-boundary"
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { AssetDetailsContext } from "../context/ClientsContext"
import { ErrorFallback } from "../errors/ErrorFallback"
const { Text } = Typography

const AssetDetailsCostsContentInternal: React.FC = () => {
  const assetDetailsState = useContext(AssetDetailsContext)
  const [selectedMonth, setSelectedMonth] = useState<string>()
  const [months, setMonths] = useState<{ label: string; value: string }[]>([])
  const [costData, setCostData] = useState<any>()

  useEffect(() => {
    if (
      !assetDetailsState.asset._meta.billingId ||
      !assetDetailsState.asset._meta.cost
    ) {
      return setMonths([])
    }

    const costEntries = assetDetailsState.asset._meta.cost.data
      .slice()
      .sort((a, b) =>
        b.month.split("/")[0].localeCompare(a.month.split("/")[0]),
      )

    const m = costEntries
      .map((e) => e.month.split("/")[0])
      .sort((a, b) => b.localeCompare(a))
      .map((month) => {
        return {
          label: "20" + month.slice(0, 2) + "/" + month.slice(2),
          value: month,
        }
      })
      .flat()

    setMonths(m)

    const costDataForSelectedMonth =
      costEntries.find((s) => s.month.split("/")[0] === selectedMonth) ??
      costEntries[0]

    const cd = costDataForSelectedMonth
      ? Object.entries(costDataForSelectedMonth.usageTypes).map(
          ([usageType, data]) => {
            return {
              usageType,
              description: data.description,
              usageAmountUnit: data.unit,
              data: data.usageAmount.map((usageAmount, index) => ({
                usageAmount,
                unblendedCost: data.unblendedCost[index],
                day: index + 1,
              })),
            }
          },
        )
      : undefined

    setCostData(cd)
  }, [assetDetailsState, selectedMonth])

  if (assetDetailsState.status !== "ready") {
    return (
      <div>
        <Spin delay={500} />
      </div>
    )
  }

  if (months.length === 0) {
    return <Text disabled={true}>Billing data not available</Text>
  }

  const onDateChanged = (value) => {
    setSelectedMonth(value)
  }

  const charts = costData
    ? costData
        .slice()
        .sort((a, b) => a.usageType.localeCompare(b.usageType))
        .map((d) => {
          return (
            <div>
              <Typography.Title level={2}>{d.usageType}</Typography.Title>
              <Text>{d.description}</Text>
              <div>
                <ResponsiveContainer
                  height={300}
                  style={{ paddingTop: "20px" }}
                >
                  <BarChart data={d.data} width={500} height={400}>
                    <CartesianGrid />
                    <Legend />
                    <Tooltip />
                    <XAxis dataKey="day" />
                    <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                    <YAxis
                      yAxisId="right"
                      orientation="right"
                      stroke="#82ca9d"
                    />
                    <Bar
                      yAxisId="left"
                      dataKey="usageAmount"
                      name={`Usage Amount (${d.usageAmountUnit})`}
                      fill="#8884d8"
                    />
                    <Bar
                      yAxisId="right"
                      dataKey="unblendedCost"
                      name="Unblended Cost ($)"
                      fill="#82ca9d"
                    />
                    <Brush dataKey="day" height={20} stroke="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          )
        })
    : [<div>Please select date</div>]

  return (
    <div>
      <Form layout="inline">
        <Form.Item label="Date">
          <Select
            options={months}
            onChange={onDateChanged}
            style={{ width: 200 }}
            defaultValue={months[0]}
          />
        </Form.Item>
      </Form>

      {charts}
    </div>
  )
}

export const AssetDetailsCostsContent = withErrorBoundary(
  AssetDetailsCostsContentInternal,
  { FallbackComponent: ErrorFallback },
)
