import {
  Alert,
  AutoComplete,
  Button,
  Col,
  Layout,
  Modal,
  Row,
  SelectProps,
  Space,
  theme,
  Typography,
} from "antd"
import _ from "lodash"
import React, { useContext, useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { ClientDto } from "../../../common/model/api"
import { AssetType, IndexedAsset } from "../../../common/model/common"
import { AwsSummaryDto } from "../../../common/model/content/aws-content"
import { ApiContext } from "../ApiContext"
import { AwsSearchContext } from "../UserSessionContext"
import { Api } from "../api"
import { assetFamilyDisplayName, assetTypeDisplayName } from "../content/texts"
import {
  AwsSummaryContext,
  ClientStateContext,
} from "../context/ClientsContext"
import { AwsResourcesSideMenu } from "./AwsResourcesSideMenu"
import { AwsSearch } from "./AwsSearch"

const { Title, Text } = Typography
const { Content, Sider } = Layout

abstract class SearchTerm {
  abstract type: string
}

type Wildcard = "start" | "end" | "both" | "none"

class PrimarySearchTerm extends SearchTerm {
  type: "primary"
  value: string
  wildcard: Wildcard

  constructor(value: string, wildcard: Wildcard) {
    super()
    this.value = value
    this.wildcard = wildcard
  }
}

class TagSearchTerm extends SearchTerm {
  type: "tag"
  key: string
  value: string

  constructor(value: string, key: string) {
    super()
    this.value = value
    this.key = key
  }
}

class AccountSearchTerm extends SearchTerm {
  type: "account"
  value: string

  constructor(value: string) {
    super()
    this.value = value
  }
}

class RegionSearchTerm extends SearchTerm {
  type: "region"
  value: string

  constructor(value: string) {
    super()
    this.value = value
  }
}

class AssetTypeSearchTerm extends SearchTerm {
  type: "asset-type"
  value: string

  constructor(value: string) {
    super()
    this.value = value
  }
}

const tagTermRegex = /^tag:(.+)=(.+)$/
const accountTermRegex = /^account=(.+)$/
const regionTermRegex = /^region=(.+)$/
const assetTypeTermRegex = /^assetType=(.+)$/

const parseQueryString = (queryString: string): ReadonlyArray<SearchTerm> => {
  let rawTerms = queryString.trim().replace(/\s+/g, " ").split(" ")

  return rawTerms.map((term) => {
    const tagTermMatches = term.match(tagTermRegex)
    if (tagTermMatches && tagTermMatches.length === 3) {
      return new TagSearchTerm(tagTermMatches[2], tagTermMatches[1])
    }

    const accountTermMatches = term.match(accountTermRegex)
    if (accountTermMatches && accountTermMatches.length === 2) {
      return new AccountSearchTerm(accountTermMatches[1])
    }

    const regionTermMatches = term.match(regionTermRegex)
    if (regionTermMatches && regionTermMatches.length === 2) {
      return new RegionSearchTerm(regionTermMatches[1])
    }

    const assetTypeTermMatches = term.match(assetTypeTermRegex)
    if (assetTypeTermMatches && assetTypeTermMatches.length === 2) {
      return new AssetTypeSearchTerm(assetTypeTermMatches[1])
    }

    const wildcardStart = term.startsWith("*")
    const wildcardEnd = term.endsWith("*")

    let wildcard: Wildcard = "none"
    let value = term
    if (wildcardStart && wildcardEnd) {
      wildcard = "both"
      value = term.slice(1, -1)
    } else if (wildcardStart) {
      wildcard = "start"
      value = term.slice(1)
    } else if (wildcardEnd) {
      wildcard = "end"
      value = term.slice(0, -1)
    }

    return new PrimarySearchTerm(value, wildcard)
  })
}

const createOrganizationOptions = (
  summary?: AwsSummaryDto,
): SelectProps["options"] => {
  if (!summary) {
    return []
  }

  return Object.entries(summary.organizations)
    .sort()
    .map(([orgId, orgName]) => ({
      label: orgName + (orgId !== "none" ? ` (${orgId})` : ""),
      value: orgId,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

const createRegionOptions = (
  summary?: AwsSummaryDto,
): SelectProps["options"] => {
  if (!summary) {
    return []
  }

  return summary.regions
    .slice()
    .sort()
    .map((value) => ({ label: value, value }))
}

const createAssetGroupOptions = (
  summary?: AwsSummaryDto,
): SelectProps["options"] => {
  if (!summary) {
    return []
  }

  return summary.assetGroups
    .slice()
    .sort()
    .map((value) => ({ label: value, value }))
}

const createAccountOptions = (
  summary?: AwsSummaryDto,
): SelectProps["options"] => {
  if (!summary) {
    return []
  }

  return Object.entries(summary.accounts)
    .map(([id, name]) => ({
      label: id === name ? id : `${name} #${id}`,
      value: id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

const startSyncJob = async (
  api: Api,
  selectedClient: ClientDto,
  setSyncJobRequested: (status: boolean) => void,
): Promise<void> => {
  setSyncJobRequested(true)
  api
    .startSyncJob(selectedClient.id)
    .then((r) => {
      console.log(`Response: ${JSON.stringify(r)}`)
      setSyncJobRequested(false)
    })
    .catch((error) => {
      console.log(error)
      setSyncJobRequested(false)
    })
}

const renderTitle = (assetType: AssetType, count: number) => {
  const [assetFamily, assetKind] = assetType.split("/")
  return (
    <span>
      {assetFamilyDisplayName(assetFamily)} /{" "}
      {assetTypeDisplayName(assetFamily, assetKind)} ({count})
    </span>
  )
}

// `${AccountNumber} ${RegionNumber} ${AssetTypeNumber} ${AssetId} ${AssetDisplayName}`
const renderItem = (asset: IndexedAsset, assetType: AssetType) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [accountNumber, regionNumber, assetTypeNumber, id, displayName] =
    asset.split(" ", 5)
  return {
    value: `${assetType}/${id}`,
    label: <div>{displayName}</div>,
  }
}

export const AwsProductContent: React.FC = (props) => {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false)

  const [searchResults, setSearchResults] = useState<SelectProps["options"]>([])

  const showSearchModal = () => {
    setIsSearchModalOpen(true)
  }

  const closeSearchModal = () => {
    setIsSearchModalOpen(false)
  }

  const api = useContext(ApiContext)
  const navigate = useNavigate()
  const clientState = useContext(ClientStateContext)

  const {
    token: { colorBgContainer },
  } = theme.useToken()

  const [summary, setSummary] = useState<AwsSummaryDto>(undefined)

  // search values
  const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>([])
  const [selectedRegions, setSelectedRegions] = useState<string[]>([])
  const [selectedAssetGroups, setSelectedAssetGroups] = useState<string[]>([])
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>(
    [],
  )
  const [syncJobRequested, setSyncJobRequested] = useState<boolean>(false)

  useEffect(() => {
    if (clientState.status !== "ready") {
      return
    }

    const { id, syncJobs } = clientState.client

    // TODO: Handle summary not found
    if (syncJobs.length === 0) {
      return
    }

    api
      .getAwsSummary({ clientId: id, syncJob: syncJobs[0] })
      .then((summary) => {
        setSummary(summary)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [clientState, api])

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.metaKey === true) {
        if (event.key === "k") {
          showSearchModal()
        }
      }
    }

    // attach the event listener
    document.addEventListener("keydown", handleKeyPress)

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress)
    }
  })

  if (clientState.status !== "ready") {
    return <div></div>
  }

  const assetGroupOptions = createAssetGroupOptions(summary)
  const regionOptions = createRegionOptions(summary)
  const accountOptions = createAccountOptions(summary)
  const organizationOptions = createOrganizationOptions(summary)

  const handleSearch = _.debounce((newValue: string) => {
    if (
      !clientState.assetsIndexData ||
      !clientState.assetsIndexPrimary ||
      !clientState.assetsIndexTags ||
      !clientState.assetsIndexAccounts ||
      !clientState.assetsIndexRegions ||
      !clientState.assetsIndexAssetTypes
    ) {
      console.log("Search index not yet loaded")
      return
    }

    const terms = parseQueryString(newValue)

    if (terms.length === 0) {
      setSearchResults([])
      return
    }

    const allResults = new Array<number[]>()

    terms.forEach((searchTerm, termIndex) => {
      let termResults = new Array<number>()
      if (searchTerm instanceof PrimarySearchTerm) {
        // Is wildcard search performant enough?
        if (searchTerm.value.length > 3 && searchTerm.wildcard !== "none") {
          Object.entries(clientState.assetsIndexPrimary.terms).forEach(
            ([term, assets]) => {
              if (
                searchTerm.wildcard === "both" &&
                term.includes(searchTerm.value)
              ) {
                termResults.push(...assets)
              } else if (
                searchTerm.wildcard === "start" &&
                term.endsWith(searchTerm.value)
              ) {
                termResults.push(...assets)
              } else if (
                searchTerm.wildcard === "end" &&
                term.startsWith(searchTerm.value)
              ) {
                termResults.push(...assets)
              }
            },
          )
        } else {
          const assets = clientState.assetsIndexPrimary.terms[searchTerm.value]
          if (assets) {
            termResults = assets
          }
        }
      } else if (searchTerm instanceof TagSearchTerm) {
        const assets =
          clientState.assetsIndexTags.keys[searchTerm.key]?.[searchTerm.value]
        if (assets) {
          termResults = assets
        }
      } else if (searchTerm instanceof AccountSearchTerm) {
        const assets = clientState.assetsIndexAccounts.terms[searchTerm.value]
        if (assets) {
          termResults = assets
        }
      } else if (searchTerm instanceof RegionSearchTerm) {
        const assets = clientState.assetsIndexRegions.terms[searchTerm.value]
        if (assets) {
          termResults = assets
        }
      } else if (searchTerm instanceof AssetTypeSearchTerm) {
        const assets = clientState.assetsIndexAssetTypes.terms[searchTerm.value]
        if (assets) {
          termResults = assets
        }
      } else {
        throw new Error(`Unknown search term type: ${searchTerm.type}`)
      }

      const uniqueTermResults = _.uniq(termResults)
      allResults.push(uniqueTermResults)
    })

    const finalResults = _.intersection(...allResults)

    const results = finalResults.map(
      (i) => clientState.assetsIndexData.assets[i],
    )

    const resultsByType = _.groupBy(results, (r) => r.split(" ")[2])

    // `${AccountNumber} ${RegionNumber} ${AssetTypeNumber} ${AssetId} ${AssetDisplayName}`
    const options = Array.from(Object.entries(resultsByType)).map(
      ([assetTypeNumber, value]) => {
        const assetType =
          clientState.assetsIndexData.types[Number(assetTypeNumber)]

        return {
          label: renderTitle(assetType, value.length),
          options: value.map((asset) => renderItem(asset, assetType)),
        }
      },
    )

    setSearchResults(options)
  }, 2)

  const onSelect = (value: string) => {
    closeSearchModal()
    setSearchResults([])
    navigate(`/app/clients/${clientState.client.id}/aws/${value}`)
  }

  if (clientState.client.syncJobs.length === 0) {
    return (
      <div>
        <Row>
          <Col span={12}>
            <Title level={2}>{clientState.client.name}</Title>
          </Col>
        </Row>
        <Layout
          style={{
            padding: "0",
            background: colorBgContainer,
            marginTop: "10px",
          }}
        >
          <Content style={{ padding: "10px", minHeight: 280 }}>
            <Alert
              message="Index not available"
              description={
                <div>
                  <Text>This client has no index available. Please click </Text>
                  <Button
                    type="link"
                    style={{ paddingLeft: "0", paddingRight: "0" }}
                    disabled={syncJobRequested}
                    onClick={() =>
                      startSyncJob(api, clientState.client, setSyncJobRequested)
                    }
                  >
                    here
                  </Button>
                  <Text> to initialize indexing.</Text>
                </div>
              }
              type="info"
            />
          </Content>
        </Layout>
      </div>
    )
  }

  return (
    <div>
      <Modal
        open={isSearchModalOpen}
        onOk={closeSearchModal}
        onCancel={closeSearchModal}
        width="100%"
        footer={null}
        closeIcon={null}
        destroyOnClose={true}
        getContainer={document.getElementById("root")}
      >
        {isSearchModalOpen ? (
          <div>
            <Space direction="vertical">
              <Text type="secondary">
                Search assets by their identifiers such as id and name. Leading
                and trailing wildcards * are supported when the query string has
                at least 3 characters. Give multiple search terms by separating
                them with a space. If multiple terms are given, all of them must
                match. Type tag:tagname=value to search by tag name and value.
                You can also search by account, region, and asset type by typing
                account=value, region=value, and assetType=value respectively.
              </Text>
            </Space>
            <AutoComplete
              placeholder="Search assets"
              style={{ width: "100%", marginTop: "10px" }}
              options={searchResults}
              onSelect={onSelect}
              onSearch={handleSearch}
              autoFocus={true}
            />
          </div>
        ) : (
          <div></div>
        )}
      </Modal>

      <Layout
        style={{
          padding: "10px",
          background: colorBgContainer,
        }}
      >
        <Row gutter={8}>
          <Col span={6}>
            <AwsSearch
              placeholder="Organization"
              options={organizationOptions}
              onChange={setSelectedOrganizations}
            />
          </Col>
          <Col span={6}>
            <AwsSearch
              placeholder="Account"
              options={accountOptions}
              onChange={setSelectedAccountIds}
            />
          </Col>
          <Col span={6}>
            <AwsSearch
              placeholder="Region"
              options={regionOptions}
              onChange={setSelectedRegions}
            />
          </Col>
          <Col span={6}>
            <AwsSearch
              placeholder="Asset Group"
              options={assetGroupOptions}
              onChange={setSelectedAssetGroups}
            />
          </Col>
        </Row>
      </Layout>
      <Layout
        style={{
          padding: "0",
          background: colorBgContainer,
          marginTop: "10px",
        }}
      >
        <Sider style={{ background: colorBgContainer }} width={200}>
          <AwsResourcesSideMenu
            selectedOrganizations={selectedOrganizations}
            selectedAccountIds={selectedAccountIds}
            selectedRegions={selectedRegions}
            selectedAssetGroups={selectedAssetGroups}
            summary={summary}
          />
        </Sider>
        <Content style={{ padding: "0 24px", minHeight: 280 }}>
          <AwsSearchContext.Provider
            value={{
              accountIds: selectedAccountIds,
              regions: selectedRegions,
              organizationIds: selectedOrganizations,
              assetGroups: selectedAssetGroups,
            }}
          >
            <AwsSummaryContext.Provider value={summary}>
              <Outlet />
            </AwsSummaryContext.Provider>
          </AwsSearchContext.Provider>
        </Content>
      </Layout>
    </div>
  )
}
