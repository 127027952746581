import { createContext, Dispatch, Reducer } from "react"
import { useOutletContext } from "react-router-dom"
import { ClientDto } from "../../../common/model/api"
import {
  AwsResource,
  KeySearchIndex,
  SearchIndex,
  SearchIndexAssetsData,
} from "../../../common/model/common"
import { AwsSummaryDto } from "../../../common/model/content/aws-content"
import { assetFamilyDisplayName, assetTypeDisplayName } from "../content/texts"

export interface ClientsState {
  clients: ReadonlyArray<ClientDto>
}

export interface SelectedClientState {
  readonly status: "loading" | "ready"
}

export class SelectedClientReadyState implements SelectedClientState {
  readonly status = "ready"

  constructor(readonly client: ClientDto) {}
}

export class SelectedClientLoadingState implements SelectedClientState {
  readonly status = "loading"
}

export const useClientsState = () => useOutletContext<ClientsState>()

// deprecated
export const SelectedClientContext = createContext<SelectedClientState>(
  new SelectedClientLoadingState(),
)

export const AwsSummaryContext = createContext<AwsSummaryDto | undefined>(
  undefined,
)

export type ThemeId = "light" | "dark"

export interface ThemeState {
  themeId: ThemeId
  setThemeId: (themeId: ThemeId) => void
}

export const ThemeContext = createContext<ThemeState>({
  themeId: "light",
  setThemeId: () => {},
})

export interface AssetsListingState {
  assets: ReadonlyArray<AwsResource>
  assetFamilyLabel?: string
  assetTypeLabel?: string
  status: "loading" | "ready"
}

export const AssetsListingContext = createContext<AssetsListingState>(undefined)

export const AssetsListingReducerContext =
  createContext<Dispatch<AssetListingAction>>(undefined)

///////

export type ClientState = {
  client?: ClientDto
  status: "loading" | "ready"
  assetsIndexData?: SearchIndexAssetsData
  assetsIndexPrimary?: SearchIndex
  assetsIndexAccounts?: SearchIndex
  assetsIndexRegions?: SearchIndex
  assetsIndexAssetTypes?: SearchIndex
  assetsIndexTags?: KeySearchIndex
}

export const ClientStateContext = createContext<ClientState>({
  status: "loading",
})

type ClientStateAction =
  | { action: "loading" }
  | { action: "ready"; client: ClientDto }
  | { action: "asset-index-data-ready"; data: SearchIndexAssetsData }
  | {
      action: "asset-index-primary-ready"
      index: SearchIndex
    }
  | {
      action: "asset-index-accounts-ready"
      index: SearchIndex
    }
  | {
      action: "asset-index-regions-ready"
      index: SearchIndex
    }
  | {
      action: "asset-index-asset-types-ready"
      index: SearchIndex
    }
  | {
      action: "asset-index-tags-ready"
      index: KeySearchIndex
    }

export type ClientStateReducer = Reducer<ClientState, ClientStateAction>

export const clientStateReducer: ClientStateReducer = (
  prevState,
  action,
): ClientState => {
  switch (action.action) {
    case "loading":
      return {
        status: "loading",
      }
    case "ready":
      return {
        status: "ready",
        client: action.client,
      }
    case "asset-index-data-ready":
      return {
        ...prevState,
        assetsIndexData: action.data,
      }
    case "asset-index-accounts-ready":
      return {
        ...prevState,
        assetsIndexAccounts: action.index,
      }
    case "asset-index-regions-ready":
      return {
        ...prevState,
        assetsIndexRegions: action.index,
      }
    case "asset-index-asset-types-ready":
      return {
        ...prevState,
        assetsIndexAssetTypes: action.index,
      }
    case "asset-index-tags-ready":
      return {
        ...prevState,
        assetsIndexTags: action.index,
      }
    case "asset-index-primary-ready":
      return {
        ...prevState,
        assetsIndexPrimary: action.index,
      }
    default:
      throw new Error(`Unknown action ${action}`)
  }
}

///////

export interface AssetDetailsState {
  asset?: AwsResource
  status: "loading" | "ready"
}

export const AssetDetailsContext = createContext<AssetDetailsState>({
  status: "loading",
})

type AssetDetailsAction =
  | { action: "loading" }
  | { action: "ready"; asset: AwsResource }

export type AssetDetailsReducer = Reducer<AssetDetailsState, AssetDetailsAction>

export const assetDetailsReducer: AssetDetailsReducer = (
  prevState,
  action,
): AssetDetailsState => {
  switch (action.action) {
    case "loading":
      return {
        status: "loading",
      }
    case "ready":
      return {
        status: "ready",
        asset: action.asset,
      }
    default:
      throw new Error(`Unknown action ${action}`)
  }
}

export const AssetDetailsDispatchContext =
  createContext<AssetDetailsReducer>(assetDetailsReducer)

type AssetListingAction =
  | { action: "loading"; assetFamily: string; assetType: string }
  | { action: "ready"; assets: ReadonlyArray<AwsResource> }

export type AssetListingReducer = Reducer<
  AssetsListingState,
  AssetListingAction
>

export const assetsListingReducer: AssetListingReducer = (
  prevState,
  action,
): AssetsListingState => {
  switch (action.action) {
    case "loading":
      return {
        status: "loading",
        assetFamilyLabel: assetFamilyDisplayName(action.assetFamily),
        assetTypeLabel: assetTypeDisplayName(
          action.assetFamily,
          action.assetType,
        ),
        assets: [],
      }
    case "ready":
      return {
        ...prevState,
        status: "ready",
        assetTypeLabel: `${prevState.assetTypeLabel} (${action.assets.length})`,
        assets: action.assets,
      }

    default:
      throw new Error(`Unknown action ${action}`)
  }
}
