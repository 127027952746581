import { ThemeId } from "../context/ClientsContext"

const KEY_THEME_ID = "themeId"
const DEFAULT_THEME_ID = "light"
export interface Storage {
  getThemeId: () => ThemeId
  setThemeId: (themeId: ThemeId) => void
}

export const storage: Storage = {
  getThemeId: (): ThemeId =>
    (localStorage.getItem(KEY_THEME_ID) ?? DEFAULT_THEME_ID) as ThemeId,
  setThemeId: (themeId: ThemeId): void => {
    localStorage.setItem(KEY_THEME_ID, themeId)
  },
}
