import { ColumnType } from "antd/es/table/interface"
import dt from "date-and-time"
import md5 from "md5"
import { Link } from "react-router-dom"

export const dateColumnRenderer = (timestamp): string =>
  dt.format(new Date(timestamp), "YYYY-MM-DD HH:mm")

export interface ColumnProps {
  title: string
  dataIndex: string | string[]
}

export const dateColumn = (props: ColumnProps): ColumnType<any> => ({
  ...props,
  render: dateColumnRenderer,
})

export const metaIdColumn = (): ColumnType<any> => ({
  title: "Meta id",
  dataIndex: ["_meta", "id"],
  sorter: (a, b) => a._meta.id.localeCompare(b._meta.id),
  render: (text, resource) => <Link to={resource._meta.id}>{text}</Link>,
  fixed: "left",
  width: 320,
})

export const displayNameColumn = (): ColumnType<any> => ({
  title: "Display Name",
  sorter: (a, b) =>
    (a._meta.displayName ?? "-").localeCompare(b._meta.displayName ?? "-"),
  render: (text, asset) => (
    <div style={{ minWidth: "150px" }}>{asset._meta.displayName ?? "-"}</div>
  ),
})

export const regionColumn = (): ColumnType<any> => ({
  title: "Region",
  dataIndex: ["_meta", "region"],
  sorter: (a, b) => a._meta.region.localeCompare(b._meta.region),
  render: (text) => text,
})

export const currentMonthCostColumn = (): ColumnType<any> => ({
  title: "Region",
  dataIndex: ["_meta", "region"],
  sorter: (a, b) => a._meta.region.localeCompare(b._meta.region),
  render: (text) => text,
})

export const accountColumn = (): ColumnType<any> => ({
  title: "Account",
  dataIndex: ["_meta", "accountId"],
  sorter: (a, b) =>
    a._meta.accountName
      ? a._meta.accountName.localeCompare(b._meta.accountName)
      : a._meta.accountId.localeCompare(b._meta.accountId),
  render: (text, record) => {
    return (
      <Link
        to={`../../account/account/${md5(record._meta.accountId)}`}
        relative="path"
      >
        {record._meta.accountName ?? text}
      </Link>
    )
  },
})
