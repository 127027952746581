import { App, Button, Form, Input, InputNumber, Select } from "antd"
import React, { useContext } from "react"
import { AuditSettingDto } from "../../../../common/model/api"
import { ApiContext } from "../../ApiContext"

type EditAuditSettingFormProps = {
  setting?: AuditSettingDto
  onComplete: (value: number | string | boolean | string[] | undefined) => void
  clientId: string
}

type EditAuditSettingFormValues = {
  value: string | number | boolean | string[]
}

const getValueInput = (setting: AuditSettingDto) => {
  switch (setting.type) {
    case "boolean":
      return (
        <Select style={{ width: "100%" }}>
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      )
    case "number":
      return <InputNumber style={{ width: "100%" }} />
    case "string[]":
      return <Select mode="tags" style={{ width: "100%" }} />
    default:
      return <Input style={{ width: "100%" }} />
  }
}

export const EditAuditSettingForm: React.FC<EditAuditSettingFormProps> = ({
  setting,
  onComplete,
  clientId,
}) => {
  const api = useContext(ApiContext)
  const { notification } = App.useApp()

  if (!setting) {
    return <></>
  }

  const onFinish = (request: EditAuditSettingFormValues) => {
    api
      .updateAuditSetting(clientId, {
        ...request,
        name: setting.name,
        product: setting.product,
        assetType: setting.assetType,
        rule: setting.rule,
      })
      .then((data) => {
        notification.success({ message: "Setting updated" })
        onComplete(request.value)
      })
      .catch((e) => {
        console.error(e)
        notification.error({
          message: "Failed to update setting",
          description: e.message,
        })
      })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }

  return (
    <Form
      requiredMark={false}
      name="basic"
      layout="vertical"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ value: setting.value ?? setting.defaultValue }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item<EditAuditSettingFormValues>
        label="Value"
        name="value"
        rules={[{ required: true }]}
      >
        {getValueInput(setting)}
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Update
        </Button>
        <Button type="link" onClick={() => onComplete(setting.value)}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  )
}
