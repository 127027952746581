import { OrganizationalUnitAsset } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  tableSection,
} from "../../asset-content"

export const awsOrganizationalUnits = assetContent<
  OrganizationalUnitAsset,
  OrganizationalUnitAsset
>({
  type: "organizations/organizational-unit",
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["accounts"],
    }),
    tableSection({
      asset,
      title: "Policy Types",
      data: () => (asset as any).PolicyTypes, // TODO: Not found from typings
    }),
    tableSection({
      asset,
      title: "Accounts",
      data: () => asset.accounts,
    }),
  ],
})
