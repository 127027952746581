import { Button, Table } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { ClientDto } from "../../../common/model/api"
import { ApiContext } from "../ApiContext"

const columns = [
  {
    title: "Action",
    key: "action",
    render: (text, record) => <Link to={`${record.id}/basic-info`}>View</Link>,
  },
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
]

export const ClientsPage: React.FC = () => {
  const api = useContext(ApiContext)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [clients, setClients] = useState<ReadonlyArray<ClientDto>>([])

  useEffect(() => {
    setLoading(true)
    api
      .listClients()
      .then((data) => {
        setClients(data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }, [api])

  return (
    <div>
      <h1>Clients</h1>
      <Table
        loading={loading}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={clients}
        style={{ paddingTop: "20px" }}
        pagination={{ hideOnSinglePage: true, pageSize: 20 }}
      />
      <div style={{ paddingTop: "20px" }}>
        <Button onClick={() => navigate("create")}>Create client</Button>
      </div>
    </div>
  )
}
