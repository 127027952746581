import React, { useContext, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { ApiContext } from "../ApiContext"
import { ClientsState } from "../context/ClientsContext"

export const ClientsContent: React.FC = () => {
  const api = useContext(ApiContext)
  const [clientsState, setClientsState] = useState<ClientsState>({
    clients: [],
  })

  useEffect(() => {
    api.listClients().then((clients) => {
      setClientsState({
        clients,
      })
    })
  }, [api])

  return <Outlet context={clientsState} />
}
