import { BeanstalkApplicationAsset } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
} from "../../asset-content"

export const awsBeanstalkApplications = assetContent<
  BeanstalkApplicationAsset,
  BeanstalkApplicationAsset
>({
  type: "beanstalk/application",
  list: {
    exclude: ["Description"],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["Versions", "ResourceLifecycleConfig"],
      additional: [
        { label: "Versions", value: () => asset.Versions?.length ?? 0 },
      ],
    }),
    descriptionSection({
      asset: asset.ResourceLifecycleConfig,
      title: "Resource Lifecycle Config",
    }),
  ],
})
