import { AwsAssetFamily, AwsAssetType } from "../../../common/aws/types"
import { toDisplayString } from "../utils"

const ASSET_FAMILY_LABELS: Partial<Record<AwsAssetFamily, string>> = {
  apigw: "API Gateway",
  dynamodb: "DynamoDB",
}

export const assetFamilyDisplayName = (assetFamily: string): string => {
  const label = ASSET_FAMILY_LABELS[assetFamily]
  if (label) {
    return label
  }

  if (assetFamily.length <= 3) {
    return assetFamily.toUpperCase()
  }

  return toDisplayString(assetFamily)
}

const ASSET_TYPE_LABELS: Partial<Record<AwsAssetType, string>> = {}

export const assetTypeDisplayName = (
  assetFamily: string,
  assetKind: string,
): string =>
  ASSET_TYPE_LABELS[assetFamily + "/" + assetKind] ?? toDisplayString(assetKind)
