import { NetworkAclAsset } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  tableSection,
} from "../../asset-content"

export const awsVpcNetworkAcls = assetContent<NetworkAclAsset>({
  type: "vpc/network-acl",
  details: (asset) => [
    basicAssetDetails({ asset, exclude: ["Associations", "Entries"] }),
    tableSection({
      asset,
      title: "Entries",
      data: () => asset.Entries,
    }),
    tableSection({
      asset,
      title: "Associations",
      data: () => asset.Associations!,
    }),
  ],
})
