import { WebAclAsset } from "../../../../../common/model/data/aws-data"
import { assetContent } from "../../asset-content"

export const awsCloudfrontWebAcls = assetContent<WebAclAsset>({
  type: "waf/cloudfront-web-acl",
  list: {
    filtered: ["ManagedByFirewallManager"],
    exclude: ["Description"],
  },
})
