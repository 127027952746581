import { RouteTableResource } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  tableSection,
} from "../../asset-content"

export const awsVpcRouteTables = assetContent<RouteTableResource>({
  type: "vpc/route-table",
  details: (asset) => [
    basicAssetDetails({ asset, exclude: ["Associations", "Routes"] }),
    tableSection({
      asset,
      title: "Associations",
      data: () => asset.Associations!,
    }),
  ],
})
