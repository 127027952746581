import { ApiStageAsset } from "../../../../../common/model/data/aws-data"
import { assetContent } from "../../asset-content"

export const awsApigwApiStages = assetContent<ApiStageAsset, ApiStageAsset>({
  type: "apigw/api-stage",
  list: {
    exclude: ["LastDeploymentStatusMessage", "Description"],
    filtered: ["ApiGatewayManaged", "AutoDeploy"],
  },
})
