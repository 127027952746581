import {
  IamGroupListAsset,
  IamGroupResource,
} from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  tableSection,
} from "../../asset-content"

export const awsIamGroupsContent = assetContent<
  IamGroupListAsset,
  IamGroupResource
>({
  type: "iam/group",
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["users", "inlinePolicies", "policies"],
    }),
    tableSection({ asset, title: "Users", data: () => asset.users }),
    tableSection({
      asset,
      title: "Policies",
      data: () => asset.policies,
      columns: [
        { label: "Policy Name", value: (a) => a.PolicyName },
        {
          label: "Policy Document",
          value: (a) => {
            return JSON.stringify(
              JSON.parse(decodeURIComponent(a.policyDocument)),
              undefined,
              2,
            )
          },
          displayValue: (v: string) => <pre>{v}</pre>,
        },
      ],
    }),
    tableSection({
      asset,
      title: "Inline Policies",
      data: () => asset.inlinePolicies,
      columns: [
        { label: "Policy Name", value: (a) => a.PolicyName },
        {
          label: "Policy Document",
          value: (a) => {
            return JSON.stringify(
              JSON.parse(decodeURIComponent(a.PolicyDocument)),
              undefined,
              2,
            )
          },
          displayValue: (v: string) => <pre>{v}</pre>,
        },
      ],
    }),
  ],
})
