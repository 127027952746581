import { Button, Table } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { AwsBillingConfig } from "../../../../common/model/data/dynamodb-types"
import { ApiContext } from "../../ApiContext"

const columns = [
  {
    title: "Action",
    key: "action",
    render: (text, record) => <Link to={`${record.id}/basic-info`}>View</Link>,
  },
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Report Type",
    dataIndex: "reportType",
    key: "reportType",
  },
  {
    title: "First Billing Month",
    dataIndex: "firstBillingMonth",
    key: "firstBillingMonth",
  },
]

/*
     {
            "enabled": true,
            "reportBucketName": "webscale-billing",
            "reportName": "standard-daily",
            "reportBucketPrefix": "billing",
            "accountId": "639105102536",
            "reportBucketRegion": "eu-north-1",
            "id": "354670e6-97dc-47ab-a2c6-cadd1d9645a6",
            "reportType": "standard",
            "roleName": "metamesh-reader",
            "firstBillingMonth": "2023/10"
        }
 */
export const BillingConfigsPage: React.FC = () => {
  const api = useContext(ApiContext)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [billingConfigs, setBillingConfigs] = useState<
    ReadonlyArray<AwsBillingConfig>
  >([])

  useEffect(() => {
    setLoading(true)
    api
      .listBillingConfigs()
      .then((data) => {
        setBillingConfigs(data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }, [api])

  return (
    <div>
      <h1>Billing Configs</h1>
      <Table
        loading={loading}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={billingConfigs}
        style={{ paddingTop: "20px" }}
        pagination={{ hideOnSinglePage: true, pageSize: 20 }}
      />
      <div style={{ paddingTop: "20px" }}>
        <Button onClick={() => navigate("create")}>
          Create Billing Config
        </Button>
      </div>
    </div>
  )
}
