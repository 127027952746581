import { Col, Row, Table } from "antd"
import { ColumnType } from "antd/es/table/interface"
import _ from "lodash"
import React, { useContext, useEffect, useState } from "react"
import { CSVLink } from "react-csv"
import { Link } from "react-router-dom"
import { AwsAssetAuditFinding } from "../../../../common/aws/types"
import { ApiContext } from "../../ApiContext"
import {
  SelectedClientContext,
  SelectedClientReadyState,
} from "../../context/ClientsContext"

export const AwsAuditFindingsContent: React.FC = (props) => {
  const api = useContext(ApiContext)
  const selectedClient = useContext(SelectedClientContext)

  const [loading, setLoading] = useState(true)
  const [findings, setFindings] =
    useState<ReadonlyArray<AwsAssetAuditFinding>>(undefined)

  useEffect(() => {
    if (!(selectedClient instanceof SelectedClientReadyState)) {
      return
    }

    const { id, syncJobs } = selectedClient.client
    setLoading(true)

    // TODO: Handle no syncJobs exist
    // TODO: Handle summary not found
    api
      .getAwsAuditData({ clientId: id, syncJob: syncJobs[0] })
      .then((data) => {
        setFindings(data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }, [selectedClient, api])

  const assetTypeFilters = loading
    ? []
    : _.uniq(findings.map((f) => f.assetType)).map((f) => ({
        text: f,
        value: f,
      }))

  const findingTypeFilters = loading
    ? []
    : _.uniq(findings.map((f) => f.type)).map((f) => ({
        text: f,
        value: f,
      }))

  const findingIdFilters = loading
    ? []
    : _.uniq(findings.map((f) => f.id)).map((f) => ({
        text: f,
        value: f,
      }))

  const findingAccountIdFilters = loading
    ? []
    : _.uniq(findings.map((f) => f.accountId)).map((f) => ({
        text: f,
        value: f,
      }))

  const findingRegionFilters = loading
    ? []
    : _.uniq(findings.map((f) => f.region)).map((f) => ({
        text: f,
        value: f,
      }))

  const columns: Array<ColumnType<any>> = [
    {
      title: "Asset Id",
      dataIndex: "assetId",
      key: "assetId",
      fixed: "left",
      sorter: (a, b) => a.assetId.localeCompare(b.assetId),
      render: (v, record) => (
        <Link to={`../../aws/${record.assetType}/${record.assetId}`}>
          {record.assetId}
        </Link>
      ),
    },
    {
      title: "Asset Display Name",
      dataIndex: "assetDisplayName",
      key: "assetDisplayName",
      sorter: (a, b) => a.assetDisplayName.localeCompare(b.assetDisplayName),
      render: (v, record) => (
        <Link to={`../../aws/${record.assetType}/${record.assetId}`}>
          {record.assetDisplayName}
        </Link>
      ),
    },
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      width: 150,
      sorter: (a, b) => a.severity.localeCompare(b.severity),
      onFilter: (value: string, record) => record.severity === value,
      filters: [
        {
          text: "High",
          value: "high",
        },
        {
          text: "Medium",
          value: "medium",
        },
        {
          text: "Low",
          value: "low",
        },
      ],
    },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id.localeCompare(b.id),
      onFilter: (value: string, record) => record.id === value,
      filters: findingIdFilters,
      filterSearch: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 150,
      sorter: (a, b) => a.type.localeCompare(b.type),
      onFilter: (value: string, record) => record.type === value,
      filters: findingTypeFilters,
      filterSearch: true,
    },
    {
      title: "Account Id",
      dataIndex: "accountId",
      key: "accountId",
      width: 150,
      sorter: (a, b) => a.accountId.localeCompare(b.accountId),
      onFilter: (value: string, record) => record.accountId === value,
      filters: findingAccountIdFilters,
      filterSearch: true,
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      width: 150,
      sorter: (a, b) => a.region.localeCompare(b.region),
      onFilter: (value: string, record) => record.region === value,
      filters: findingRegionFilters,
      filterSearch: true,
    },

    {
      title: "Asset Type",
      dataIndex: "assetType",
      key: "assetType",
      sorter: (a, b) => a.assetType.localeCompare(b.assetType),
      onFilter: (value: string, record) => record.assetType === value,
      filters: assetTypeFilters,
      filterSearch: true,
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      width: 150,
      sorter: (a, b) => {
        if (!a.cost && !b.cost) {
          return 0
        }
        if (a.cost && !b.cost) {
          return 1
        }
        if (!a.cost && b.cost) {
          return -1
        }

        return a.cost - b.cost
      },
      render: (v) => {
        if (typeof v === "number") {
          return v.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        }

        return "-"
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (v) => {
        return <div style={{ minWidth: "100px" }}>{v ?? "-"}</div>
      },
    },
  ]

  return (
    <div>
      <Row>
        <Col span={20}></Col>
        <Col span={4} style={{ paddingTop: "20px", textAlign: "right" }}>
          <CSVLink
            data={findings ?? []}
            filename={`audit-findings.csv`}
            className="btn btn-primary"
            target="_blank"
          >
            Export to CSV
          </CSVLink>
        </Col>
      </Row>
      <Table
        rowKey={(record) => record.id + record.assetId}
        loading={loading}
        columns={columns}
        dataSource={findings}
        style={{ paddingTop: "20px" }}
        scroll={{
          y: 600,
          x: "max-content",
          scrollToFirstRowOnChange: true,
        }}
      />
    </div>
  )
}
