import { PlusOutlined } from "@ant-design/icons"
import { Button, Divider, Drawer, List } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ProductConfig } from "../../../../common/model/asset-data-sources"
import { AssetGroupRule } from "../../../../common/model/asset-group-rules"
import { ApiContext } from "../../ApiContext"
import { renderAssetDataSources } from "./AssetDataSources"

export const ClientAssetDataSourcesContent: React.FC = () => {
  const api = useContext(ApiContext)
  const { clientId } = useParams()
  const [dataSources, setDataSources] = useState<ReadonlyArray<ProductConfig>>(
    [],
  )
  const [drawerMode, setDrawerMode] = useState("none")
  const [editedDataSource, setEditedDataSource] = useState<AssetGroupRule>()

  const openCreateDrawer = () => {
    setDrawerMode("create")
  }

  const openEditDrawer = (rule: AssetGroupRule) => {
    setEditedDataSource(JSON.parse(JSON.stringify(rule))) // TODO: Use proper clone
    setDrawerMode("edit")
  }

  const closeDrawer = () => {
    setDrawerMode("none")
    console.log(editedDataSource)
    setEditedDataSource(undefined)
  }

  useEffect(() => {
    api
      .listAssetDataSources(clientId)
      .then((data) => {
        setDataSources(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [clientId, api])

  const onDataSourcesChanged = async () => {
    api
      .listAssetDataSources(clientId)
      .then((data) => {
        setDataSources(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const drawerTitle =
    drawerMode === "create" ? "New Data Source" : "Edit Data Source"

  const onDataSourceDeleted = async () => {
    await onDataSourcesChanged()
  }

  // const onDataSourceUpdated = async () => {
  //   closeDrawer()
  //   await onDataSourcesChanged()
  // }

  return (
    <>
      <List
        itemLayout="vertical"
        dataSource={dataSources.slice()}
        renderItem={(dataSource) =>
          renderAssetDataSources({
            dataSource,
            onDataSourceDeleted,
            openEditDrawer,
          })
        }
      />
      <Divider />
      <Button icon={<PlusOutlined />} onClick={openCreateDrawer}>
        New Data Source
      </Button>
      <Drawer
        title={drawerTitle}
        width={720}
        onClose={closeDrawer}
        open={drawerMode !== "none"}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        {/*<AssetGroupRuleForm*/}
        {/*  editedRule={editedDataSource}*/}
        {/*  onRuleUpdated={onRuleUpdated}*/}
        {/*  cancel={closeDrawer}*/}
        {/*  clientId={clientId}*/}
        {/*/>*/}
      </Drawer>
    </>
  )
}
