import {
  ServerError,
  TokenExpiredError,
  UnauthorizedError,
} from "../errors/api-errors"

const TOKEN_EXPIRATION_MESSAGE = "The incoming token has expired"

const responseHandler = async (response: Response): Promise<any> => {
  if (response.ok) {
    return response.json()
  }

  const responseData = await response.json()
  const errorData = {
    statusCode: response.status,
    statusText: response.statusText,
    response: responseData,
  }
  if (
    response.status === 401 &&
    responseData.message === TOKEN_EXPIRATION_MESSAGE
  ) {
    throw new TokenExpiredError(errorData)
  }
  if (response.status === 403) {
    throw new UnauthorizedError(errorData)
  }

  throw new ServerError(errorData)
}

const serializeQueryString = (
  queryStringParams?: Record<string, string | number>,
): string => {
  if (!queryStringParams) {
    return ""
  }

  return (
    "?" +
    Object.entries(queryStringParams)
      .filter(([key, value]) => value !== undefined)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&")
  )
}

const getWithTokenProvider =
  (authTokenProvider: () => Promise<String>) =>
  <T>(
    url: string,
    queryStringParams?: Record<string, string | number>,
  ): Promise<T> =>
    authTokenProvider()
      .then((token) =>
        fetch(`${url}${serializeQueryString(queryStringParams)}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }),
      )
      .then(responseHandler)

const putWithTokenProvider =
  (authTokenProvider: () => Promise<String>) =>
  <T>(url: string, payload?: unknown): Promise<T> =>
    authTokenProvider()
      .then((token) =>
        fetch(url, {
          method: "PUT",
          body: payload ? JSON.stringify(payload) : undefined,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }),
      )
      .then(responseHandler)

const delWithTokenProvider =
  (authTokenProvider: () => Promise<String>) =>
  <T>(url: string): Promise<T> =>
    authTokenProvider()
      .then((token) =>
        fetch(url, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }),
      )
      .then(responseHandler)

const postWithTokenProvider =
  (authTokenProvider: () => Promise<String>) =>
  <T>(url: string, payload?: unknown): Promise<T> =>
    authTokenProvider()
      .then((token) =>
        fetch(url, {
          method: "POST",
          body: payload ? JSON.stringify(payload) : undefined,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }),
      )
      .then(responseHandler)

export interface HttpClient {
  get: <T>(
    url: string,
    queryStringParams?: Record<string, string | number>,
  ) => Promise<T>
  post: <T>(url: string, payload?: unknown) => Promise<T>
  put: <T>(url: string, payload?: unknown) => Promise<T>
  del: <T>(url: string) => Promise<T>
}

export const httpClientUsing = (
  authTokenProvider: () => Promise<string>,
): HttpClient => ({
  get: getWithTokenProvider(authTokenProvider),
  put: putWithTokenProvider(authTokenProvider),
  post: postWithTokenProvider(authTokenProvider),
  del: delWithTokenProvider(authTokenProvider),
})
