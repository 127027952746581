import {
  CreateAssetDataSourceRequest,
  UpdateAssetDataSourceRequest,
} from "../../../common/model/api"
import { ProductConfig } from "../../../common/model/asset-data-sources"
import { HttpClient } from "./http-client"
import { extractData } from "./response-mappers"

const BASE_PATH = "/api/asset-data-sources"

export interface AssetDataSourcesApi {
  listAssetDataSources: (
    clientId?: string,
  ) => Promise<ReadonlyArray<ProductConfig>>
  deleteAssetDataSource: (clientId: string, ruleId: string) => Promise<void>
  updateAssetDataSource: (
    clientId: string,
    ruleId: string,
    request: UpdateAssetDataSourceRequest,
  ) => Promise<void>
  createAssetDataSource: (
    request: CreateAssetDataSourceRequest,
  ) => Promise<void>
}

export const assetDataSourcesApiUsing = ({
  get,
  del,
  put,
  post,
}: HttpClient): AssetDataSourcesApi => ({
  listAssetDataSources: (clientId?) =>
    get(BASE_PATH, { clientId }).then(extractData),
  deleteAssetDataSource: (clientId, id) =>
    del(`${BASE_PATH}/${clientId}/${id}`),
  updateAssetDataSource: (clientId, id, request) =>
    put(`${BASE_PATH}/${clientId}/${id}`, request),
  createAssetDataSource: (request) => post(BASE_PATH, request),
})
