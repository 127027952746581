import { AutoScalingGroupAsset } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
  tableSection,
} from "../../asset-content"

export const awsAutoscalingGroups = assetContent<
  AutoScalingGroupAsset,
  AutoScalingGroupAsset
>({
  type: "autoscaling/autoscaling-group",
  list: {
    filtered: ["HealthCheckType", "NewInstancesProtectedFromScaleIn"],
    exclude: [
      "AutoScalingGroupARN",
      "VPCZoneIdentifier",
      "ServiceLinkedRoleARN",
    ],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["Instances", "LaunchTemplate"],
    }),
    descriptionSection({
      asset: asset.LaunchTemplate,
      title: "Launch Template",
    }),
    tableSection({ asset, title: "Instances", data: () => asset.Instances }),
  ],
})
