import { GuardDutyFindingAsset } from "../../../../../common/model/data/aws-data"
import { assetContent } from "../../asset-content"

export const awsGuardDutyFindings = assetContent<
  GuardDutyFindingAsset,
  GuardDutyFindingAsset
>({
  type: "guardduty/finding",
  list: {
    filtered: ["AccountId", "Type", "Severity", "Region", "SchemaVersion"],
  },
})
