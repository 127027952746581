import { Button, Form, Input, Select } from "antd"
import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { CreateUserRequest } from "../../../common/model/api"
import { ApiContext } from "../ApiContext"

export const CreateUserPage: React.FC = () => {
  const api = useContext(ApiContext)
  const navigate = useNavigate()

  const onFinish = (request: CreateUserRequest) => {
    api.createUser(request).then((data) => {
      navigate(`../${data.id}`, { relative: "path" })
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }

  return (
    <div>
      <h1>Create user</h1>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ email: "", role: "user" }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<CreateUserRequest>
          label="Email"
          name="email"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<CreateUserRequest>
          label="Role"
          name="role"
          rules={[{ required: true }]}
        >
          <Select
            style={{ width: 120 }}
            options={[
              { value: "user", label: "User" },
              { value: "admin", label: "Admin" },
            ]}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
          <Button
            type="link"
            onClick={() => navigate("../", { relative: "path" })}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
