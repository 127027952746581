import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth"
import { CognitoUser } from "amazon-cognito-identity-js"
import { Amplify, Auth } from "aws-amplify"
import { createRoot } from "react-dom/client"
import { ApiContext } from "./ApiContext"
import { App } from "./App"
import { UserSessionContext } from "./UserSessionContext"
import { createApiUsing } from "./api"
import { getConfig } from "./config"
import "./index.css"

Amplify.configure({ ...getConfig(window.location.hostname) })

const refreshSessionIfNeeded = (user: CognitoUser) =>
  new Promise((resolve, reject) => {
    if (!user.getSignInUserSession().isValid()) {
      user.refreshSession(
        user.getSignInUserSession().getRefreshToken(),
        (err, result) => {
          if (err) {
            reject(err)
          } else {
            resolve(result)
          }
        },
      )
    } else {
      resolve(undefined)
    }
  })

Auth.currentAuthenticatedUser()
  .then((user: CognitoUser) => {
    const idToken = user.getSignInUserSession().getIdToken()

    const idTokenProvider = async () => {
      await refreshSessionIfNeeded(user)
      return user.getSignInUserSession().getIdToken().getJwtToken()
    }

    const accessTokenProvider = async () => {
      await refreshSessionIfNeeded(user)
      return user.getSignInUserSession().getAccessToken().getJwtToken()
    }

    const session = {
      username: user.getUsername(),
      firstName: idToken.payload.given_name,
      lastName: idToken.payload.family_name,
      roleProvider: async () => {
        await refreshSessionIfNeeded(user)
        return user.getSignInUserSession().getIdToken().payload.role
      },
      accessTokenProvider,
      idTokenProvider,
    }

    const api = createApiUsing(accessTokenProvider)

    const root = createRoot(document.getElementById("root")!)

    root.render(
      // <React.StrictMode> Use strict mode in development time only to detect potential problems
      <UserSessionContext.Provider value={session}>
        <ApiContext.Provider value={api}>
          <App />
        </ApiContext.Provider>
      </UserSessionContext.Provider>,
      // </React.StrictMode>,
    )
  })
  .catch((e) => {
    console.log("authentication failed", e)

    // How to handle the case where user is not found from DynamoDB
    if (String(e) === "The user is not authenticated") {
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Cognito,
      }).then((user) => user.accessKeyId)
    }
  })
