import { TrailResource } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
} from "../../asset-content"

export const awsCloudtrailTrails = assetContent<TrailResource, TrailResource>({
  type: "cloudtrail/trail",
  details: (asset) => [
    basicAssetDetails({ asset }),
    descriptionSection({
      asset: asset.status,
      title: "Status",
    }),
  ],
})
