import { ResourceRecordSetResource } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
  tableSection,
} from "../../asset-content"

export const awsRoute53RecordSets = assetContent<
  ResourceRecordSetResource,
  ResourceRecordSetResource
>({
  type: "route53/record-set",
  list: {
    filtered: ["Type", "hostedZoneId", "hostedZoneName", "SetIdentifier"],
  },
  details: (asset) => [
    basicAssetDetails({ asset, exclude: ["AliasTarget", "ResourceRecords"] }),
    tableSection({
      asset,
      title: "Resource Records",
      data: () => asset.ResourceRecords,
    }),
    descriptionSection({ title: "Alias Target", asset: asset.AliasTarget }),
  ],
})
