import { NetworkAclAsset } from "../../../../../common/model/data/aws-data"
import { assetContent } from "../../asset-content"

export const awsEc2NetworkAcsl = assetContent<NetworkAclAsset, NetworkAclAsset>(
  {
    type: "vpc/network-acl",
    list: {
      filtered: ["IsDefault", "VpcId"],
    },
  },
)
