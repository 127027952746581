import prettyBytes from "pretty-bytes"
import { DynamoDbTableResource } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
  tableSection,
} from "../../asset-content"

export const awsDynamoDbTables = assetContent<
  DynamoDbTableResource,
  DynamoDbTableResource
>({
  type: "dynamodb/table",
  list: {
    filtered: ["DeletionProtectionEnabled", "TableStatus"],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: [
        "BillingModeSummary",
        "ProvisionedThroughput",
        "AttributeDefinitions",
        "StreamSpecification",
        "TableSizeBytes",
        "KeySchema",
      ],
      additional: [
        {
          label: "Billing Mode",
          value: () => asset.BillingModeSummary?.BillingMode,
        },
        {
          label: "Table Size",
          value: () => prettyBytes(asset.TableSizeBytes, { bits: true }),
        },
      ],
    }),
    descriptionSection({
      asset: asset.ProvisionedThroughput,
      title: "Provisioned Throughput",
    }),
    descriptionSection({
      asset: asset.StreamSpecification,
      title: "Stream Specification",
    }),
    tableSection({
      asset: asset.KeySchema,
      title: "Key Schema",
      data: () => asset.KeySchema,
    }),
    tableSection({
      asset: asset.AttributeDefinitions,
      title: "Attribute Definitions",
      data: () => asset.AttributeDefinitions,
    }),
  ],
})
