import {
  IamPolicyAsset,
  IamPolicyListAsset,
} from "../../../../../common/model/data/aws-data"
import { assetContent, basicAssetDetails } from "../../asset-content"

export const awsIamPolicies = assetContent<IamPolicyListAsset, IamPolicyAsset>({
  type: "iam/policy",
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["policyDocument"],
      additional: [
        {
          label: "Policy Document",
          value: "policyDocument",
          render: (val: string) => (
            <pre>
              {JSON.stringify(
                JSON.parse(decodeURIComponent(val)),
                undefined,
                2,
              )}
            </pre>
          ),
        },
      ],
    }),
  ],
})
