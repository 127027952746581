import {
  StackSetSummaryAsset,
  StackSetSummaryListAsset,
} from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  simpleTableSection,
} from "../../asset-content"

export const awsCloudformationStackSetContent = assetContent<
  StackSetSummaryListAsset,
  StackSetSummaryAsset
>({
  type: "cloudformation/stack-set",
  // list: {
  //   filtered: ["StackStatus", "EnableTerminationProtection", "DisableRollback"],
  // },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["instances", "operations"],
    }),
    simpleTableSection({ asset, property: "instances" }),
    simpleTableSection({ asset, property: "operations" }),
  ],
})
