import { SnapshotAsset } from "../../../../../common/model/data/aws-data"
import { assetContent, basicAssetDetails } from "../../asset-content"

export const awsEc2Snapshots = assetContent<SnapshotAsset, SnapshotAsset>({
  type: "ec2/snapshot",
  list: {
    exclude: ["Description", "Progress"],
    filtered: ["Encrypted", "State", "StorageTier"],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["VolumeSize", "Progress"],
      additional: [
        { label: "VolumeSize", value: () => `${asset.VolumeSize} GiB` },
      ],
    }),
  ],
})
