import { ExportOutlined } from "@ant-design/icons"
import type { TabsProps } from "antd"
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Row,
  Space,
  Spin,
  Tabs,
  Typography,
} from "antd"
import React, { useContext, useEffect, useReducer, useState } from "react"
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import { AwsResource } from "../../../common/model/common"
import { ApiContext } from "../ApiContext"
import { linkToAwsConsole } from "../content/links"
import { assetFamilyDisplayName, assetTypeDisplayName } from "../content/texts"
import {
  AssetDetailsContext,
  assetDetailsReducer,
  AssetsListingContext,
  SelectedClientContext,
  SelectedClientReadyState,
} from "../context/ClientsContext"

const { Text } = Typography

const assetNavigationLinks = (
  assetId: string,
  assets: ReadonlyArray<AwsResource>,
  currentTab: string,
) => {
  const currentAssetIndex = assets.findIndex((a) => a._meta.id === assetId)

  // Asset not found from the asset list filtered by top-filters
  if (currentAssetIndex === -1) {
    return (
      <Space style={{ float: "right" }} size="small">
        <Text disabled={true}>Asset not found with search</Text>
      </Space>
    )
  }

  const assetCount = assets.length
  const previousAsset = assets[currentAssetIndex - 1]
  const nextAsset = assets[currentAssetIndex + 1]

  const previousAssetLink = previousAsset ? (
    <Link to={`../${previousAsset._meta.id}/${currentTab}`}>Previous</Link>
  ) : (
    <Text disabled={true}>Previous</Text>
  )

  const nextAssetLink = nextAsset ? (
    <Link to={`../${nextAsset._meta.id}/${currentTab}`}>Next</Link>
  ) : (
    <Text disabled={true}>Next</Text>
  )

  return (
    <Space style={{ float: "right" }} size="small">
      {previousAssetLink}
      <Divider type="vertical" />
      <Text style={{ whiteSpace: "nowrap" }}>
        {currentAssetIndex + 1} / {assetCount}
      </Text>
      <Divider type="vertical" />
      {nextAssetLink}
    </Space>
  )
}

export const AssetDetailsContainerContent: React.FC = () => {
  const location = useLocation()
  const assetsListingState = useContext(AssetsListingContext)
  const [assetDetailsState, dispatchAssetDetails] = useReducer(
    assetDetailsReducer,
    {
      status: "loading",
    },
  )
  const api = useContext(ApiContext)
  const selectedClient = useContext(SelectedClientContext)
  const navigate = useNavigate()
  const { assetFamily, assetType, assetId } = useParams()
  const [currentTab, setCurrentTab] = useState("details")

  useEffect(() => {
    if (!(selectedClient instanceof SelectedClientReadyState)) {
      return
    }

    const { id, syncJobs } = selectedClient.client

    dispatchAssetDetails({ action: "loading" })

    api
      .getAwsResourceDetails({
        clientId: id,
        syncJob: syncJobs[0],
        assetType,
        assetFamily,
        assetId,
      })
      .then((asset) => {
        dispatchAssetDetails({ asset, action: "ready" })
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error)
      })
  }, [assetFamily, assetType, assetId, selectedClient, api])

  useEffect(() => {
    if (location.pathname.endsWith("/raw")) {
      setCurrentTab("raw")
    } else if (location.pathname.endsWith("/cost")) {
      setCurrentTab("cost")
    } else if (location.pathname.endsWith("/dependencies")) {
      setCurrentTab("dependencies")
    } else if (location.pathname.endsWith("/dependants")) {
      setCurrentTab("dependants")
    } else if (location.pathname.endsWith("/audit")) {
      setCurrentTab("audit")
    } else {
      setCurrentTab("details")
    }
  }, [location])

  if (assetDetailsState.status === "loading") {
    return (
      <div style={{ paddingTop: "20px" }}>
        <Spin delay={500} />
      </div>
    )
  }

  const tabs: TabsProps["items"] = [
    {
      key: "details",
      label: "Details",
    },
    {
      key: "dependencies",
      label: assetDetailsState.asset._meta.relations
        ? `Dependencies (${assetDetailsState.asset._meta.relations.dependencyCount})`
        : "Dependencies",
    },
    {
      key: "dependants",
      label: assetDetailsState.asset._meta.relations
        ? `Dependants (${assetDetailsState.asset._meta.relations.dependantCount})`
        : "Dependants",
    },
    {
      key: "cost",
      label: assetDetailsState.asset._meta.cost
        ? "Cost (" +
          assetDetailsState.asset._meta.cost.currentMonthCost!.toLocaleString(
            "en-US",
            {
              style: "currency",
              currency: "USD",
            },
          ) +
          ")"
        : "Cost",
    },
    {
      key: "audit",
      label: assetDetailsState.asset._meta.audit
        ? `Audit (${assetDetailsState.asset._meta.audit?.findingCount ?? 0})`
        : "Audit",
    },
    {
      key: "raw",
      label: "Raw Data",
    },
  ]

  const awsLink =
    assetDetailsState.status === "ready"
      ? linkToAwsConsole(assetDetailsState.asset)
      : undefined

  return (
    <AssetDetailsContext.Provider value={assetDetailsState}>
      <Row>
        <Col span={20}>
          <Breadcrumb
            style={{ paddingTop: "20px" }}
            items={[
              {
                title: (
                  <Link to="../../" relative="path">
                    {assetFamilyDisplayName(assetFamily)}
                  </Link>
                ),
              },

              {
                title: (
                  <Link to="../" relative="path">
                    {assetTypeDisplayName(assetFamily, assetType) +
                      ` (${assetsListingState.assets.length})`}
                  </Link>
                ),
              },
              {
                title:
                  assetDetailsState.status === "ready" ? (
                    <span>
                      {assetDetailsState.asset._meta.displayName}
                      {awsLink ? (
                        <Button
                          type="link"
                          size="small"
                          target="_blank"
                          title="View in AWS management console"
                          href={awsLink}
                          style={{ marginLeft: "5px" }}
                          icon={<ExportOutlined />}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  ) : (
                    ""
                  ),
              },
            ]}
          />
        </Col>
        <Col span={4} style={{ paddingTop: "20px" }}>
          {assetNavigationLinks(assetId, assetsListingState.assets, currentTab)}
        </Col>
      </Row>

      <Tabs
        defaultActiveKey={currentTab}
        items={tabs}
        onTabClick={(key) => navigate(key)}
      />
      <Outlet />
      <div style={{ paddingTop: "20px" }}></div>
    </AssetDetailsContext.Provider>
  )
}
