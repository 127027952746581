import {
  IamRoleListAsset,
  IamRoleResource,
} from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  tableSection,
} from "../../asset-content"

export const awsIamRolesContent = assetContent<
  IamRoleListAsset,
  IamRoleResource
>({
  type: "iam/role",
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["inlinePolicies", "policies", "AssumeRolePolicyDocument"],
      additional: [
        {
          label: "Assume Role Policy Document",
          value: "AssumeRolePolicyDocument",
          render: (val: string) => (
            <pre>
              {JSON.stringify(
                JSON.parse(decodeURIComponent(val)),
                undefined,
                2,
              )}
            </pre>
          ),
        },
      ],
    }),
    tableSection({
      asset,
      title: "Inline Policies",
      data: () => asset.inlinePolicies,
      columns: [
        { label: "Policy Name", value: (a) => a.PolicyName },
        {
          label: "Policy Document",
          value: (a) => {
            return JSON.stringify(
              JSON.parse(decodeURIComponent(a.PolicyDocument)),
              undefined,
              2,
            )
          },
          displayValue: (v: string) => <pre>{v}</pre>,
        },
      ],
    }),
    tableSection({
      asset,
      title: "Policies",
      data: () => asset.policies,
      columns: [
        { label: "Policy Name", value: (a) => a.PolicyName },
        {
          label: "Policy Document",
          value: (a) => {
            return JSON.stringify(
              JSON.parse(decodeURIComponent(a.policyDocument)),
              undefined,
              2,
            )
          },
          displayValue: (v: string) => <pre>{v}</pre>,
        },
      ],
    }),
  ],
})
