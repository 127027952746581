import { Button, Descriptions, DescriptionsProps, Form, Select } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { UpdateUserRequest, UserDto } from "../../../common/model/api"
import { ApiContext } from "../ApiContext"

export const UserPage: React.FC = () => {
  const api = useContext(ApiContext)
  const { userId } = useParams()
  const [user, setUser] = useState<UserDto>(undefined)
  const [mode, setMode] = useState("loading")

  const onFinish = (request: UpdateUserRequest) => {
    api.updateUser(userId, request).then((data) => {
      setUser(data)
      setMode("view")
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }

  useEffect(() => {
    setMode("loading")
    api
      .getUser(userId)
      .then((data) => {
        setUser(data)
        setMode("view")
      })
      .catch((error) => {
        console.log(error)
        setMode("error")
      })
  }, [userId, api])

  if (mode === "loading") {
    return <div>Loading...</div>
  }

  if (mode === "view" && user !== undefined) {
    const descriptions: DescriptionsProps["items"] = [
      {
        key: "0",
        label: "Id",
        children: user.id,
      },
      {
        key: "1",
        label: "Email",
        children: user.email,
      },
      {
        key: "2",
        label: "Role",
        children: user.role,
      },
      {
        key: "3",
        label: "Permissions",
        children: user.permissions,
      },
    ]

    return (
      <div>
        <Link to="/app/admin/users">Back to users listing</Link>
        <Descriptions title="Basic" items={descriptions} />
        <Button onClick={() => setMode("edit")}>Edit</Button>
      </div>
    )
  }

  if (mode === "edit") {
    return (
      <div>
        <Link to="/app/admin/users">Back to users listing</Link>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ role: user.role }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<UpdateUserRequest>
            label="Role"
            name="role"
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: 120 }}
              options={[
                { value: "user", label: "User" },
                { value: "admin", label: "Admin" },
              ]}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button type="link" onClick={() => setMode("view")}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }

  return <div>Error</div>
}
