import { RecoveryPointAsset } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
} from "../../asset-content"

export const awsBackupRecoveryPoints = assetContent<
  RecoveryPointAsset,
  RecoveryPointAsset
>({
  type: "backup/recovery-point",
  list: {
    // exclude: ["Description"],
    filtered: [
      "BackupVaultName",
      "BackupVaultArn",
      "EncryptionKeyArn",
      "IsEncrypted",
      "IsParent",
      "IamRoleArn",
      "ResourceArn",
      "ResourceType",
      "ResourceName",
      "Status",
      "VaultType",
    ],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["CreatedBy", "Lifecycle", "CalculatedLifecycle"],
    }),
    descriptionSection({
      asset: asset.CreatedBy,
      title: "Created By",
    }),
    descriptionSection({
      asset: asset.Lifecycle,
      title: "Lifecycle",
    }),
    descriptionSection({
      asset: asset.CalculatedLifecycle,
      title: "Calculated Lifecycle",
    }),
  ],
})
