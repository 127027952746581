import { BucketResource } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
} from "../../asset-content"

export const awsS3Buckets = assetContent<BucketResource, BucketResource>({
  type: "s3/bucket",
  list: {
    columns: [{ dataIndex: ["bucket", "Name"], title: "Name" }],
  },
  details: (asset) => [
    basicAssetDetails({
      asset: {
        ...asset.bucket,
        RequestPayer: asset.requestPayment.Payer,
        versioningStatus: asset.versioning?.Status,
        versioningMfaDelete: asset.versioning?.MFADelete,
      },
    }),
    descriptionSection({
      enabled: asset.publicAccessBlock !== undefined,
      asset: {
        IsPublic: asset.policyStatus?.IsPublic,
        ...asset.publicAccessBlock,
      },
      title: "Public Access",
    }),
    descriptionSection({
      enabled: asset.policy !== undefined,
      asset: {
        bucketPolicy: asset.policy ? JSON.parse(asset.policy) : undefined,
      },
      title: "Bucket Policy",
    }),
    descriptionSection({
      asset: asset.acl,
      title: "Bucket Access Control List",
    }),
    descriptionSection({
      asset: asset.logging,
      title: "Logging",
    }),
    descriptionSection({
      asset: asset.lifecycleConfiguration,
      title: "Lifecycle Configuration",
    }),
  ],
})
