import {
  GetProps,
  Input,
  Layout,
  Space,
  Table,
  TableColumnsType,
  theme,
  Typography,
} from "antd"
import _ from "lodash"
import React, { useContext, useState } from "react"
import { ClientStateContext } from "../../context/ClientsContext"

type SearchProps = GetProps<typeof Input.Search>

abstract class SearchTerm {
  abstract type: string
}

type Wildcard = "start" | "end" | "both" | "none"

class PrimarySearchTerm extends SearchTerm {
  type: "primary"
  value: string
  wildcard: Wildcard

  constructor(value: string, wildcard: Wildcard) {
    super()
    this.value = value
    this.wildcard = wildcard
  }
}

class TagSearchTerm extends SearchTerm {
  type: "tag"
  key: string
  value: string

  constructor(value: string, key: string) {
    super()
    this.value = value
    this.key = key
  }
}

class AccountSearchTerm extends SearchTerm {
  type: "account"
  value: string

  constructor(value: string) {
    super()
    this.value = value
  }
}

class RegionSearchTerm extends SearchTerm {
  type: "region"
  value: string

  constructor(value: string) {
    super()
    this.value = value
  }
}

class AssetTypeSearchTerm extends SearchTerm {
  type: "asset-type"
  value: string

  constructor(value: string) {
    super()
    this.value = value
  }
}

const tagTermRegex = /^tag:(.+)=(.+)$/
const accountTermRegex = /^account=(.+)$/
const regionTermRegex = /^region=(.+)$/
const assetTypeTermRegex = /^assetType=(.+)$/

const parseQueryString = (queryString: string): ReadonlyArray<SearchTerm> => {
  let rawTerms = queryString.trim().replace(/\s+/g, " ").split(" ")

  return rawTerms.map((term) => {
    const tagTermMatches = term.match(tagTermRegex)
    if (tagTermMatches && tagTermMatches.length === 3) {
      return new TagSearchTerm(tagTermMatches[2], tagTermMatches[1])
    }

    const accountTermMatches = term.match(accountTermRegex)
    if (accountTermMatches && accountTermMatches.length === 2) {
      return new AccountSearchTerm(accountTermMatches[1])
    }

    const regionTermMatches = term.match(regionTermRegex)
    if (regionTermMatches && regionTermMatches.length === 2) {
      return new RegionSearchTerm(regionTermMatches[1])
    }

    const assetTypeTermMatches = term.match(assetTypeTermRegex)
    if (assetTypeTermMatches && assetTypeTermMatches.length === 2) {
      return new AssetTypeSearchTerm(assetTypeTermMatches[1])
    }

    const wildcardStart = term.startsWith("*")
    const wildcardEnd = term.endsWith("*")

    let wildcard: Wildcard = "none"
    let value = term
    if (wildcardStart && wildcardEnd) {
      wildcard = "both"
      value = term.slice(1, -1)
    } else if (wildcardStart) {
      wildcard = "start"
      value = term.slice(1)
    } else if (wildcardEnd) {
      wildcard = "end"
      value = term.slice(0, -1)
    }

    return new PrimarySearchTerm(value, wildcard)
  })
}

type SearchResult = {
  displayName: string
  id: string
  assetType: String
  region: string
  accountId: string
}

export const AwsAssetsContent: React.FC = (props) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  const clientState = useContext(ClientStateContext)
  const [searchResults, setSearchResults] = useState<
    ReadonlyArray<SearchResult>
  >([])

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    console.log(info?.source, value)

    if (
      !clientState.assetsIndexData ||
      !clientState.assetsIndexPrimary ||
      !clientState.assetsIndexTags ||
      !clientState.assetsIndexAccounts ||
      !clientState.assetsIndexRegions ||
      !clientState.assetsIndexAssetTypes
    ) {
      console.log("Search index not yet loaded")
      return
    }

    const terms = parseQueryString(value)

    if (terms.length === 0) {
      setSearchResults([])
      return
    }

    const allResults = new Array<number[]>()

    terms.forEach((searchTerm, termIndex) => {
      let termResults = new Array<number>()
      if (searchTerm instanceof PrimarySearchTerm) {
        // Is wildcard search performant enough?
        if (searchTerm.value.length > 3 && searchTerm.wildcard !== "none") {
          Object.entries(clientState.assetsIndexPrimary.terms).forEach(
            ([term, assets]) => {
              if (
                searchTerm.wildcard === "both" &&
                term.includes(searchTerm.value)
              ) {
                termResults.push(...assets)
              } else if (
                searchTerm.wildcard === "start" &&
                term.endsWith(searchTerm.value)
              ) {
                termResults.push(...assets)
              } else if (
                searchTerm.wildcard === "end" &&
                term.startsWith(searchTerm.value)
              ) {
                termResults.push(...assets)
              }
            },
          )
        } else {
          const assets = clientState.assetsIndexPrimary.terms[searchTerm.value]
          if (assets) {
            termResults = assets
          }
        }
      } else if (searchTerm instanceof TagSearchTerm) {
        const assets =
          clientState.assetsIndexTags.keys[searchTerm.key]?.[searchTerm.value]
        if (assets) {
          termResults = assets
        }
      } else if (searchTerm instanceof AccountSearchTerm) {
        const assets = clientState.assetsIndexAccounts.terms[searchTerm.value]
        if (assets) {
          termResults = assets
        }
      } else if (searchTerm instanceof RegionSearchTerm) {
        const assets = clientState.assetsIndexRegions.terms[searchTerm.value]
        if (assets) {
          termResults = assets
        }
      } else if (searchTerm instanceof AssetTypeSearchTerm) {
        const assets = clientState.assetsIndexAssetTypes.terms[searchTerm.value]
        if (assets) {
          termResults = assets
        }
      } else {
        throw new Error(`Unknown search term type: ${searchTerm.type}`)
      }

      const uniqueTermResults = _.uniq(termResults)
      allResults.push(uniqueTermResults)
    })

    const finalResults = _.intersection(...allResults)

    const results = finalResults.map(
      (i) => clientState.assetsIndexData.assets[i],
    )

    const resultsByType = _.groupBy(results, (r) => r.split(" ")[2])

    const options: ReadonlyArray<SearchResult> = Array.from(
      Object.entries(resultsByType),
    )
      .map(([assetTypeNumber, value]) => {
        const assetType =
          clientState.assetsIndexData.types[Number(assetTypeNumber)]

        // `${AccountNumber} ${RegionNumber} ${AssetTypeNumber} ${AssetId} ${AssetDisplayName}`
        return value.map((asset) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [accountNumber, regionNumber, type, id, displayName] =
            asset.split(" ", 5)

          const region =
            clientState.assetsIndexData.regions[Number(regionNumber)]

          const accountId =
            clientState.assetsIndexData.accounts[Number(accountNumber)]

          return {
            id,
            assetType,
            displayName,
            region,
            accountId,
          }
        })
      })
      .flat()

    setSearchResults(options)
  }

  if (clientState.status !== "ready") {
    return <div></div>
  }

  const columns: TableColumnsType<SearchResult> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Display Name",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "Asset Type",
      dataIndex: "assetType",
      key: "assetType",
    },
    {
      title: "Account ID",
      dataIndex: "accountId",
      key: "accountId",
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
    },
  ]

  return (
    <Layout>
      <Layout.Content
        style={{
          padding: "10px",
          background: colorBgContainer,
        }}
      >
        <Space direction="vertical">
          <Typography.Text type="secondary">
            Search assets by their identifiers such as id and name. Leading and
            trailing wildcards * are supported when the query string has at
            least 3 characters. Give multiple search terms by separating them
            with a space. If multiple terms are given, all of them must match.
            Type tag:tagname=value to search by tag name and value. You can also
            search by account, region, and asset type by typing account=value,
            region=value, and assetType=value respectively.
          </Typography.Text>
          <Input.Search placeholder="Search for assets" onSearch={onSearch} />
        </Space>
      </Layout.Content>
      <Layout.Content
        style={{
          padding: "10px",
          background: colorBgContainer,
          marginTop: "10px",
        }}
      >
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={searchResults}
          scroll={{
            y: 600,
            x: "max-content",
            scrollToFirstRowOnChange: true,
          }}
        />
      </Layout.Content>
    </Layout>
  )
}
