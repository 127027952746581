import {
  Button,
  Col,
  Layout,
  Menu,
  MenuProps,
  Row,
  Spin,
  theme,
  Typography,
} from "antd"
import dt from "date-and-time"
import md5 from "md5"
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { ClientDto } from "../../../common/model/api"
import { Api } from "../api"
import { ApiContext } from "../ApiContext"
import {
  ClientState,
  ClientStateContext,
  clientStateReducer,
  SelectedClientContext,
  SelectedClientLoadingState,
  SelectedClientReadyState,
  SelectedClientState,
  useClientsState,
} from "../context/ClientsContext"

const { Title, Text } = Typography

const startSyncJob = async (
  api: Api,
  selectedClient: ClientDto,
  setSyncJobRequested: (status: boolean) => void,
): Promise<void> => {
  setSyncJobRequested(true)
  api
    .startSyncJob(selectedClient.id)
    .then((r) => {
      setSyncJobRequested(false)
    })
    .catch((error) => {
      console.log(error)
      setSyncJobRequested(false)
    })
}

const createInitialClientState = (): ClientState => ({
  status: "loading",
})

export const ClientContent: React.FC = () => {
  const { clientId } = useParams()
  const api = useContext(ApiContext)
  const navigate = useNavigate()
  const location = useLocation()
  const clientsState = useClientsState()
  const [syncJobRequested, setSyncJobRequested] = useState<boolean>(false)

  const currentDependencies = useRef("")

  const [selectedClient, setSelectedClient] = useState<SelectedClientState>(
    new SelectedClientLoadingState(),
  )

  const [clientState, dispatchClientState] = useReducer(
    clientStateReducer,
    undefined,
    createInitialClientState,
  )

  const {
    token: { colorBgContainer },
  } = theme.useToken()

  useEffect(() => {
    const client = clientsState.clients.find((client) => client.id === clientId)
    if (!client) {
      return
    }

    setSelectedClient(new SelectedClientReadyState(client))
    dispatchClientState({ action: "ready", client })
  }, [clientId, clientsState.clients])

  useEffect(() => {
    if (clientState.status !== "ready") {
      return
    }

    const { id, syncJobs } = clientState.client

    if (syncJobs.length === 0) {
      return
    }

    const dependencies = md5(JSON.stringify({ id, syncJobs }))

    // TODO: Is there a better way to prevent this from executing on every render?
    if (currentDependencies.current === dependencies) {
      return
    }

    currentDependencies.current = dependencies

    api
      .getAwsSearchPrimaryIndex({ clientId: id, syncJob: syncJobs[0] })
      .then((index) => {
        dispatchClientState({
          action: "asset-index-primary-ready",
          index,
        })
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error)
      })

    api
      .getAwsSearchTagsIndex({ clientId: id, syncJob: syncJobs[0] })
      .then((index) => {
        dispatchClientState({
          action: "asset-index-tags-ready",
          index,
        })
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error)
      })

    api
      .getAwsSearchAccountsIndex({ clientId: id, syncJob: syncJobs[0] })
      .then((index) => {
        dispatchClientState({
          action: "asset-index-accounts-ready",
          index,
        })
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error)
      })

    api
      .getAwsSearchRegionsIndex({ clientId: id, syncJob: syncJobs[0] })
      .then((index) => {
        dispatchClientState({
          action: "asset-index-regions-ready",
          index,
        })
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error)
      })

    api
      .getAwsSearchAssetTypesIndex({ clientId: id, syncJob: syncJobs[0] })
      .then((index) => {
        dispatchClientState({
          action: "asset-index-asset-types-ready",
          index,
        })
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error)
      })

    api
      .getAwsSearchAssetsData({ clientId: id, syncJob: syncJobs[0] })
      .then((data) => {
        dispatchClientState({
          action: "asset-index-data-ready",
          data,
        })
      })
      .catch((error) => {
        // TODO: Handle error
        console.log(error)
      })
  }, [clientState, api])

  if (clientState.status === "loading") {
    return (
      <div style={{ paddingTop: "20px" }}>
        <Spin delay={500} />
      </div>
    )
  }

  const client = clientState.client

  const productItems: MenuProps["items"] = [
    {
      key: "aws",
      label: "AWS Assets",
      onClick: () => navigate(`/app/clients/${client.id}/aws`),
    },
    {
      key: "aws-assets",
      label: "AWS Assets V2",
      onClick: () => navigate(`/app/clients/${client.id}/aws-assets`),
    },
    {
      key: "aws-audit",
      label: "AWS Audit",
      disabled: false,
      onClick: () => navigate(`/app/clients/${client.id}/aws-audit`),
    },
  ]

  const selectedProductItemKey = productItems
    .map((item) => String(item.key))
    .sort((a, b) => b.length - a.length)
    .find((key) =>
      location.pathname.startsWith(`/app/clients/${client.id}/${key}`),
    )

  return (
    <ClientStateContext.Provider value={clientState}>
      <SelectedClientContext.Provider value={selectedClient}>
        <Row>
          <Col span={12}>
            <Title level={2}>{client.name}</Title>
          </Col>
          <Col span={12} style={{ textAlign: "right", paddingTop: "40px" }}>
            <Text type="secondary">
              {client.syncJobs
                ? "index updated: " +
                  dt.format(
                    new Date(client.syncJobs[0] * 1000),
                    "YYYY-MM-DD HH:mm",
                  ) +
                  " | "
                : ""}
            </Text>
            <Button
              type="link"
              style={{ paddingLeft: "0", paddingRight: "0" }}
              disabled={syncJobRequested}
              onClick={() => startSyncJob(api, client, setSyncJobRequested)}
            >
              update now
            </Button>
          </Col>
        </Row>

        <Menu
          mode="horizontal"
          defaultSelectedKeys={[selectedProductItemKey]}
          items={productItems}
        />
        <Layout
          style={{
            padding: "0",
            background: colorBgContainer,
            marginTop: "10px",
          }}
        >
          <Outlet />
        </Layout>
      </SelectedClientContext.Provider>
    </ClientStateContext.Provider>
  )
}
