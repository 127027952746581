import { StateMachineAsset } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  simpleTableSection,
} from "../../asset-content"

export const awsStateMachines = assetContent<StateMachineAsset>({
  type: "step-functions/state-machine",
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["definition", "executions"],
      additional: [
        {
          label: "Definition",
          value: () => asset.definition,
          render: (definition: string) => <pre>{definition}</pre>,
        },
      ],
    }),
    simpleTableSection({
      asset,
      property: "executions",
    }),
  ],
  list: {
    exclude: ["definition"],
    filtered: ["status", "type"],
  },
})
