import {
  CreateAssetGroupRuleRequest,
  UpdateAssetGroupRuleRequest,
} from "../../../common/model/api"
import { AssetGroupRule } from "../../../common/model/asset-group-rules"
import { HttpClient } from "./http-client"
import { extractData } from "./response-mappers"

const BASE_PATH = "/api/asset-group-rules"

export interface AssetGroupRulesApi {
  listAssetGroupRules: (
    clientId?: string,
  ) => Promise<ReadonlyArray<AssetGroupRule>>
  deleteAssetGroupRule: (clientId: string, ruleId: string) => Promise<void>
  updateAssetGroupRule: (
    clientId: string,
    ruleId: string,
    request: UpdateAssetGroupRuleRequest,
  ) => Promise<void>
  createAssetGroupRule: (request: CreateAssetGroupRuleRequest) => Promise<void>
}

export const assetGroupRulesApiUsing = ({
  get,
  put,
  post,
  del,
}: HttpClient): AssetGroupRulesApi => ({
  listAssetGroupRules: (clientId) =>
    get(BASE_PATH, { clientId }).then(extractData),
  deleteAssetGroupRule: (clientId, ruleId) =>
    del(`${BASE_PATH}/${clientId}/${ruleId}`),
  updateAssetGroupRule: (clientId, ruleId, request) =>
    put(`${BASE_PATH}/${clientId}/${ruleId}`, request),
  createAssetGroupRule: (request) => post(BASE_PATH, request),
})
