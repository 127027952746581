import { Button, Table } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { ProductConfig } from "../../../../common/model/asset-data-sources"
import { ApiContext } from "../../ApiContext"

const columns = [
  {
    title: "Action",
    key: "action",
    render: (text, record) => <Link to={`${record.id}/basic-info`}>View</Link>,
  },
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Client Id",
    dataIndex: "clientId",
    key: "clientId",
  },
]

export const AssetDataSourcesPage: React.FC = () => {
  const api = useContext(ApiContext)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [productConfigs, setProductConfigs] = useState<
    ReadonlyArray<ProductConfig>
  >([])

  useEffect(() => {
    setLoading(true)
    api
      .listAssetDataSources()
      .then((data) => {
        setProductConfigs(data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }, [api])

  return (
    <div>
      <h1>Asset Data Sources</h1>
      <Table
        rowKey={(record) => record.id}
        loading={loading}
        columns={columns}
        dataSource={productConfigs}
        style={{ paddingTop: "20px" }}
        pagination={{ hideOnSinglePage: true, pageSize: 20 }}
      />
      <div style={{ paddingTop: "20px" }}>
        <Button onClick={() => navigate("create")}>
          Create Asset Data Source
        </Button>
      </div>
    </div>
  )
}
