import { App, Descriptions, Table, Typography } from "antd"
import { NotificationInstance } from "antd/es/notification/interface"
import React, { useContext, useEffect, useState } from "react"
import { withErrorBoundary } from "react-error-boundary"
import { useParams } from "react-router-dom"
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts"
import { AwsAssetType } from "../../../common/aws/types"
import { DetailSection, assetContent } from "../content/asset-content"
import { awsAssetContents } from "../content/aws/aws-content"
import { AssetDetailsContext } from "../context/ClientsContext"
import { ErrorFallback } from "../errors/ErrorFallback"
const { Title, Text } = Typography

const defaultDetailContent = (
  type: AwsAssetType,
  asset: any,
  notification: NotificationInstance,
): ReadonlyArray<DetailSection> => {
  return assetContent({ type }).getContent({ notification }).details(asset)
}

const getDetails = (
  assetType: AwsAssetType,
  asset: any,
  notification: NotificationInstance,
): ReadonlyArray<DetailSection> => {
  const content = awsAssetContents.get(assetType)
  if (!content) {
    return defaultDetailContent(assetType, asset, notification)
  }

  const details = content.getContent({ notification }).details

  return details
    ? details(asset)
    : defaultDetailContent(assetType, asset, notification)
}

const AssetDetailsContentInternal: React.FC = () => {
  const { notification } = App.useApp()
  const assetDetailsState = useContext(AssetDetailsContext)
  const { assetFamily, assetType } = useParams()
  const [details, setDetails] = useState<ReadonlyArray<DetailSection>>([])

  useEffect(() => {
    setDetails(
      getDetails(
        (assetFamily + "/" + assetType) as AwsAssetType,
        assetDetailsState.asset,
        notification,
      ),
    )
  }, [assetFamily, assetType, assetDetailsState, notification])

  if (assetDetailsState.status === "loading") {
    return <div />
  }

  return (
    <div>
      {details.map((detail, i) => {
        switch (detail.type) {
          case "description":
            return (
              <Descriptions
                key={i}
                title={detail.title}
                items={detail.items}
                layout="horizontal"
                style={{ paddingTop: "20px" }}
                bordered
                column={1}
              />
            )
          case "bar-chart":
            return (
              <div key={i}>
                <Title level={5}>{detail.title}</Title>
                <Text>{detail.description}</Text>
                <ResponsiveContainer
                  height={300}
                  style={{ paddingTop: "20px" }}
                >
                  <BarChart data={detail.data} width={500} height={400}>
                    <CartesianGrid />
                    <Legend />
                    <XAxis dataKey={detail.xAxisKey} />
                    <YAxis padding={{ top: 20 }} />
                    <Bar
                      dataKey={detail.valueKey}
                      fill="#b3cbf2"
                      unit="$"
                      name={"Cost ($)"}
                    >
                      <LabelList
                        dataKey={detail.valueKey}
                        position="top"
                        formatter={(v: number) =>
                          v.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }
                      />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            )
          case "table":
            return (
              <div key={i}>
                <Title level={5}>{detail.title}</Title>
                <Table
                  rowKey={detail.rowKey}
                  columns={detail.columns}
                  dataSource={detail.data}
                  style={{ paddingTop: "20px" }}
                  scroll={{ x: true }}
                  pagination={{ hideOnSinglePage: true, pageSize: 20 }}
                />
              </div>
            )
          default:
            throw new Error("Unknown detail type")
        }
      })}
    </div>
  )
}

export const AssetDetailsContent = withErrorBoundary(
  AssetDetailsContentInternal,
  { FallbackComponent: ErrorFallback },
)
