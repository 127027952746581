import { LoadBalancerResource } from "../../../../../common/model/data/aws-data"
import { assetContent } from "../../asset-content"

export const awsElbLoadBalancers = assetContent<
  LoadBalancerResource,
  LoadBalancerResource
>({
  type: "elb/load-balancer",
  list: {
    filtered: ["VpcId", "Scheme", "Type", "IpAddressType"],
  },
})
