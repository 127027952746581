export const isKnownError = (error: unknown): error is BaseError =>
  typeof error === "object" && (error as BaseError).isKnownError

export abstract class BaseError extends Error {
  public readonly isKnownError = true
  public readonly type: string
  protected constructor(message: string, type: string) {
    super(message)
    this.type = type
  }
}
