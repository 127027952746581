import { Button, Form, Input } from "antd"
import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { CreateClientRequest } from "../../../common/model/api"
import { ApiContext } from "../ApiContext"

export const CreateClientPage: React.FC = () => {
  const api = useContext(ApiContext)
  const navigate = useNavigate()

  const onFinish = (request: CreateClientRequest) => {
    api.createClient(request).then((data) => {
      navigate(`../${data.id}`, { relative: "path" })
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }

  return (
    <div>
      <h1>Create client</h1>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ name: "" }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<CreateClientRequest>
          label="Name"
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
          <Button
            type="link"
            onClick={() => navigate("../", { relative: "path" })}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
