import _ from "lodash"
import { LambdaFunctionResource } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
  tableSection,
} from "../../asset-content"

export const awsLambdaFunctions = assetContent<LambdaFunctionResource>({
  type: "lambda/function",
  list: {
    exclude: [
      "Description",
      "Handler",
      "Version",
      "Role",
      "RevisionId",
      "CodeSha256",
    ],
    filtered: ["Runtime", "PackageType", "MemorySize"],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: [
        "Environment",
        "VpcConfig",
        "LoggingConfig",
        "SnapStart",
        "EphemeralStorage",
        "TracingConfig",
        "Layers",
      ],
      additional: [
        {
          label: "Ephemeral Storage Size",
          value: () => asset.EphemeralStorage.Size,
        },
        {
          label: "Tracing Config Mode",
          value: () => asset.TracingConfig.Mode,
        },
      ],
    }),
    descriptionSection({ title: "Logging Config", asset: asset.LoggingConfig }),
    descriptionSection({ title: "VPC Config", asset: asset.VpcConfig }),
    descriptionSection({ title: "Snap Start", asset: asset.SnapStart }),
    tableSection({
      asset,
      title: "Environment Variables",
      enabled: !_.isEmpty(asset.Environment?.Variables),
      columns: ["Name", "Value"],
      data: () =>
        Object.entries(asset.Environment?.Variables ?? {}).map((k) => ({
          Name: k[0],
          Value: k[1],
        })),
    }),
    tableSection({
      asset,
      title: "Layers",
      enabled: !_.isEmpty(asset.Layers),
      data: () => asset.Layers,
    }),
  ],
})
