import { Button, Table } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { UserDto } from "../../../common/model/api"
import { ApiContext } from "../ApiContext"

const columns = [
  {
    title: "Action",
    key: "action",
    render: (text, record) => <Link to={record.id}>View</Link>,
  },
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
  },
]

export const UsersPage: React.FC = () => {
  const api = useContext(ApiContext)
  const navigate = useNavigate()
  const [users, setUsers] = useState<ReadonlyArray<UserDto>>([])

  useEffect(() => {
    api
      .listUsers()
      .then((data) => {
        setUsers(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [api])

  return (
    <div>
      <h1>Users</h1>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={users}
        style={{ paddingTop: "20px" }}
        pagination={{ hideOnSinglePage: true, pageSize: 20 }}
      />
      <div style={{ paddingTop: "20px" }}>
        <Button onClick={() => navigate("create")}>Create user</Button>
      </div>
    </div>
  )
}
