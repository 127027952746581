import {
  StackSummaryAsset,
  StackSummaryListAsset,
} from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
  simpleTableSection,
} from "../../asset-content"

export const awsCloudformationStackContent = assetContent<
  StackSummaryListAsset,
  StackSummaryAsset
>({
  type: "cloudformation/stack",
  list: {
    filtered: ["StackStatus", "EnableTerminationProtection", "DisableRollback"],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["DriftInformation", "Parameters", "Outputs", "template"],
      additional: [
        {
          label: "Template",
          value: () => asset.template,
          render: (template: string) => <pre>{template}</pre>,
        },
      ],
    }),
    descriptionSection({
      asset: asset.DriftInformation,
      title: "Drift Information",
    }),
    simpleTableSection({ asset, property: "Parameters" }),
    simpleTableSection({ asset, property: "Outputs" }),
  ],
})
