import type { SelectProps } from "antd"
import { Select } from "antd"
import React from "react"

interface AwsSearchProps {
  placeholder: string
  options: SelectProps["options"]
  onChange: (values: string[]) => void
}

export const AwsSearch: React.FC<AwsSearchProps> = (props) => (
  <Select
    mode="tags"
    style={{ width: "100%" }}
    placeholder={props.placeholder}
    onChange={props.onChange}
    options={props.options}
    allowClear={true}
  />
)
