import _ from "lodash"
import { DbInstanceResource } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
  tableSection,
} from "../../asset-content"

export const awsRdsDbInstancesContent = assetContent<
  DbInstanceResource,
  DbInstanceResource
>({
  type: "rds/db-instance",
  list: {
    exclude: ["MasterUsername"],
    filtered: [
      "DBInstanceClass",
      "Engine",
      "AvailabilityZone",
      "MultiAZ",
      "AutoMinorVersionUpgrade",
      "PubliclyAccessible",
      "StorageEncrypted",
      "StorageType",
      "CACertificateIdentifier",
      "CopyTagsToSnapshot",
      "DeletionProtection",
      "BackupTarget",
      "NetworkType",
      "SecondaryAvailabilityZone",
    ],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: [
        "CertificateDetails",
        "CACertificateIdentifier",
        "DBSecurityGroups",
        "VpcSecurityGroups",
        "Endpoint",
        "DBParameterGroups",
        "OptionGroupMemberships",
        "events",
        "DBSubnetGroup",
      ],
    }),
    descriptionSection({
      asset: asset.CertificateDetails,
      title: "Certificate Details",
    }),
    descriptionSection({
      asset: asset.Endpoint,
      title: "Endpoint",
    }),
    descriptionSection({
      asset: asset.DBSubnetGroup,
      title: "DB Subnet Group",
      exclude: ["Subnets"],
    }),
    tableSection({
      asset,
      title: "DB Subnet Group Subnets",
      enabled: !_.isEmpty(asset.DBSubnetGroup.Subnets),
      data: () =>
        asset.DBSubnetGroup.Subnets.map((s) => ({
          ...s,
          AvailabilityZone: s.SubnetAvailabilityZone.Name,
        })),
    }),
    tableSection({
      asset,
      title: "DB Parameter Groups",
      enabled: !_.isEmpty(asset.DBParameterGroups),
      data: () => asset.DBParameterGroups!,
    }),
    tableSection({
      asset,
      title: "Option Group Memberships",
      enabled: !_.isEmpty(asset.OptionGroupMemberships),
      data: () => asset.OptionGroupMemberships!,
    }),
    tableSection({
      asset,
      title: "Vpc Security Groups",
      enabled: !_.isEmpty(asset.VpcSecurityGroups),
      data: () => asset.VpcSecurityGroups!,
    }),
    tableSection({
      asset,
      title: "DB Security Groups",
      enabled: !_.isEmpty(asset.DBSecurityGroups),
      data: () => asset.DBSecurityGroups!,
    }),
    tableSection({
      asset,
      title: "Events",
      enabled: !_.isEmpty(asset.events),
      data: () => asset.events,
      columns: ["Date", "Message"],
    }),
  ],
})
