import { GuardDutyDetectorAsset } from "../../../../../common/model/data/aws-data"
import { assetContent } from "../../asset-content"

export const awsGuardDutyDetectors = assetContent<
  GuardDutyDetectorAsset,
  GuardDutyDetectorAsset
>({
  type: "guardduty/detector",
  list: {
    filtered: ["Status", "FindingPublishingFrequency"],
  },
})
