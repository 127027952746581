import md5 from "md5"
import * as React from "react"
import { Link } from "react-router-dom"
import { AwsResource } from "../../../common/model/common"
import { IamUserResource } from "../../../common/model/data/aws-data"

export const isVolumeId = (value: string): boolean =>
  /^vol-[a-z0-9]+$/.test(value)
export const isImageId = (value: string): boolean =>
  /^ami-[a-z0-9]+$/.test(value)
export const isSubnetId = (value: string): boolean =>
  /^subnet-[a-z0-9]+$/.test(value)
export const isSubnetArn = (value: string): boolean =>
  /^arn:aws:ec2:.+:[0-9]{12}:subnet\/subnet-[a-z0-9]+$/.test(value)
export const isVpcId = (value: string): boolean => /^vpc-[a-z0-9]+$/.test(value)
export const isInstanceId = (value: string): boolean =>
  /^i-[a-z0-9]+$/.test(value)
export const isSecurityGroupId = (value: string): boolean =>
  /^sg-[a-z0-9]+$/.test(value)
export const isNetworkInterfaceId = (value: string): boolean =>
  /^eni-[a-z0-9]+$/.test(value)
export const isRouteTableId = (value: string): boolean =>
  /^rtb-[a-z0-9]+$/.test(value)
export const isVpcEndpointId = (value: string): boolean =>
  /^vpce-[a-z0-9]+$/.test(value)
export const isSnapshotId = (value: string): boolean =>
  /^snap-[a-z0-9]+$/.test(value)
export const isVpcPeeringConnectionId = (value: string): boolean =>
  /^pcx-[a-z0-9]+$/.test(value)
export const isRoleArn = (value: string): boolean =>
  /^arn:aws:iam::[0-9]{12}:role\/.*$/.test(value)
export const isFunctionArn = (value: string): boolean =>
  /^arn:aws:lambda:.+:[0-9]{12}:function:.*$/.test(value)
export const isQueueArn = (value: string): boolean =>
  /^arn:aws:sqs:.+:[0-9]{12}:.*$/.test(value)

export const resolveLinkToAsset = (
  value: unknown,
  relativePath: number,
): React.ReactNode => {
  if (typeof value !== "string") {
    return undefined
  }

  const createLink = (type: string): React.ReactNode => (
    <Link
      to={`${"../".repeat(relativePath)}${type}/${md5(value)}`}
      relative="path"
    >
      {value}
    </Link>
  )

  if (isImageId(value)) {
    return createLink("ec2/image")
  }
  if (isSubnetId(value)) {
    return createLink("vpc/subnet")
  }
  if (isVpcId(value)) {
    return createLink("vpc/vpc")
  }
  if (isInstanceId(value)) {
    return createLink("ec2/instance")
  }
  if (isSecurityGroupId(value)) {
    return createLink("vpc/security-group")
  }
  if (isNetworkInterfaceId(value)) {
    return createLink("ec2/network-interface")
  }
  if (isVolumeId(value)) {
    return createLink("ec2/volume")
  }
  if (isRouteTableId(value)) {
    return createLink("vpc/route-table")
  }
  if (isVpcEndpointId(value)) {
    return createLink("vpc/vpc-endpoint")
  }
  if (isSnapshotId(value)) {
    return createLink("ec2/snapshot")
  }
  if (isVpcPeeringConnectionId(value)) {
    return createLink("vpc/vpc-peering-connection")
  }
  if (isRoleArn(value)) {
    return createLink("iam/role")
  }
  if (isFunctionArn(value)) {
    return createLink("lambda/function")
  }
  if (isQueueArn(value)) {
    return createLink("sqs/queue")
  }

  return undefined
}

export const linkToAwsConsole = <A extends AwsResource>(
  asset: A,
): string | undefined => {
  if (asset._meta.type === "iam/user") {
    const a = asset as unknown as IamUserResource
    return `https://us-east-1.console.aws.amazon.com/iam/home?region=us-east-1#/users/details/${a.UserName}?section=permissions`
  }

  return undefined
}
