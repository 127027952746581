import { BaseError, isKnownError } from "./base-error"

export enum API_ERRORS {
  GENERAL = "API_ERROR",
  SERVER = "API_ERROR_SERVER",
  UNAUTHORIZED = "API_ERROR_UNAUTHORIZED",
  TOKEN_EXPIRED = "API_ERROR_TOKEN_EXPIRED",
}

export const isUnauthorizedError = (
  error: unknown,
): error is UnauthorizedError =>
  isKnownError(error) && error.type === API_ERRORS.UNAUTHORIZED

export const isTokenExpiredError = (
  error: unknown,
): error is TokenExpiredError =>
  isKnownError(error) && error.type === API_ERRORS.TOKEN_EXPIRED

interface ApiErrorData {
  statusCode: number
  statusText: string
  response: any
}
export class ApiError extends BaseError {
  public readonly errorData: ApiErrorData
  constructor(
    message: string,
    errorData: ApiErrorData,
    errorType = API_ERRORS.GENERAL,
  ) {
    super(message, errorType)
    this.errorData = errorData
  }
}
export class UnauthorizedError extends ApiError {
  constructor(errorData: ApiErrorData) {
    super("Not authorized", errorData, API_ERRORS.UNAUTHORIZED)
  }
}

export class TokenExpiredError extends ApiError {
  constructor(errorData: ApiErrorData) {
    super("Token expired", errorData, API_ERRORS.TOKEN_EXPIRED)
  }
}

export class ServerError extends ApiError {
  constructor(errorData: ApiErrorData) {
    super("Server error", errorData, API_ERRORS.SERVER)
  }
}
