import convertCase from "js-convert-case"

const acronyms = new Set([
  "ip",
  "kms",
  "az",
  "db",
  "ami",
  "ebs",
  "ena",
  "vpc",
  "dns",
  "http",
  "acl",
  "api",
  "rest",
  "nat",
  "vpn",
  "mfa",
  "ses",
  "sqs",
  "igw",
  "tgw",
  "gw",
  "id",
  "arn",
  "saml",
  "oidc",
  "uuid",
  "ttl",
])

const acronymWords = new Map<string, string>([
  ["arecord", "A Record"],
  ["aaaarecord", "AAAA Record"],
  ["dbi", "DB Instance"],
  ["dbname", "DB Name"],
  ["dbsubnet", "DB Subnet"],
  ["dbinstance", "DB Instance"],
  ["iamdatabase", "IAM Database"],
  ["caidentifier", "CA Identifier"],
  ["dbcluster", "DB Cluster"],
  ["dnsname", "DNS Name"],
  ["acmcertificate", "ACM Certificate"],
  ["sslsupport", "SSL Support"],
  ["appsync", "AppSync"],
  ["graphql", "GraphQL"],
])

const handleAcronymWord = (word: string): string => {
  const lowerCased = word.toLowerCase()
  return acronyms.has(lowerCased)
    ? word.toUpperCase()
    : acronymWords.get(lowerCased) ?? word
}

export const toDisplayString = (value: string): string =>
  convertCase.toHeaderCase(value).split(" ").map(handleAcronymWord).join(" ")
