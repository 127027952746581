import { Dispatch, Reducer, createContext } from "react"
import { ClientDto } from "../../../../common/model/api"

export interface AdminClientDetailsState {
  client?: ClientDto
  status: "loading" | "ready"
}

export const AdminClientDetailsContext =
  createContext<AdminClientDetailsState>(undefined)

export const AdminClientDetailsDispatchContext =
  createContext<Dispatch<AdminClientDetailsAction>>(undefined)

type AdminClientDetailsAction =
  | { action: "loading" }
  | { action: "ready"; client: ClientDto }

export type AdminClientDetailsReducer = Reducer<
  AdminClientDetailsState,
  AdminClientDetailsAction
>

export const adminClientDetailsReducer: AdminClientDetailsReducer = (
  prevState,
  action,
): AdminClientDetailsState => {
  switch (action.action) {
    case "loading":
      return {
        status: "loading",
      }
    case "ready":
      return {
        status: "ready",
        client: action.client,
      }
    default:
      throw new Error(`Unknown action ${action}`)
  }
}
