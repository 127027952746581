import { BackupVaultAsset } from "../../../../../common/model/data/aws-data"
import { assetContent, basicAssetDetails } from "../../asset-content"

export const awsBackupVaults = assetContent<BackupVaultAsset, BackupVaultAsset>(
  {
    type: "backup/vault",
    list: {
      exclude: ["CreatorRequestId"],
      filtered: ["Locked"],
    },
    details: (asset) => [
      basicAssetDetails({
        asset,
      }),
    ],
  },
)
