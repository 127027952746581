import _ from "lodash"
import { DbClusterAsset } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
  tableSection,
} from "../../asset-content"

export const awsRdsDbClustersContent = assetContent<
  DbClusterAsset,
  DbClusterAsset
>({
  type: "rds/db-cluster",
  list: {
    exclude: ["MasterUsername", "MasterUserSecret"],
    filtered: [
      "MultiAZ",
      "Status",
      "Engine",
      "EngineVersion",
      "EngineMode",
      "StorageType",
      "StorageEncrypted",
      "IAMDatabaseAuthenticationEnabled",
      "DeletionProtection",
      "HttpEndpointEnabled",
      "ActivityStreamStatus",
      "CopyTagsToSnapshot",
      "CrossAccountClone",
      "AutoMinorVersionUpgrade",
      "NetworkType",
    ],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: [
        "events",
        "VpcSecurityGroups",
        "ScalingConfigurationInfo",
        "DBClusterMembers",
      ],
    }),
    descriptionSection({
      asset: asset.ScalingConfigurationInfo,
      title: "Scaling Configuration Info",
    }),
    tableSection({
      asset,
      title: "Vpc Security Groups",
      enabled: !_.isEmpty(asset.VpcSecurityGroups),
      data: () => asset.VpcSecurityGroups!,
    }),
    tableSection({
      asset,
      title: "DB Cluster Members",
      enabled: !_.isEmpty(asset.DBClusterMembers),
      data: () => asset.DBClusterMembers,
    }),
    tableSection({
      asset,
      title: "Events",
      enabled: !_.isEmpty(asset.events),
      data: () => asset.events,
      columns: ["Date", "Message"],
    }),
  ],
})
