import { IdentityProviderAsset } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  tableSection,
} from "../../asset-content"

export const awsCognitoIdentityProviders = assetContent<
  IdentityProviderAsset,
  IdentityProviderAsset
>({
  type: "cognito/identity-provider",
  list: {
    filtered: ["ProviderType"],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["AttributeMapping", "ProviderDetails"],
    }),
    tableSection({
      asset: asset.AttributeMapping,
      title: "Attribute Mapping",
      data: () =>
        Object.entries(asset.AttributeMapping).map(([key, value]) => ({
          key,
          value,
        })),
    }),
    tableSection({
      asset: asset.ProviderDetails,
      title: "Provider Details",
      data: () =>
        Object.entries(asset.ProviderDetails).map(([key, value]) => ({
          key,
          value,
        })),
    }),
  ],
})
