import _ from "lodash"
import { VolumeResource } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  tableSection,
} from "../../asset-content"

export const awsEc2Volumes = assetContent<VolumeResource, VolumeResource>({
  type: "ec2/volume",
  list: {
    filtered: [
      "AvailabilityZone",
      "Encrypted",
      "State",
      "VolumeType",
      "MultiAttachEnabled",
    ],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["Attachments", "Size"],
      additional: [{ label: "Size", value: () => `${asset.Size} GiB` }],
    }),
    tableSection({
      asset,
      title: "Attachments",
      enabled: !_.isEmpty(asset.Attachments),
      data: () => asset.Attachments!,
    }),
  ],
})
