import _ from "lodash"
import { NatGatewayResource } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  tableSection,
} from "../../asset-content"

export const awsEc2NatGateways = assetContent<
  NatGatewayResource,
  NatGatewayResource
>({
  type: "ec2/nat-gateway",
  list: { filtered: ["State", "SubnetId", "VpcId", "ConnectivityType"] },
  details: (asset) => [
    basicAssetDetails({ asset, exclude: ["NatGatewayAddresses"] }),
    tableSection({
      asset,
      title: "Nat Gateway Addresses",
      enabled: !_.isEmpty(asset.NatGatewayAddresses),
      data: () => asset.NatGatewayAddresses!,
    }),
  ],
})
