import React, { useContext } from "react"
import { Navigate } from "react-router-dom"
import { SelectedClientContext } from "../context/ClientsContext"

export const ProductSelectorContent: React.FC = () => {
  const selectedClient = useContext(SelectedClientContext)

  if (!selectedClient) {
    return <div></div>
  }

  return <Navigate to="aws" replace={true} />
}
