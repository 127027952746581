import type { TabsProps } from "antd"
import { Descriptions, DescriptionsProps, Table, Tabs } from "antd"
import dt from "date-and-time"
import prettyBytes from "pretty-bytes"
import prettyMilliseconds from "pretty-ms"
import React, { useContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { SyncJobDto } from "../../../common/model/api"
import { ApiContext } from "../ApiContext"

const getTabs = (data: SyncJobDto): TabsProps["items"] => {
  const dataTasks = data.collectData
    .map((p) =>
      Array.from(Object.entries(p.tasks)).map(([id, task]) => {
        const [orgId, accountId, region, product, type] = id.split("/")
        return {
          id,
          orgId,
          accountId,
          region,
          product,
          type,
          ...(task as any),
        }
      }),
    )
    .flat()

  const aggregateDataTasks = data.aggregateData
    .map((p) =>
      Array.from(Object.entries(p.tasks)).map(([id, task]) => {
        const [orgId, accountId, region, product, type] = id.split("/")
        return {
          id,
          orgId,
          accountId,
          region,
          product,
          type,
          ...(task as any),
        }
      }),
    )
    .flat()

  const auditDataTasks = data.auditData
    .map((p) =>
      Array.from(Object.entries(p.tasks)).map(([id, task]) => {
        const [orgId, accountId, region, product, type] = id.split("/")
        return {
          id,
          orgId,
          accountId,
          region,
          product,
          type,
          ...(task as any),
        }
      }),
    )
    .flat()

  const contentTasks = data.buildContent
    .map((p) =>
      Array.from(Object.entries(p.tasks)).map(([id, task]) => {
        return {
          id,
          ...(task as any),
        }
      }),
    )
    .flat()

  return [
    {
      key: "1",
      label: "Collect Data",
      children: (
        <div>
          <h1>Collect data tasks ({dataTasks.length})</h1>
          <Table
            rowKey={(record) => record.id}
            columns={dataColumns}
            dataSource={dataTasks}
            style={{ paddingTop: "20px" }}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Aggregate Data",
      children: (
        <div>
          <h1>Aggregate data tasks ({aggregateDataTasks.length})</h1>
          <Table
            rowKey={(record) => record.id}
            columns={aggregateDataColumns}
            dataSource={aggregateDataTasks}
            style={{ paddingTop: "20px" }}
          />
        </div>
      ),
    },
    {
      key: "3",
      label: "Audit Data",
      children: (
        <div>
          <h1>Audit data tasks ({auditDataTasks.length})</h1>
          <Table
            rowKey={(record) => record.id}
            columns={auditDataColumns}
            dataSource={auditDataTasks}
            style={{ paddingTop: "20px" }}
          />
        </div>
      ),
    },
    {
      key: "4",
      label: "Build Content",
      children: (
        <div>
          <h1>Build content tasks ({contentTasks.length})</h1>
          <Table
            rowKey={(record) => record.id}
            columns={contentColumns}
            dataSource={contentTasks}
            style={{ paddingTop: "20px" }}
          />
        </div>
      ),
    },
  ]
}

const getBasicDescriptions = (
  syncJob: SyncJobDto["syncJob"],
): DescriptionsProps["items"] => {
  return [
    {
      key: "1",
      label: "Client id",
      children: syncJob.clientId,
    },
    {
      key: "2",
      label: "Timestamp",
      children: syncJob.timestamp,
    },
    {
      key: "3",
      label: "Start Time",
      children: dt.format(
        new Date(syncJob.startTime * 1000),
        "YYYY-MM-DD HH:mm",
      ),
    },
    {
      key: "4",
      label: "End Time",
      children: syncJob.endTime
        ? dt.format(new Date(syncJob.endTime * 1000), "YYYY-MM-DD HH:mm")
        : "-",
    },
    {
      key: "5",
      label: "Elapsed Time",
      children: syncJob.endTime
        ? prettyMilliseconds((syncJob.endTime - syncJob.startTime) * 1000)
        : "-",
    },
    {
      key: "6",
      label: "Collect Data partitions",
      children: syncJob.collectDataPartitions,
    },
    {
      key: "7",
      label: "Aggregate Data partitions",
      children: syncJob.aggregateDataPartitions,
    },
    {
      key: "8",
      label: "Audit Data partitions",
      children: syncJob.auditDataPartitions,
    },
    {
      key: "9",
      label: "Build Content partitions",
      children: syncJob.buildContentPartitions,
    },
    {
      key: "10",
      label: "Status",
      children: syncJob.status,
    },
    {
      key: "11",
      label: "Reason",
      children: syncJob.reason,
    },
  ]
}

const aggregateDataColumns = [
  {
    title: "Organization id",
    dataIndex: "orgId",
    key: "orgId",
    sorter: (a, b) => a.orgId.localeCompare(b.orgId),
  },
  {
    title: "Account Id",
    dataIndex: "accountId",
    key: "accountId",
    sorter: (a, b) => a.accountId.localeCompare(b.accountId),
  },
  {
    title: "Region",
    dataIndex: "region",
    key: "region",
    sorter: (a, b) => a.region.localeCompare(b.region),
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    sorter: (a, b) => a.product.localeCompare(b.product),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: (a, b) => a.status.localeCompare(b.status),
    onFilter: (value: string, record) => record.status === value,
    filters: [
      {
        text: "pending",
        value: "pending",
      },
      {
        text: "success",
        value: "success",
      },
      {
        text: "skip",
        value: "skip",
      },
      {
        text: "failed",
        value: "failed",
      },
    ],
  },
  {
    title: "Size",
    dataIndex: "size",
    key: "size",
    sorter: (a, b) => {
      if (!a.size && !b.size) {
        return 0
      }

      if (!a.size) {
        return 1
      }

      if (!b.size) {
        return -1
      }

      return a.size - b.size
    },
    render: (text) => (text ? prettyBytes(text) : "-"),
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
    sorter: (a, b) => a.time - b.time,
  },
]

const auditDataColumns = [
  {
    title: "Organization id",
    dataIndex: "orgId",
    key: "orgId",
    sorter: (a, b) => a.orgId.localeCompare(b.orgId),
  },
  {
    title: "Account Id",
    dataIndex: "accountId",
    key: "accountId",
    sorter: (a, b) => a.accountId.localeCompare(b.accountId),
  },
  {
    title: "Region",
    dataIndex: "region",
    key: "region",
    sorter: (a, b) => a.region.localeCompare(b.region),
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    sorter: (a, b) => a.product.localeCompare(b.product),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: (a, b) => a.status.localeCompare(b.status),
    onFilter: (value: string, record) => record.status === value,
    filters: [
      {
        text: "pending",
        value: "pending",
      },
      {
        text: "success",
        value: "success",
      },
      {
        text: "skip",
        value: "skip",
      },
      {
        text: "failed",
        value: "failed",
      },
    ],
  },
  {
    title: "Size",
    dataIndex: "size",
    key: "size",
    sorter: (a, b) => {
      if (!a.size && !b.size) {
        return 0
      }

      if (!a.size) {
        return 1
      }

      if (!b.size) {
        return -1
      }

      return a.size - b.size
    },
    render: (text) => (text ? prettyBytes(text) : "-"),
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
    sorter: (a, b) => a.time - b.time,
  },
]

const dataColumns = [
  {
    title: "Organization id",
    dataIndex: "orgId",
    key: "orgId",
    sorter: (a, b) => a.orgId.localeCompare(b.orgId),
  },
  {
    title: "Account Id",
    dataIndex: "accountId",
    key: "accountId",
    sorter: (a, b) => a.accountId.localeCompare(b.accountId),
  },
  {
    title: "Region",
    dataIndex: "region",
    key: "region",
    sorter: (a, b) => a.region.localeCompare(b.region),
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    sorter: (a, b) => a.product.localeCompare(b.product),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: (a, b) => a.status.localeCompare(b.status),
    onFilter: (value: string, record) => record.status === value,
    filters: [
      {
        text: "pending",
        value: "pending",
      },
      {
        text: "success",
        value: "success",
      },
      {
        text: "skip",
        value: "skip",
      },
      {
        text: "failed",
        value: "failed",
      },
    ],
  },
  {
    title: "Size",
    dataIndex: "size",
    key: "size",
    sorter: (a, b) => {
      if (!a.size && !b.size) {
        return 0
      }

      if (!a.size) {
        return 1
      }

      if (!b.size) {
        return -1
      }

      return a.size - b.size
    },
    render: (text) => (text ? prettyBytes(text) : "-"),
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
    sorter: (a, b) => a.time - b.time,
  },
]

const contentColumns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
    sorter: (a, b) => a.id.localeCompare(b.id),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: (a, b) => a.status.localeCompare(b.status),
    onFilter: (value: string, record) => record.status === value,
    filters: [
      {
        text: "pending",
        value: "pending",
      },
      {
        text: "success",
        value: "success",
      },
      {
        text: "skip",
        value: "skip",
      },
      {
        text: "failed",
        value: "failed",
      },
    ],
  },
  {
    title: "Size",
    dataIndex: "size",
    key: "size",
    sorter: (a, b) => a.time - b.time,
    render: (text) => prettyBytes(text),
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
    sorter: (a, b) => a.time - b.time,
  },
]

export const SyncJobPage: React.FC = () => {
  const api = useContext(ApiContext)
  const { clientId, timestamp } = useParams()
  const [basicDescriptions, setBasicDescriptions] = useState([])
  const [tabs, setTabs] = useState([])

  useEffect(() => {
    api
      .getSyncJob(clientId, timestamp)
      .then((data) => {
        setBasicDescriptions(getBasicDescriptions(data.syncJob))
        setTabs(getTabs(data))
      })
      .catch((error) => {
        console.log(error)
      })
  }, [clientId, timestamp, api])

  return (
    <div>
      <Link to="/app/admin/sync-jobs">Back to sync jobs listing</Link>
      <h1>Sync job</h1>
      <Descriptions title="Basic" items={basicDescriptions} />

      <Tabs defaultActiveKey="1" items={tabs} />
    </div>
  )
}
