import { VpcResource } from "../../../../../common/model/data/aws-data"
import { assetContent } from "../../asset-content"

export const awsVpcVpcsContent = assetContent<VpcResource>({
  type: "vpc/vpc",
  list: {
    exclude: ["State"],
    filtered: [
      "IsDefault",
      "InstanceTenancy",
      "hasRouteToIgwViaNatGw",
      "hasRouteToIgw",
      "hasRouteToTgw",
    ],
  },
})
