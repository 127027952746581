import React from "react"
import { Link } from "react-router-dom"

export const AdminPage: React.FC = () => {
  return (
    <div>
      <h1>Admin</h1>
      <ul>
        <li>
          <Link to="/app/admin/sync-jobs">Sync jobs</Link>
        </li>
        <li>
          <Link to="/app/admin/users">Users</Link>
        </li>
        <li>
          <Link to="/app/admin/clients">Clients</Link>
        </li>
        <li>
          <Link to="/app/admin/billing-configs">Billing Configs</Link>
        </li>
        <li>
          <Link to="/app/admin/asset-data-sources">Asset Data Sources</Link>
        </li>
        <li>
          <Link to="/app/admin/asset-group-rules">Asset Group Rules</Link>
        </li>
      </ul>
    </div>
  )
}
