const prodConfig = (isLocal: boolean) => ({
  Auth: {
    region: "eu-north-1",
    identityPoolId: "eu-north-1:2e10c1f1-f978-4556-8f5e-27cce535362b",
    userPoolId: "eu-north-1_MkAEtD2Eb",
    userPoolWebClientId: "4pin4hsjj0hk5nluvbl2io04r6",
    mandatorySignIn: true,
    oauth: {
      domain: "metamesh-prod.auth.eu-north-1.amazoncognito.com",
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: isLocal
        ? "http://localhost:3000/"
        : "https://metamesh.webscale.fi/",
      redirectSignOut: isLocal
        ? "http://localhost:3000/"
        : "https://metamesh.webscale.fi/",
      clientId: "4pin4hsjj0hk5nluvbl2io04r6",
      responseType: "code",
    },
  },
})

const devConfig = (isLocal: boolean) => ({
  Auth: {
    region: "eu-north-1",
    identityPoolId: "eu-north-1:96d62449-763b-4073-8e68-10cdadda305b",
    userPoolId: "eu-north-1_RkMfcoJA9",
    userPoolWebClientId: "4bddk0kkkfem1o6k9pj747d16f",
    mandatorySignIn: true,
    oauth: {
      domain: "metamesh-dev.auth.eu-north-1.amazoncognito.com",
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: isLocal
        ? "http://localhost:3000/"
        : "https://metamesh-dev.webscale.fi/",
      redirectSignOut: isLocal
        ? "http://localhost:3000/"
        : "https://metamesh-dev.webscale.fi/",
      clientId: "4bddk0kkkfem1o6k9pj747d16f",
      responseType: "code",
    },
  },
})

export const getConfig = (hostname: string): Record<string, any> => {
  switch (hostname) {
    case "localhost":
      return process.env.REACT_APP_ENV === "prod"
        ? prodConfig(true)
        : devConfig(true)
    case "metamesh-dev.webscale.fi":
      return devConfig(false)
    case "metamesh.webscale.fi":
      return prodConfig(false)
    default:
      throw new Error(`Unsupported hostname: ${hostname}`)
  }
}
