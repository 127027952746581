import _ from "lodash"
import { DistributionSummaryAsset } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
  tableSection,
} from "../../asset-content"

export const awsCloudfrontDistributionContent = assetContent<
  DistributionSummaryAsset,
  DistributionSummaryAsset
>({
  type: "cloudfront/distribution",
  list: {
    exclude: ["Comment"],
    filtered: [
      "PriceClass",
      "Enabled",
      "HttpVersion",
      "IsIPV6Enabled",
      "Staging",
      "Status",
    ],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: [
        "ViewerCertificate",
        "Aliases",
        "Origins",
        "Restrictions",
        "DefaultCacheBehavior",
        "AliasICPRecordals",
      ],
      additional: [{ label: "Aliases", value: () => asset.Aliases.Items }],
    }),
    descriptionSection({
      title: "Viewer Certificate",
      asset: asset.ViewerCertificate,
    }),
    descriptionSection({
      title: "Origins",
      asset: asset.Origins,
    }),
    descriptionSection({
      title: "DefaultCacheBehavior",
      asset: asset.DefaultCacheBehavior,
    }),
    descriptionSection({
      title: "Restrictions",
      asset: asset.Restrictions,
    }),
    tableSection({
      asset,
      title: "Alias ICP Recordals",
      enabled: !_.isEmpty(asset.AliasICPRecordals),
      data: () => asset.AliasICPRecordals,
    }),
  ],
})
