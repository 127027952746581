import type { DescriptionsProps } from "antd"
import { List } from "antd"
import { DescriptionsItemType } from "antd/es/descriptions"
import { NotificationInstance } from "antd/es/notification/interface"
import type { ColumnsType } from "antd/es/table"
import { ColumnGroupType, ColumnType } from "antd/es/table/interface"
import _ from "lodash"
import md5 from "md5"
import * as React from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Link } from "react-router-dom"
import { AwsAssetType } from "../../../common/aws/types"
import { AwsResource } from "../../../common/model/common"
import {
  booleanValue,
  numericTimestampValue,
  timestampValue,
} from "../components/description/util"
import {
  accountColumn,
  displayNameColumn,
  metaIdColumn,
  regionColumn,
} from "../components/table/columns"
import { toDisplayString } from "../utils"
import { resolveLinkToAsset } from "./links"

export type AssetContentColumn<T> =
  | keyof T
  | AssetContentColumnProps<T>
  | ColumnGroupType<T>
  | ColumnType<T>

export type AssetDescription<T> = keyof T | AssetDescriptionProps<T>

interface AssetContentColumnProps<T> {
  value: keyof T | ((asset: T) => unknown)

  /**
   * Description label shown next to the value.
   * If undefined and if the value is key of type T, will be generated from the value.
   */
  label?: string

  /**
   * Enable filtering table rows by this column's value
   */
  filter?: boolean

  /**
   * Function to convert the given value to a display value
   * to be shown in user interface.
   */
  displayValue?: (value: unknown) => React.ReactNode

  /**
   * Default value to show in user interface if the actual
   * value is undefined.
   */
  defaultDisplayValue?: React.ReactNode

  /**
   * Function to convert the given value to an internal value
   * to be used in sorting and filtering.
   */
  internalValue?: (value: unknown) => string | number

  /**
   * Default internal value to be used in sorting and filtering
   * if the actual value is undefined.
   */
  defaultInternalValue?: string | number
}

interface AssetDescriptionProps<T> {
  /**
   * Description label shown next to the value.
   * If undefined and if the value is key of type T, will be generated from the value.
   */
  label?: string

  /**
   * Value to show. If key of type T is given, then the value is read from the asset.
   * Can also be a ReactNode or a function that accepts the asset and returns a ReactNode.
   */
  value: keyof T | React.ReactNode | ((asset: T) => React.ReactNode)

  /**
   * Function that accepts a value given in the value property, and returns the same value
   * rendered in another form.
   */
  render?: (value: unknown) => React.ReactNode

  /**
   * Value that will be shown if the value given with the value property is undefined.
   */
  defaultValue?: React.ReactNode
}

interface DescriptionDetailSectionProps<T> {
  asset: T
  title: string
  type?: "description"
  enabled?: boolean
  items:
    | ReadonlyArray<AssetDescription<T>>
    | ((asset: T) => ReadonlyArray<AssetDescription<T>>)
}

interface TableDetailSectionProps<T, R> {
  asset: T
  title: string
  type: "table"
  enabled?: boolean
  data: () => ReadonlyArray<R>
  rowKey?: (row: R) => any
  columns?: ReadonlyArray<AssetContentColumn<R>>
}

interface SimpleTableDetailSectionProps<T> {
  asset: T
  property: keyof T
  // title: string
  type: "table"
  // enabled?: boolean
  // data: () => ReadonlyArray<R>
  // rowKey?: (row: R) => any
  // columns?: ReadonlyArray<AssetContentColumn<R>>
}

interface BarChartDetailSectionProps<T> {
  type: "bar-chart"
  enabled?: boolean
  title: string
  xAxisKey: keyof T
  valueKey: keyof T
  data: () => Array<T>
  description: string
}

export type DetailSectionProps<T> =
  | DescriptionDetailSectionProps<T>
  | TableDetailSectionProps<T, any>
  | BarChartDetailSectionProps<T>

export const tableSection = <T, R>(
  propsProvider:
    | Omit<TableDetailSectionProps<T, R>, "type">
    | (() => Omit<TableDetailSectionProps<T, R>, "type">),
): TableDetailSectionProps<T, R> => {
  const props =
    typeof propsProvider === "function" ? propsProvider() : propsProvider

  const assets = props.enabled !== false ? props.data() : undefined
  if (!assets || assets.length === 0) {
    return {
      type: "table",
      ...props,
      enabled: false,
    }
  }

  const columns = props.columns ?? autodetectColumns({ assets })

  return {
    type: "table",
    ...props,
    columns: columns as ReadonlyArray<AssetContentColumn<R>>,
  }
}

export const simpleTableSection = <T, R>(
  propsProvider:
    | Omit<SimpleTableDetailSectionProps<T>, "type">
    | (() => Omit<SimpleTableDetailSectionProps<T>, "type">),
): TableDetailSectionProps<T, R> => {
  const props =
    typeof propsProvider === "function" ? propsProvider() : propsProvider

  const title = toDisplayString(props.property as string)

  const assets = props.asset[props.property]

  if (!assets || !Array.isArray(assets) || assets.length === 0) {
    return {
      ...props,
      title,
      type: "table",
      data: () => [],
      enabled: false,
    }
  }

  const columns = autodetectColumns({ assets })

  return {
    ...props,
    title,
    type: "table",
    data: () => assets,
    columns: columns as ReadonlyArray<AssetContentColumn<R>>,
  }
}

export interface DescriptionDetailSection {
  title: string
  type: "description"
  items: DescriptionsProps["items"]
}

export interface TableDetailSection {
  title: string
  type: "table"
  columns: Array<ColumnType<any>>
  data: ReadonlyArray<any>
  rowKey: (row: any) => any
}

export interface BarChartDetailSection {
  title: string
  xAxisKey: string
  valueKey: string
  data: Array<any>
  type: "bar-chart"
  description: string
}

export type DetailSection =
  | DescriptionDetailSection
  | TableDetailSection
  | BarChartDetailSection

export interface AssetContent<
  LIST_ASSET extends AwsResource,
  DETAILED_ASSET extends AwsResource,
> {
  type: AwsAssetType
  list: (assets: ReadonlyArray<LIST_ASSET>) => ColumnsType<LIST_ASSET>
  details: (asset: DETAILED_ASSET) => ReadonlyArray<DetailSection>
}

export interface AssetContents {
  [assetType: string]: AssetContent<any, any>
}

export interface AssetGroupContents {
  [assetGroup: string]: AssetContents
}

type ListDefaultColumnName = "display-name"

interface ListAssetContentProps<LIST_ASSET extends AwsResource> {
  /**
   * Table columns.
   */
  columns?: ReadonlyArray<AssetContentColumn<LIST_ASSET>>

  /**
   * Properties to exclude from the table columns.
   */
  exclude?: ReadonlyArray<ListDefaultColumnName | keyof LIST_ASSET>

  /**
   * List of columns that can be filtered.
   */
  filtered?: ReadonlyArray<ListDefaultColumnName | keyof LIST_ASSET>

  /**
   * Additional columns
   */
  additional?: ReadonlyArray<AssetContentColumnProps<LIST_ASSET>>
}

export interface AssetContentProps<
  LIST_ASSET extends AwsResource,
  DETAILED_ASSET extends AwsResource,
> {
  type: AwsAssetType
  /**
   * Table columns for the asset list view.
   */
  list?: ListAssetContentProps<LIST_ASSET>

  /**
   * Content sections for the asset details view.
   */
  details?: (asset: DETAILED_ASSET) => ReadonlyArray<DetailSectionProps<any>>
}

interface GetDisplayValueProps {
  /**
   * Value
   */
  value: React.ReactNode

  /**
   * Function to convert the given value to a display value
   * to be shown in user interface.
   */
  displayValue?: (value: unknown) => React.ReactNode

  /**
   * Default value to show in user interface if the actual
   * value is undefined.
   */
  defaultDisplayValue?: React.ReactNode

  relativePath: number
}

const isIsoDateString = (value: unknown): boolean => {
  if (typeof value !== "string") {
    return false
  }

  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(value)
}

const getDisplayValue = ({
  value,
  displayValue,
  defaultDisplayValue = "-",
  relativePath,
}: GetDisplayValueProps): React.ReactNode => {
  if (value === undefined || value === null) {
    return defaultDisplayValue
  }

  if (displayValue) {
    return displayValue(value)
  }

  if (typeof value === "boolean") {
    return booleanValue(value)
  }

  if (typeof value === "string" && value.trim() === "") {
    return defaultDisplayValue
  }

  if (isIsoDateString(value)) {
    return timestampValue(value)
  }

  // Autodetect relations to other assets
  if (typeof value === "string") {
    const link = resolveLinkToAsset(value, relativePath)
    if (link) {
      return link
    }
  }

  if (Array.isArray(value)) {
    if (value.length === 0) {
      return defaultDisplayValue
    }

    const arrayWithPrimitiveValues = value.every((v) =>
      ["number", "string", "boolean"].includes(typeof v),
    )
    if (arrayWithPrimitiveValues) {
      if (value.length === 1) {
        const link = resolveLinkToAsset(value[0], relativePath)
        if (link) {
          return link
        }

        return value[0]
      }

      const values = value.map((v) => {
        const link = resolveLinkToAsset(v, relativePath)
        if (link) {
          return link
        }

        return v
      })

      return (
        <List
          dataSource={values}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      )
    }
  }

  if (typeof value === "object") {
    return <pre>{JSON.stringify(value, undefined, 2)}</pre>
  }

  return value
}

interface GetInternalValueProps {
  /**
   * Value
   */
  value: unknown
  /**
   * Default value to use if the actual value is undefined
   */
  defaultInternalValue?: string | number
  /**
   * Optional function to convert the given value to a internal value
   * that to be used in sorting and filtering.
   */
  internalValue?: (value: unknown) => string | number
}

const getInternalValue = ({
  value,
  defaultInternalValue = "-",
  internalValue,
}: GetInternalValueProps): string | number => {
  if (value === undefined || value === null) {
    return defaultInternalValue
  }

  if (internalValue) {
    return internalValue(value)
  }

  if (typeof value === "boolean") {
    return booleanValue(value)
  }

  if (typeof value === "number") {
    return value
  }

  if (isIsoDateString(value)) {
    return numericTimestampValue(value)
  }

  if (typeof value === "object") {
    return JSON.stringify(value)
  }

  return String(value)
}

const isAssetContentColumnProps = (
  column: AssetContentColumn<any>,
): column is AssetContentColumnProps<any> => {
  return (column as any).value !== undefined
}

const getColumns = (
  columns: ReadonlyArray<AssetContentColumn<any>>,
  assets: ReadonlyArray<any>,
  filteredColumns: ReadonlyArray<any>,
  relativePath: number,
): Array<any> => {
  return columns.map((column) => {
    if (
      typeof column === "symbol" ||
      typeof column === "number" ||
      typeof column === "string"
    ) {
      const columnName = String(column)

      const filtered = filteredColumns.includes(columnName)

      const filters = filtered
        ? _.uniq(
            assets.map((asset) => {
              return getInternalValue({
                value: asset[columnName],
              })
            }),
          ).map((a) => ({ text: a, value: a }))
        : undefined

      const c: ColumnType<any> = {
        key: columnName,
        title: toDisplayString(columnName),
        render: (value, record) => {
          const displayValue = getDisplayValue({
            value: record[columnName],
            relativePath,
          })

          return <div style={{ minWidth: "150px" }}>{displayValue}</div>
        },
        sorter: (a, b) => {
          const aVal = getInternalValue({ value: a[columnName] })
          const bVal = getInternalValue({ value: b[columnName] })
          if (typeof aVal === "string" && typeof bVal === "string") {
            return aVal.localeCompare(bVal)
          }
          if (typeof aVal === "number" && typeof bVal === "number") {
            return aVal - bVal
          }
          if (typeof aVal === "number" && typeof bVal === "string") {
            return 1
          }
          if (typeof aVal === "string" && typeof bVal === "number") {
            return -1
          }
          throw new Error(
            `Expected getInternalValue to return string or number but got a: '${typeof aVal}', b: '${typeof bVal}'`,
          )
        },
        onFilter: (v, record) =>
          getInternalValue({ value: record[columnName] }) === v,
        filterSearch: filtered,
        filters,
      }

      return c
    }

    if (isAssetContentColumnProps(column)) {
      if (typeof column.value === "function") {
        if (!column.label) {
          throw new Error("label is required when value is of type function")
        }

        const filtered = column.filter === true

        const filters = filtered
          ? _.uniq(
              assets.map((asset) => {
                return getInternalValue({
                  value: (column.value as Function)(asset), // TODO: Get rid of this cast
                  defaultInternalValue: column.defaultInternalValue,
                  internalValue: column.internalValue,
                })
              }),
            )
              .map((a) => ({ text: a, value: a }))
              .sort()
          : undefined

        const c: ColumnType<any> = {
          key: column.label,
          title: toDisplayString(column.label),
          render: (value, record) => {
            const displayValue = getDisplayValue({
              value: (column.value as Function)(record),
              defaultDisplayValue: column.defaultDisplayValue,
              displayValue: column.displayValue,
              relativePath,
            })

            return <div style={{ minWidth: "150px" }}>{displayValue}</div>
          },
          sorter: (a, b) => {
            const aVal = getInternalValue({
              value: (column.value as Function)(a),
            })
            const bVal = getInternalValue({
              value: (column.value as Function)(b),
            })
            if (typeof aVal === "string" && typeof bVal === "string") {
              return aVal.localeCompare(bVal)
            }
            if (typeof aVal === "number" && typeof bVal === "number") {
              return aVal - bVal
            }
            if (typeof aVal === "number" && typeof bVal === "string") {
              return 1
            }
            if (typeof aVal === "string" && typeof bVal === "number") {
              return -1
            }
            throw new Error(
              `Expected getInternalValue to return string or number but got a: '${typeof aVal}', b: '${typeof bVal}'`,
            )
          },
          onFilter: (v, record) =>
            getInternalValue({ value: (column.value as Function)(record) }) ===
            v,
          filterSearch: filtered,
          filters,
        }

        return c
      } else {
        const columnName = String(column.value)
        const filtered = column.filter === true

        const filters = filtered
          ? _.uniq(
              assets.map((asset) => {
                return getInternalValue({
                  value: asset[columnName],
                  defaultInternalValue: column.defaultInternalValue,
                  internalValue: column.internalValue,
                })
              }),
            )
              .map((a) => ({ text: a, value: a }))
              .sort()
          : undefined

        const c: ColumnType<any> = {
          key: columnName,
          title: column.label ?? toDisplayString(columnName),
          render: (value, record) => {
            const displayValue = getDisplayValue({
              value: record[columnName],
              defaultDisplayValue: column.defaultDisplayValue,
              displayValue: column.displayValue,
              relativePath,
            })

            return <div style={{ minWidth: "150px" }}>{displayValue}</div>
          },
          sorter: (a, b) => {
            const aVal = getInternalValue({ value: a[columnName] })
            const bVal = getInternalValue({ value: b[columnName] })
            if (typeof aVal === "string" && typeof bVal === "string") {
              return aVal.localeCompare(bVal)
            }
            if (typeof aVal === "number" && typeof bVal === "number") {
              return aVal - bVal
            }
            if (typeof aVal === "number" && typeof bVal === "string") {
              return 1
            }
            if (typeof aVal === "string" && typeof bVal === "number") {
              return -1
            }
            throw new Error(
              `Expected getInternalValue to return string or number but got a: '${typeof aVal}', b: '${typeof bVal}'`,
            )
          },
          onFilter: (v, record) =>
            getInternalValue({ value: record[columnName] }) === v,
          filterSearch: filtered,
          filters,
        }

        return c
      }
    }

    const c = column as ColumnType<any>
    if (c.sorter) {
      return c
    }

    return {
      ...c,
      sorter: (a, b) => {
        const aVal = getInternalValue({ value: a[`${c.dataIndex}`] })
        const bVal = getInternalValue({ value: b[`${c.dataIndex}`] })
        if (typeof aVal === "string" && typeof bVal === "string") {
          return aVal.localeCompare(bVal)
        }
        if (typeof aVal === "number" && typeof bVal === "number") {
          return aVal - bVal
        }
        if (typeof aVal === "number" && typeof bVal === "string") {
          return 1
        }
        if (typeof aVal === "string" && typeof bVal === "number") {
          return -1
        }
        throw new Error(
          `Expected getInternalValue to return string or number but got a: '${typeof aVal}', b: '${typeof bVal}'`,
        )
      },
    }
  })
}

export type AssetContentProviderProps = {
  notification: NotificationInstance
}

export type AssetContentProvider<
  LIST_ASSET extends AwsResource,
  DETAILED_ASSET extends AwsResource,
> = {
  type: AwsAssetType
  getContent: (
    props: AssetContentProviderProps,
  ) => AssetContent<LIST_ASSET, DETAILED_ASSET>
}

export const assetContent = <
  LIST_ASSET extends AwsResource,
  DETAILED_ASSET extends AwsResource = LIST_ASSET,
>(
  props: AssetContentProps<LIST_ASSET, DETAILED_ASSET>,
): AssetContentProvider<LIST_ASSET, DETAILED_ASSET> => {
  const getContent = ({
    notification,
  }: AssetContentProviderProps): AssetContent<LIST_ASSET, DETAILED_ASSET> => {
    // Needed to make relative links
    const detailRelativePath = 4
    const listRelativePath = 2

    const metaSection: (
      asset: DETAILED_ASSET,
    ) => DescriptionDetailSectionProps<DETAILED_ASSET> = (asset) => ({
      asset,
      title: "Meta",
      type: "description",
      items: [
        {
          label: "Meta Id",
          value: (asset) => asset._meta.id,
        },
        {
          label: "Account Name",
          value: () => asset._meta.accountName ?? "-",
          render: () => (
            <Link
              to={`../../../../account/account/${md5(asset._meta.accountId)}`}
              relative="path"
            >
              {asset._meta.accountName ?? "-"}
            </Link>
          ),
        },
        {
          label: "Account Id",
          value: () => asset._meta.accountId,
          render: () => (
            <Link
              to={`../../../../account/account/${md5(asset._meta.accountId)}`}
              relative="path"
            >
              {asset._meta.accountId}
            </Link>
          ),
        },
        {
          label: "Region",
          value: () => asset._meta.region,
        },
        {
          label: "Organization Id",
          value: () => asset._meta.organizationId,
        },
      ],
    })

    const tagsSection = (
      asset: AwsResource,
    ): TableDetailSectionProps<AwsResource, { Key: string; Value: string }> =>
      tableSection({
        asset,
        title: "Tags",
        columns: ["Key", "Value"],
        enabled: !_.isEmpty(asset._meta.tags),
        data: () =>
          Array.from(Object.entries(asset._meta.tags))
            .map(([Key, Value]) => ({
              Key,
              Value,
            }))
            .sort((a, b) => a.Key.localeCompare(b.Key)),
      })

    const getDetailItems = (d: DescriptionDetailSectionProps<any>) =>
      typeof d.items === "function" ? d.items(d.asset) : d.items

    const createDetailSection = (
      p: DetailSectionProps<DETAILED_ASSET>,
    ): DetailSection => {
      if (p.type === "table") {
        const tableSection: TableDetailSection = {
          title: p.title,
          type: "table",
          columns: getColumns(p.columns, [], [], detailRelativePath),
          data: p.data(),
          rowKey: p.rowKey ?? ((r: any) => md5(JSON.stringify(r))),
        }

        return tableSection
      } else if (p.type === "bar-chart") {
        const barChartSection: BarChartDetailSection = {
          title: p.title,
          type: "bar-chart",
          xAxisKey: String(p.xAxisKey),
          valueKey: String(p.valueKey),
          data: p.data(),
          description: p.description,
        }

        return barChartSection
      } else {
        const items = getDetailItems(p).map((description, key) => {
          if (
            typeof description === "symbol" ||
            typeof description === "number" ||
            typeof description === "string"
          ) {
            const val = p.asset[String(description)]

            const value = getDisplayValue({
              value: val,
              relativePath: detailRelativePath,
            })

            const internalValue = getInternalValue({ value: val })

            const label = toDisplayString(String(description))

            const c: DescriptionsItemType = {
              key,
              label,
              labelStyle: {
                width: "250px",
                verticalAlign: "top",
              },
              children: (
                <CopyToClipboard
                  text={internalValue}
                  onCopy={(text, result) => {
                    notification.success({
                      message: `Copied "${label}" to clipboard`,
                    })
                  }}
                >
                  <div
                    style={{ cursor: "copy" }}
                    title="Click to copy to clipboard"
                  >
                    {value}
                  </div>
                </CopyToClipboard>
              ),
            }

            return c
          }

          // TODO: implement type guard and write this some other more nicer way
          if (typeof description === "object") {
            let value = undefined
            if (typeof description.value === "function") {
              value = description.value(p.asset)
            } else if (
              typeof description.value === "symbol" ||
              typeof description.value === "number" ||
              typeof description.value === "string"
            ) {
              value = p.asset[String(description.value)]
            } else {
              value = description.value
            }

            const label =
              description.label ?? toDisplayString(String(description.value)) // TODO: fix me

            const displayValue = getDisplayValue({
              value,
              displayValue: description.render,
              defaultDisplayValue: description.defaultValue,
              relativePath: detailRelativePath,
            })

            const internalValue = getInternalValue({ value })

            const c: DescriptionsItemType = {
              key,
              label,
              labelStyle: {
                width: "250px",
                verticalAlign: "top",
              },
              children: (
                <CopyToClipboard
                  text={internalValue}
                  onCopy={(text, result) => {
                    notification.success({
                      message: `Copied "${label}" to clipboard`,
                    })
                  }}
                >
                  <div
                    style={{ cursor: "copy" }}
                    title="Click to copy to clipboard"
                  >
                    {displayValue}
                  </div>
                </CopyToClipboard>
              ),
            }

            return c
          }

          throw new Error("shouldn't happen")
        })

        return {
          title: p.title,
          type: "description",
          items: items.sort((a, b) =>
            String(a.label).localeCompare(String(b.label)),
          ),
        }
      }
    }

    const details = (asset: DETAILED_ASSET): ReadonlyArray<DetailSection> => {
      const detailsBuilder =
        props.details ?? ((asset: any) => [basicAssetDetails({ asset })])

      const assetDetailsProps: ReadonlyArray<DetailSectionProps<any>> = [
        ...detailsBuilder(asset),
        tagsSection(asset),
        metaSection(asset),
      ]

      const assetDetails = assetDetailsProps
        .filter((d) => d.enabled ?? true)
        .map((d) => createDetailSection(d))
        .sort((a, b) => {
          if (a.title === "Basic") {
            return -1
          }

          if (b.title === "Basic") {
            return 1
          }

          if (a.title === "Meta") {
            return 1
          }

          if (b.title === "Meta") {
            return -1
          }

          return a.title.localeCompare(b.title)
        })

      return [...assetDetails]
    }

    const list = (
      assets: ReadonlyArray<LIST_ASSET>,
    ): ColumnsType<LIST_ASSET> => {
      const defaultColumns = [metaIdColumn()]
      if (
        !props.list?.exclude ||
        !props.list.exclude.includes("display-name")
      ) {
        defaultColumns.push(displayNameColumn())
      }

      const columnDefinitions = props.list?.columns ?? [
        ...autodetectColumns({
          assets,
          exclude: props.list?.exclude,
        }),
        ...(props.list?.additional ?? []),
      ]

      const columns = getColumns(
        [
          ...columnDefinitions,
          {
            label: "Previous Month Cost",
            value: (a) => a._meta?.cost?.previousMonthCost,
            displayValue: (v) => {
              if (typeof v === "number") {
                return v.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
              }

              return String(v)
            },
          },
          {
            label: "Current Month Cost",
            value: (a) => a._meta?.cost?.currentMonthCost,
            displayValue: (v) => {
              if (typeof v === "number") {
                return v.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
              }

              return String(v)
            },
          },
          {
            label: "Audit Findings",
            value: (a) => a._meta?.audit?.findingCount,
          },
        ],
        assets,
        props.list?.filtered ?? [],
        listRelativePath,
      )

      return [...defaultColumns, ...columns, accountColumn(), regionColumn()]
    }

    return {
      type: props.type,
      list,
      details,
    }
  }

  return {
    type: props.type,
    getContent,
  }
}

type AutodetectColumnsProps<LIST_ASSET> = {
  assets: ReadonlyArray<LIST_ASSET>
  exclude?: ReadonlyArray<ListDefaultColumnName | keyof LIST_ASSET>
}

const autodetectColumns = <LIST_ASSET,>({
  assets,
  exclude = [],
}: AutodetectColumnsProps<LIST_ASSET>): Array<AssetContentColumn<any>> => {
  if (!assets || assets.length === 0) {
    return []
  }

  const included = new Set<string>()
  const excluded = new Set<string>([
    "_meta",
    "Tags",
    ...exclude.map((k) => String(k)),
  ])

  for (const asset of assets) {
    for (const key of Object.keys(asset)) {
      if (included.has(key) || excluded.has(key)) {
        continue
      }

      const value = asset[key]

      if (["boolean", "string", "number"].includes(typeof value)) {
        included.add(key)
      } else {
        excluded.add(key)
      }
    }
  }

  return new Array<AssetContentColumn<any>>(...included.values())
}

export interface AssetDetailsProps<T> {
  asset: T
  exclude?: ReadonlyArray<keyof T>
  additional?: ReadonlyArray<AssetDescriptionProps<T>>
}

export const basicAssetDetails = <T,>(
  props: AssetDetailsProps<T>,
): DetailSectionProps<T> => {
  return descriptionSection({
    ...props,
    title: "Basic",
  })
}

export interface DescriptionSectionProps<T> {
  title: string
  asset: T
  exclude?: ReadonlyArray<keyof T>
  include?: ReadonlyArray<keyof T>
  additional?: ReadonlyArray<AssetDescriptionProps<T>>
  override?: Record<keyof T, AssetDescriptionProps<T>>
  enabled?: boolean
}

export const descriptionSection = <T,>({
  asset,
  include,
  exclude = [],
  additional = [],
  title,
  override = {} as Record<keyof T, AssetDescriptionProps<T>>,
  enabled = asset !== undefined,
}: DescriptionSectionProps<T>): DetailSectionProps<T> => {
  if (!enabled || !asset) {
    // TODO: Make it possible to return undefined?
    return {
      title,
      asset,
      items: [],
      enabled: false,
    }
  }

  const overrideKeys = Object.keys(override)
  const overrideItems = Object.values(override) as AssetDescriptionProps<T>[]

  const autoDetectedItems = Object.keys(asset)
    .filter((key) => !["_meta", "Tags"].includes(key))
    .filter((key) => !include || include.includes(key as keyof T))
    .filter((key) => !exclude.includes(key as keyof T))
    .filter((key) => !overrideKeys.includes(key))
    .map((key) => key as AssetDescription<T>)

  const items = [...autoDetectedItems, ...additional, ...overrideItems]

  return {
    title,
    items,
    enabled,
    asset,
  }
}

export const barChartSection = <T,>({
  title,
  enabled,
  xAxisKey,
  valueKey,
  data,
  description,
}: Omit<
  BarChartDetailSectionProps<T>,
  "type"
>): BarChartDetailSectionProps<T> => {
  return {
    title,
    enabled,
    xAxisKey,
    valueKey,
    data,
    description,
    type: "bar-chart",
  }
}
