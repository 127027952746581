import { CertificateResource } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  tableSection,
} from "../../asset-content"

export const awsAcmCertificates = assetContent<
  CertificateResource,
  CertificateResource
>({
  type: "acm/certificate",
  list: {
    filtered: ["Status", "RenewalEligibility", "Type"],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["DomainValidationOptions", "ExtendedKeyUsages"],
    }),
    tableSection({
      asset,
      title: "Domain Validation Options",
      data: () => asset.DomainValidationOptions,
    }),
    tableSection({
      asset,
      title: "Extended Key Usages",
      data: () => asset.ExtendedKeyUsages,
    }),
  ],
})
