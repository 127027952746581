import { VpcEndpointAsset } from "../../../../../common/model/data/aws-data"
import { assetContent, basicAssetDetails } from "../../asset-content"

export const awsVpcVpcEndpointsContent = assetContent<VpcEndpointAsset>({
  type: "vpc/vpc-endpoint",
  list: {
    filtered: [
      "VpcEndpointType",
      "VpcId",
      "ServiceName",
      "State",
      "IpAddressType",
      "RequesterManaged",
      "PrivateDnsEnabled",
    ],
    exclude: ["PolicyDocument"],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["PolicyDocument"],
      additional: [
        {
          label: "Policy Document",
          value: () => JSON.parse(asset.PolicyDocument),
          render: (document) => (
            <pre>{JSON.stringify(document, undefined, 2)}</pre>
          ),
        },
      ],
    }),
  ],
})
