import { AwsResource } from "../../../common/model/common"
import { HttpClient } from "./http-client"
import { extractData } from "./response-mappers"

const CONTENT_PATH = "/api/assets"

interface ContentApiParams {
  clientId: string
  syncJob: number
}

interface AwsResourceTypeParams extends ContentApiParams {
  assetFamily: string
  assetKind: string
}

interface AwsResourceDetailParams extends AwsResourceTypeParams {
  assetId: string
  product: string
}

export interface AssetsApi {
  getAssetDetails: (params: AwsResourceDetailParams) => Promise<AwsResource>
}

export const assetsApiUsing = ({ get }: HttpClient): AssetsApi => ({
  getAssetDetails: ({
    clientId,
    syncJob,
    assetFamily,
    assetKind,
    assetId,
    product,
  }) =>
    get(
      `${CONTENT_PATH}/${clientId}/${syncJob}/${product}/${assetFamily}/${assetKind}/${assetId}`,
    ).then(extractData),
})
