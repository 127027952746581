import { AwsAssetAuditFinding } from "../../../common/aws/types"
import {
  AwsResource,
  KeySearchIndex,
  SearchIndex,
  SearchIndexAssetsData,
} from "../../../common/model/common"
import {
  AwsAuditSummary,
  AwsSummaryDto,
} from "../../../common/model/content/aws-content"
import { HttpClient } from "./http-client"
import { extractData } from "./response-mappers"

const CONTENT_PATH = "/api/content"

interface ContentApiParams {
  clientId: string
  syncJob: number
}

interface AwsResourceTypeParams extends ContentApiParams {
  assetFamily: string
  assetType: string
}

interface AwsResourceDetailParams extends AwsResourceTypeParams {
  assetId: string
}

export interface ContentApi {
  getAwsSummary: (params: ContentApiParams) => Promise<AwsSummaryDto>
  getAwsAuditSummary: (params: ContentApiParams) => Promise<AwsAuditSummary>
  getAwsAuditData: (
    params: ContentApiParams,
  ) => Promise<ReadonlyArray<AwsAssetAuditFinding>>
  getAwsSearchPrimaryIndex: (params: ContentApiParams) => Promise<SearchIndex>
  getAwsSearchRegionsIndex: (params: ContentApiParams) => Promise<SearchIndex>
  getAwsSearchAssetTypesIndex: (
    params: ContentApiParams,
  ) => Promise<SearchIndex>
  getAwsSearchAccountsIndex: (params: ContentApiParams) => Promise<SearchIndex>
  getAwsSearchTagsIndex: (params: ContentApiParams) => Promise<KeySearchIndex>
  getAwsSearchAssetsData: (
    params: ContentApiParams,
  ) => Promise<SearchIndexAssetsData>
  listAwsResourcesByType: (
    params: AwsResourceTypeParams,
  ) => Promise<ReadonlyArray<AwsResource>>
  getAwsResourceDetails: (
    params: AwsResourceDetailParams,
  ) => Promise<AwsResource>
}

export const contentApiUsing = ({ get }: HttpClient): ContentApi => ({
  getAwsSummary: ({ clientId, syncJob }) =>
    get(`${CONTENT_PATH}/${clientId}/${syncJob}/aws/summary`),
  getAwsAuditSummary: ({ clientId, syncJob }) =>
    get(`${CONTENT_PATH}/${clientId}/${syncJob}/aws/audit-summary`),
  getAwsAuditData: ({ clientId, syncJob }) =>
    get(`${CONTENT_PATH}/${clientId}/${syncJob}/aws/audit`),
  getAwsSearchPrimaryIndex: ({ clientId, syncJob }) =>
    get(`${CONTENT_PATH}/${clientId}/${syncJob}/aws/search-index-primary`),
  getAwsSearchAccountsIndex: ({ clientId, syncJob }) =>
    get(`${CONTENT_PATH}/${clientId}/${syncJob}/aws/search-index-accounts`),
  getAwsSearchRegionsIndex: ({ clientId, syncJob }) =>
    get(`${CONTENT_PATH}/${clientId}/${syncJob}/aws/search-index-regions`),
  getAwsSearchAssetTypesIndex: ({ clientId, syncJob }) =>
    get(`${CONTENT_PATH}/${clientId}/${syncJob}/aws/search-index-asset-types`),
  getAwsSearchTagsIndex: ({ clientId, syncJob }) =>
    get(`${CONTENT_PATH}/${clientId}/${syncJob}/aws/search-index-tags`),
  getAwsSearchAssetsData: ({ clientId, syncJob }) =>
    get(`${CONTENT_PATH}/${clientId}/${syncJob}/aws/search-data-assets`),
  getAwsResourceDetails: ({
    clientId,
    syncJob,
    assetFamily,
    assetType,
    assetId,
  }) =>
    get(
      `${CONTENT_PATH}/${clientId}/${syncJob}/aws/${assetFamily}/${assetType}/${assetId}`,
    ),
  listAwsResourcesByType: ({ clientId, syncJob, assetFamily, assetType }) =>
    get(
      `${CONTENT_PATH}/${clientId}/${syncJob}/aws/${assetFamily}/${assetType}`,
    ).then(extractData),
})
