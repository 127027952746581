import { CacheClusterAsset } from "../../../../../common/model/data/aws-data"
import { assetContent } from "../../asset-content"

export const awsElasticacheCacheClusters = assetContent<
  CacheClusterAsset,
  CacheClusterAsset
>({
  type: "elasticache/cache-cluster",
  list: {
    exclude: ["ClientDownloadLandingPage"],
    filtered: [
      "CacheClusterStatus",
      "AtRestEncryptionEnabled",
      "AutoMinorVersionUpgrade",
      "Engine",
      "EngineVersion",
      "CacheClusterStatus",
      "PreferredAvailabilityZone",
      "TransitEncryptionEnabled",
      "TransitEncryptionMode",
      "AtRestEncryptionEnabled",
      "ReplicationGroupLogDeliveryEnabled",
      "NetworkType",
      "IpDiscovery",
    ],
  },
})
