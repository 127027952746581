import { EcsTaskAsset } from "../../../../../common/model/data/aws-data"
import { assetContent } from "../../asset-content"

export const awsEcsTasksContent = assetContent<EcsTaskAsset, EcsTaskAsset>({
  type: "ecs/task",
  list: {
    filtered: [
      "availabilityZone",
      "connectivity",
      "cpu",
      "enableExecuteCommand",
      "launchType",
      "platformFamily",
      "platformVersion",
    ],
    exclude: ["healthStatus", "desiredStatus", "lastStatus"],
  },
})
