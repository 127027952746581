import _ from "lodash"
import { DbSubnetGroupAsset } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  tableSection,
} from "../../asset-content"

export const awsRdsDbSubnetGroupsContent = assetContent<
  DbSubnetGroupAsset,
  DbSubnetGroupAsset
>({
  type: "rds/db-subnet-group",
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["Subnets"],
    }),
    tableSection({
      asset,
      title: "Subnets",
      enabled: !_.isEmpty(asset.Subnets),
      data: () =>
        asset.Subnets.map((s) => ({
          ...s,
          AvailabilityZone: s.SubnetAvailabilityZone.Name,
        })),
    }),
  ],
})
