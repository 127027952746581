import { NetworkInterfaceAsset } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
} from "../../asset-content"

export const awsEc2NetworkInterfaces = assetContent<
  NetworkInterfaceAsset,
  NetworkInterfaceAsset
>({
  type: "ec2/network-interface",
  list: {
    exclude: ["Description"],
    filtered: [
      "VpcId",
      "AvailabilityZone",
      "InterfaceType",
      "RequesterManaged",
      "Status",
      "SubnetId",
      "RequesterId",
    ],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["Attachment"],
    }),
    descriptionSection({
      title: "Attachment",
      asset: asset.Attachment,
    }),
  ],
})
