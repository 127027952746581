import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

export const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation()

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }, [pathname])

  return <div></div>
}
