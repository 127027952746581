import { Drawer, Table } from "antd"
import { ColumnType } from "antd/es/table/interface"
import _ from "lodash"
import React, { useContext, useEffect, useState } from "react"
import { AuditSettingDto } from "../../../../common/model/api"
import { ApiContext } from "../../ApiContext"
import {
  SelectedClientContext,
  SelectedClientReadyState,
} from "../../context/ClientsContext"
import { EditAuditSettingForm } from "./EditAuditSettingForm"

export const AwsAuditSettingsContent: React.FC = (props) => {
  const api = useContext(ApiContext)
  const selectedClient = useContext(SelectedClientContext)

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [editedSetting, setEditedSetting] = useState<AuditSettingDto>(undefined)
  const [loading, setLoading] = useState(true)
  const [settings, setSettings] =
    useState<ReadonlyArray<AuditSettingDto>>(undefined)

  useEffect(() => {
    if (!(selectedClient instanceof SelectedClientReadyState)) {
      return
    }

    const { id } = selectedClient.client
    setLoading(true)

    // TODO: Handle no syncJobs exist
    // TODO: Handle summary not found
    api
      .listAuditSettings(id)
      .then((data) => {
        setSettings(data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }, [selectedClient, api])

  if (!(selectedClient instanceof SelectedClientReadyState)) {
    return <div></div>
  }

  const typeFilters = loading
    ? []
    : _.uniq(settings.map((f) => f.type)).map((f) => ({
        text: f,
        value: f,
      }))

  const nameFilters = loading
    ? []
    : _.uniq(settings.map((f) => f.name)).map((f) => ({
        text: f,
        value: f,
      }))

  const ruleFilters = loading
    ? []
    : _.uniq(settings.map((f) => f.rule)).map((f) => ({
        text: f,
        value: f,
      }))

  const assetTypeFilters = loading
    ? []
    : _.uniq(settings.map((f) => f.assetType)).map((f) => ({
        text: f,
        value: f,
      }))

  const columns: ColumnType<AuditSettingDto>[] = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 100,
      sorter: (a, b) => a.product.localeCompare(b.product),
    },
    {
      title: "Asset Type",
      dataIndex: "assetType",
      key: "assetType",
      sorter: (a, b) => a.assetType.localeCompare(b.assetType),
      onFilter: (value: string, record) => record.assetType === value,
      filters: assetTypeFilters,
      filterSearch: true,
    },
    {
      title: "Rule",
      dataIndex: "rule",
      key: "rule",
      sorter: (a, b) => a.rule.localeCompare(b.rule),
      onFilter: (value: string, record) => record.rule === value,
      filters: ruleFilters,
      filterSearch: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      onFilter: (value: string, record) => record.name === value,
      filters: nameFilters,
      filterSearch: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
      onFilter: (value: string, record) => record.type === value,
      filters: typeFilters,
      filterSearch: true,
    },
    {
      title: "Default Value",
      dataIndex: "defaultValue",
      key: "defaultValue",
      render: (v) => {
        if (v === undefined) {
          return "-"
        }

        if (typeof v === "boolean") {
          return v ? "Yes" : "No"
        }

        if (Array.isArray(v)) {
          return v.length === 0 ? "-" : v.join(", ")
        }

        return v
      },
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (v, record) => {
        const val = v ?? record.defaultValue

        if (val === undefined) {
          return "-"
        }

        if (typeof val === "boolean") {
          return val ? "Yes" : "No"
        }

        if (Array.isArray(val)) {
          return val.length === 0 ? "-" : val.join(", ")
        }

        return val
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ]

  const openDrawer = (setting: AuditSettingDto) => {
    setEditedSetting(JSON.parse(JSON.stringify(setting)))
    setDrawerOpen(true)
  }

  const closeDrawer = () => {
    setDrawerOpen(false)
    setEditedSetting(undefined)
  }

  return (
    <div>
      <Table
        rowKey={(record) => record.assetType + record.rule + record.name}
        loading={loading}
        columns={columns}
        dataSource={settings}
        style={{ paddingTop: "20px" }}
        scroll={{
          y: 600,
          x: "max-content",
          scrollToFirstRowOnChange: true,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (setting) => {
              openDrawer(record)
            },
            style: {
              cursor: "pointer",
            },
          }
        }}
      />
      <Drawer title="Edit Setting" onClose={closeDrawer} open={drawerOpen}>
        <EditAuditSettingForm
          clientId={selectedClient.client.id}
          setting={editedSetting}
          onComplete={(value: number | string | boolean | undefined) => {
            const activeSetting = settings.find(
              (s) =>
                s.assetType === editedSetting.assetType &&
                s.rule === editedSetting.rule &&
                s.name === editedSetting.name,
            )

            if (activeSetting) {
              activeSetting.value = value
            }

            closeDrawer()
          }}
        />
      </Drawer>
    </div>
  )
}
