import { UserPoolClientAsset } from "../../../../../common/model/data/aws-data"
import { assetContent } from "../../asset-content"

export const awsCognitoUserPoolClients = assetContent<
  UserPoolClientAsset,
  UserPoolClientAsset
>({
  type: "cognito/user-pool-client",
  list: {
    filtered: [
      "AllowedOAuthFlowsUserPoolClient",
      "PreventUserExistenceErrors",
      "EnableTokenRevocation",
      "EnablePropagateAdditionalUserContextData",
    ],
  },
})
