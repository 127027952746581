import { Menu, MenuProps } from "antd"
import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { AwsSummaryDto } from "../../../common/model/content/aws-content"
import { assetFamilyDisplayName, assetTypeDisplayName } from "../content/texts"

type MenuItem = Required<MenuProps>["items"][number]

interface AwsResourcesSideMenuProps {
  selectedOrganizations: ReadonlyArray<string>
  selectedAccountIds: ReadonlyArray<string>
  selectedRegions: ReadonlyArray<string>
  selectedAssetGroups: ReadonlyArray<string>
  summary: AwsSummaryDto
}

export const AwsResourcesSideMenu: React.FC<AwsResourcesSideMenuProps> = ({
  summary,
  selectedOrganizations,
  selectedRegions,
  selectedAccountIds,
  selectedAssetGroups,
}: AwsResourcesSideMenuProps) => {
  const navigate = useNavigate()
  const { assetFamily, assetType } = useParams()

  if (!summary) {
    return <div></div>
  }

  const sideMenuTemp = {}

  for (const [orgId, orgSummary] of Array.from(
    Object.entries(summary.organizationsData),
  )) {
    if (
      selectedOrganizations.length > 0 &&
      !selectedOrganizations.includes(orgId)
    ) {
      continue
    }

    for (const [accountId, accountSummary] of Array.from(
      Object.entries(orgSummary),
    )) {
      if (
        selectedAccountIds.length > 0 &&
        !selectedAccountIds.includes(accountId)
      ) {
        continue
      }

      for (const [region, regionSummary] of Array.from(
        Object.entries(accountSummary),
      )) {
        if (selectedRegions.length > 0 && !selectedRegions.includes(region)) {
          continue
        }

        for (const [resGroupId, resGroupData] of Array.from(
          Object.entries(regionSummary),
        )) {
          for (const [resTypeId, counts] of Array.from(
            Object.entries(resGroupData),
          )) {
            if (selectedAssetGroups.length === 0) {
              if (!sideMenuTemp[resGroupId]) {
                sideMenuTemp[resGroupId] = {}
              }

              if (!sideMenuTemp[resGroupId][resTypeId]) {
                sideMenuTemp[resGroupId][resTypeId] = 0
              }

              const currentCount = sideMenuTemp[resGroupId][resTypeId] ?? 0

              sideMenuTemp[resGroupId][resTypeId] =
                currentCount + (counts as any)._total
            } else {
              const matchingCount = selectedAssetGroups.reduce(
                (total, group) => total + counts[group] ?? 0,
                0,
              )

              if (matchingCount > 0) {
                if (!sideMenuTemp[resGroupId]) {
                  sideMenuTemp[resGroupId] = {}
                }

                if (!sideMenuTemp[resGroupId][resTypeId]) {
                  sideMenuTemp[resGroupId][resTypeId] = 0
                }

                const currentCount = sideMenuTemp[resGroupId][resTypeId] ?? 0

                sideMenuTemp[resGroupId][resTypeId] =
                  currentCount + matchingCount
              }
            }
          }
        }
      }
    }
  }

  const items: MenuItem[] = Array.from(Object.entries(sideMenuTemp))
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(([resGroupId, resGroupData]) => {
      const groupItem: MenuItem = {
        key: resGroupId,
        label: assetFamilyDisplayName(resGroupId),
        type: "group",
        children: [],
      }

      const resItems = Array.from(Object.entries(resGroupData))
        .map(([resTypeId, count]) => {
          return {
            key: `${resGroupId}-${resTypeId}`,
            label: `${assetTypeDisplayName(resGroupId, resTypeId)} (${count})`,
            title: `${assetTypeDisplayName(resGroupId, resTypeId)} (${count})`,
            onClick: () => navigate(`${resGroupId}/${resTypeId}`),
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label))

      return [groupItem, ...resItems]
    })
    .flat()

  const selectedKeys =
    assetFamily !== undefined && assetType !== undefined
      ? [`${assetFamily}-${assetType}`]
      : []

  return (
    <Menu
      mode="inline"
      style={{ height: "100%" }}
      items={items}
      selectedKeys={selectedKeys}
    />
  )
}
