import { AwsBillingConfig } from "../../../common/model/data/dynamodb-types"
import { HttpClient } from "./http-client"
import { extractData } from "./response-mappers"

const API_PATH = "/api/billing-configs"

export interface BillingConfigsApi {
  listBillingConfigs: (
    clientId?: string,
  ) => Promise<ReadonlyArray<AwsBillingConfig>>
  // getAssetDataSource: (clientId: string, id: string) => Promise<UserDto>
  // createAssetDataSource: (request: CreateUserRequest) => Promise<UserDto>
  // updateAssetDataSource: (
  //   id: string,
  //   request: UpdateUserRequest,
  // ) => Promise<UserDto>
}

export const billingConfigsApiUsing = ({
  get,
}: HttpClient): BillingConfigsApi => ({
  listBillingConfigs: (clientId?) =>
    get(API_PATH, { clientId }).then(extractData),
})
