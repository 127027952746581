import { App, Breadcrumb, Col, Row, Spin, Table } from "antd"
import { NotificationInstance } from "antd/es/notification/interface"
import type { ColumnsType } from "antd/es/table"
import React, { useContext, useEffect, useState } from "react"
import { CSVLink } from "react-csv"
import { Link, useParams } from "react-router-dom"
import { AwsAssetType } from "../../../common/aws/types"
import { AwsResource } from "../../../common/model/common"
import { assetContent } from "../content/asset-content"
import { awsAssetContents } from "../content/aws/aws-content"
import { AssetsListingContext } from "../context/ClientsContext"

const defaultListContent = (
  type: AwsAssetType,
  assets: ReadonlyArray<AwsResource>,
  notification: NotificationInstance,
): ColumnsType<AwsResource> => {
  return assetContent({ type }).getContent({ notification }).list(assets)
}

const getColumns = (
  assetType: AwsAssetType,
  assets: ReadonlyArray<AwsResource>,
  notification: NotificationInstance,
): ColumnsType<any> => {
  const content = awsAssetContents.get(assetType)
  if (!content) {
    return defaultListContent(assetType, assets, notification)
  }

  const list = content.getContent({ notification }).list

  return list
    ? list(assets)
    : defaultListContent(assetType, assets, notification)
}

export const AssetsListContent: React.FC = () => {
  const { notification } = App.useApp()
  const assetsListingState = useContext(AssetsListingContext)
  const { assetFamily, assetType } = useParams()
  const [columns, setColumns] = useState<ColumnsType<any>>([])

  useEffect(() => {
    const columns = getColumns(
      (assetFamily + "/" + assetType) as AwsAssetType,
      assetsListingState.assets,
      notification,
    )
    setColumns(columns)
  }, [assetFamily, assetType, assetsListingState, notification])

  if (assetsListingState.status === "loading") {
    return (
      <div>
        <Breadcrumb
          style={{ paddingTop: "20px" }}
          items={[
            {
              title: assetsListingState.assetFamilyLabel,
            },
            {
              title: assetsListingState.assetTypeLabel,
            },
          ]}
        />

        <div style={{ paddingTop: "20px" }}>
          <Spin delay={500} />
        </div>
      </div>
    )
  }

  return (
    <div>
      <Row>
        <Col span={20}>
          <Breadcrumb
            style={{ paddingTop: "20px" }}
            items={[
              {
                title: (
                  <Link to="../" relative="path">
                    {assetsListingState.assetFamilyLabel}
                  </Link>
                ),
              },
              {
                title: assetsListingState.assetTypeLabel,
              },
            ]}
          />
        </Col>
        <Col span={4} style={{ paddingTop: "20px", textAlign: "right" }}>
          <CSVLink
            data={assetsListingState.assets}
            filename={`${assetFamily}-${assetType}.csv`}
            className="btn btn-primary"
            target="_blank"
          >
            Export to CSV
          </CSVLink>
        </Col>
      </Row>

      <Table
        rowKey={(record) => record._meta.id}
        columns={columns}
        dataSource={assetsListingState.assets}
        style={{ paddingTop: "20px" }}
        scroll={{
          y: 600,
          x: "max-content",
          scrollToFirstRowOnChange: true,
        }}
      />
    </div>
  )
}
