import {
  CreateUserRequest,
  UpdateUserRequest,
  UserDto,
} from "../../../common/model/api"
import { HttpClient } from "./http-client"
import { extractData } from "./response-mappers"

const USERS_PATH = "/api/users"

export interface UsersApi {
  listUsers: () => Promise<ReadonlyArray<UserDto>>
  getUser: (id: string) => Promise<UserDto>
  createUser: (request: CreateUserRequest) => Promise<UserDto>
  updateUser: (id: string, request: UpdateUserRequest) => Promise<UserDto>
}

export const usersApiUsing = ({ get, post, put }: HttpClient): UsersApi => ({
  listUsers: () => get(USERS_PATH).then(extractData),
  getUser: (id) => get(`${USERS_PATH}/${id}`).then(extractData),
  createUser: (request) => post(USERS_PATH, request).then(extractData),
  updateUser: (id: string, request: UpdateUserRequest) =>
    put(`${USERS_PATH}/${id}`, request).then(extractData),
})
