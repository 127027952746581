import { UserPoolAsset } from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  descriptionSection,
  tableSection,
} from "../../asset-content"

export const awsCognitoUserPools = assetContent<UserPoolAsset, UserPoolAsset>({
  type: "cognito/user-pool",
  list: {
    filtered: ["MfaConfiguration", "DeletionProtection"],
    exclude: [
      "EmailVerificationMessage",
      "EmailVerificationSubject",
      "SmsVerificationMessage",
      "SmsAuthenticationMessage",
    ],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: [
        "EmailConfiguration",
        "VerificationMessageTemplate",
        "SmsConfiguration",
        "Policies",
        "LambdaConfig",
        "SchemaAttributes",
      ],
    }),
    descriptionSection({
      asset: asset.Policies.PasswordPolicy,
      title: "Password Policy",
    }),
    descriptionSection({
      asset: asset.LambdaConfig,
      title: "Lambda Config",
    }),
    descriptionSection({
      asset: asset.EmailConfiguration,
      title: "Email Configuration",
    }),
    descriptionSection({
      asset: asset.SmsConfiguration,
      title: "SMS Configuration",
    }),
    descriptionSection({
      asset: asset.VerificationMessageTemplate,
      title: "Verification Message Template",
    }),
    tableSection({
      asset: asset.SchemaAttributes,
      title: "Schema Attributes",
      data: () => asset.SchemaAttributes,
    }),
  ],
})
