import { Breadcrumb, Layout, Spin, Tabs, TabsProps, theme } from "antd"
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react"
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import { ApiContext } from "../../ApiContext"
import {
  AdminClientDetailsContext,
  AdminClientDetailsDispatchContext,
  adminClientDetailsReducer,
} from "./ClientAdminContext"

const { Content } = Layout

export const ClientDetailsContainerContent: React.FC = () => {
  const api = useContext(ApiContext)
  const { clientId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [currentTab, setCurrentTab] = useState("basic-info")

  const [adminClientDetails, dispatchAdminClientDetails] = useReducer(
    adminClientDetailsReducer,
    {
      status: "loading",
    },
  )

  const {
    token: { colorBgContainer },
  } = theme.useToken()

  const tabs: TabsProps["items"] = useMemo(
    () => [
      {
        key: "basic-info",
        label: "Basic Info",
      },
      {
        key: "asset-data-sources",
        label: "Asset Data Sources",
      },
      {
        key: "asset-group-rules",
        label: "Asset Group Rules",
      },
    ],
    [],
  )

  useEffect(() => {
    dispatchAdminClientDetails({ action: "loading" })

    api
      .getClient(clientId)
      .then((client) => {
        dispatchAdminClientDetails({ client, action: "ready" })
      })
      .catch((error) => {
        console.log(error)
      })
  }, [clientId, api])

  useEffect(() => {
    const key = location.pathname.split("/").slice(-1)[0]
    const activeTab = tabs.find((t) => t.key === key) ?? tabs[0]
    setCurrentTab(activeTab.key)
  }, [location, tabs])

  if (adminClientDetails.status === "loading") {
    return (
      <div style={{ paddingTop: "20px" }}>
        <Spin delay={500} />
      </div>
    )
  }

  return (
    <AdminClientDetailsContext.Provider value={adminClientDetails}>
      <AdminClientDetailsDispatchContext.Provider
        value={dispatchAdminClientDetails}
      >
        <Breadcrumb
          style={{ paddingTop: "20px" }}
          items={[
            {
              title: (
                <Link to="../../.." relative="path">
                  Admin
                </Link>
              ),
            },
            {
              title: (
                <Link to="../.." relative="path">
                  Clients
                </Link>
              ),
            },
            {
              title: adminClientDetails.client.name,
            },
          ]}
        />
        <Layout
          style={{
            padding: "20px",
            background: colorBgContainer,
            marginTop: "20px",
          }}
        >
          <Content>
            <Tabs
              defaultActiveKey={currentTab}
              items={tabs}
              onTabClick={(key) => navigate(key)}
            />
            <Outlet />
          </Content>
        </Layout>
      </AdminClientDetailsDispatchContext.Provider>
    </AdminClientDetailsContext.Provider>
  )
}
