import { AwsAssetType } from "../../../../common/aws/types"
import { AssetContentProvider } from "../asset-content"
import { awsAccountAccounts } from "./accounts/accounts"
import { awsAcmCertificates } from "./acm/certificates"
import { awsApigwApiStages } from "./apigw/api-stages"
import { awsApigwApis } from "./apigw/apis"
import { awsApigwRestApiStages } from "./apigw/rest-api-stages"
import { awsApigwRestApis } from "./apigw/rest-apis"
import { awsAutoscalingGroups } from "./autoscaling/autoscaling-groups"
import { awsBackupRecoveryPoints } from "./backup/recovery-points"
import { awsBackupVaults } from "./backup/vaults"
import { awsBeanstalkApplicationVersions } from "./beanstalk/application-versions"
import { awsBeanstalkApplications } from "./beanstalk/applications"
import { awsCloudformationStackSetContent } from "./cloudformation/stack-sets"
import { awsCloudformationStackContent } from "./cloudformation/stacks"
import { awsCloudfrontDistributionContent } from "./cloudfront/distributions"
import { awsCloudtrailTrails } from "./cloudtrail/trails"
import { awsCloudWatchCompositeAlarms } from "./cloudwatch/composite-alarms"
import { awsCloudwatchEventRules } from "./cloudwatch/events"
import { awsCloudwatchLogGroups } from "./cloudwatch/log-groups"
import { awsCloudWatchMetricAlarms } from "./cloudwatch/metric-alarms"
import { awsCognitoIdentityPools } from "./cognito/identity-pools"
import { awsCognitoIdentityProviders } from "./cognito/identity-providers"
import { awsCognitoUserPoolClients } from "./cognito/user-pool-clients"
import { awsCognitoUserPools } from "./cognito/user-pools"
import { awsConfigRules } from "./config/config-rules"
import { awsLandingZones } from "./controltower/landing-zones"
import { awsDynamoDbTables } from "./dynamodb/tables"
import { awsEc2Addresses } from "./ec2/addresses"
import { awsEc2Images } from "./ec2/images"
import { awsEc2Instances } from "./ec2/instances"
import { awsEc2NatGateways } from "./ec2/nat-gateways"
import { awsEc2NetworkAcsl } from "./ec2/network-acls"
import { awsEc2NetworkInterfaces } from "./ec2/network-interfaces"
import { awsEc2ReservedInstances } from "./ec2/reserved-instances"
import { awsEc2Snapshots } from "./ec2/snapshots"
import { awsEc2Volumes } from "./ec2/volumes"
import { awsEcrRepositories } from "./ecr/repositories"
import { awsEcsClustersContent } from "./ecs/clusters"
import { awsEcsTasksContent } from "./ecs/tasks"
import { awsElasticacheCacheClusters } from "./elasticache/cache-clusters"
import { awsElbClassicLoadBalancers } from "./elb/classic-load-balancers"
import { awsElbLoadBalancers } from "./elb/load-balancers"
import { awsEventRules } from "./events/events"
import { awsGuardDutyDetectors } from "./guardduty/guardduty-detectors"
import { awsGuardDutyFindings } from "./guardduty/guardduty-findings"
import { awsIamGroupsContent } from "./iam/groups"
import { awsIamPolicies } from "./iam/policies"
import { awsIamRolesContent } from "./iam/roles"
import { awsIamUsersContent } from "./iam/users"
import { awsLambdaFunctions } from "./lambda/functions"
import { awsOrganizationalUnits } from "./organizations/organizational-units"
import { awsRdsDbClustersContent } from "./rds/db-clusters"
import { awsRdsDbInstancesContent } from "./rds/db-instances"
import { awsRdsDbSubnetGroupsContent } from "./rds/db-subnet-groups"
import { awsRdsReservedDbInstances } from "./rds/reserved-db-instances"
import { awsRoute53HostedZones } from "./route53/hosted-zones"
import { awsRoute53RecordSets } from "./route53/record-sets"
import { awsS3Buckets } from "./s3/buckets"
import { awsSnsTopics } from "./sns/topics"
import { awsSqsQueues } from "./sqs/queues"
import { awsSsmParameters } from "./ssm/parameters"
import { awsStateMachines } from "./step-functions/state-machines"
import { awsVpcNetworkAcls } from "./vpc/network-acls"
import { awsVpcRouteTables } from "./vpc/route-tables"
import { awsVpcSecurityGroupsContent } from "./vpc/security-groups"
import { awsVpcSubnets } from "./vpc/subnets"
import { awsVpcVpcEndpointsContent } from "./vpc/vpc-endpoints"
import { awsVpcVpcsContent } from "./vpc/vpcs"
import { awsClassicWebAcls } from "./waf/classic-web-acls"
import { awsCloudfrontWebAcls } from "./waf/cloudfront-web-acls"
import { awsRegionalWebAcls } from "./waf/regional-web-acls"

export const awsAssetContents = new Map<
  AwsAssetType,
  AssetContentProvider<any, any>
>(
  [
    awsCloudwatchLogGroups,
    awsCloudWatchCompositeAlarms,
    awsCloudWatchMetricAlarms,
    awsApigwApis,
    awsApigwRestApis,
    awsAcmCertificates,
    awsIamUsersContent,
    awsIamGroupsContent,
    awsIamRolesContent,
    awsRdsDbInstancesContent,
    awsRdsReservedDbInstances,
    awsSnsTopics,
    awsSqsQueues,
    awsVpcVpcsContent,
    awsVpcSecurityGroupsContent,
    awsVpcRouteTables,
    awsVpcSubnets,
    awsVpcVpcEndpointsContent,
    awsVpcNetworkAcls,
    awsCloudformationStackContent,
    awsCloudfrontDistributionContent,
    awsCloudtrailTrails,
    awsDynamoDbTables,
    awsS3Buckets,
    awsAccountAccounts,
    awsElbLoadBalancers,
    awsElbClassicLoadBalancers,
    awsRoute53HostedZones,
    awsRoute53RecordSets,
    awsLambdaFunctions,
    awsEc2Volumes,
    awsEc2Addresses,
    awsEc2Instances,
    awsEc2Snapshots,
    awsEc2Images,
    awsEc2NatGateways,
    awsEc2NetworkAcsl,
    awsEc2NetworkInterfaces,
    awsEc2ReservedInstances,
    awsEcsClustersContent,
    awsEcsTasksContent,
    awsEcrRepositories,
    awsEventRules,
    awsElasticacheCacheClusters,
    awsClassicWebAcls,
    awsRegionalWebAcls,
    awsCloudfrontWebAcls,
    awsAutoscalingGroups,
    awsCognitoUserPools,
    awsStateMachines,
    awsCognitoUserPoolClients,
    awsCognitoIdentityProviders,
    awsCognitoIdentityPools,
    awsSsmParameters,
    awsCloudwatchEventRules,
    awsCloudformationStackSetContent,
    awsApigwApiStages,
    awsApigwRestApiStages,
    awsIamPolicies,
    awsBeanstalkApplications,
    awsBeanstalkApplicationVersions,
    awsBackupRecoveryPoints,
    awsBackupVaults,
    awsOrganizationalUnits,
    awsLandingZones,
    awsRdsDbClustersContent,
    awsRdsDbSubnetGroupsContent,
    awsGuardDutyFindings,
    awsGuardDutyDetectors,
    awsConfigRules,
  ].map((c) => [c.type, c]),
)
