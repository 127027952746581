import { Layout, Menu, MenuProps, theme } from "antd"
import React, { useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

const { Content, Sider } = Layout

export const AwsAuditContent: React.FC = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [currentSideMenu, setCurrentSideMenu] = useState<string>()

  const {
    token: { colorBgContainer },
  } = theme.useToken()

  const sideMenuItems: MenuProps["items"] = [
    {
      key: "findings",
      label: "Findings",
      title: "Findings",
      onClick: () => navigate("findings"),
    },
    {
      key: "settings",
      label: "Settings",
      title: "Settings",
      onClick: () => navigate("settings"),
    },
  ]

  useEffect(() => {
    if (location.pathname.endsWith("/findings")) {
      setCurrentSideMenu("findings")
    } else if (location.pathname.endsWith("/settings")) {
      setCurrentSideMenu("settings")
    } else {
      setCurrentSideMenu(undefined)
    }
  }, [location])

  return (
    <Layout
      style={{
        padding: "0",
        background: colorBgContainer,
        marginTop: "10px",
      }}
    >
      <Sider style={{ background: colorBgContainer }} width={200}>
        <Menu
          mode="inline"
          style={{ height: "100%" }}
          items={sideMenuItems}
          selectedKeys={currentSideMenu ? [currentSideMenu] : []}
        />
      </Sider>
      <Content style={{ padding: "0 24px", minHeight: 280 }}>
        <Outlet />
      </Content>
    </Layout>
  )
}
