import { Descriptions } from "antd"
import { toHeaderCase } from "js-convert-case"
import { PropsWithChildren, ReactNode } from "react"

interface DescriptionProps<T> {
  key: keyof T
  label?: string
}

export type DescriptionSpec<T> = DescriptionProps<T> | keyof T

interface SimpleDescriptionsProps<T> {
  value: T
  properties: ReadonlyArray<DescriptionSpec<T>>
}

const renderValue = (value: unknown): ReactNode => {
  if (value === undefined || value === null) {
    return "-"
  }

  if (value === true) {
    return "Yes"
  }

  if (value === false) {
    return "No"
  }

  if (Array.isArray(value)) {
    return value.join(", ")
  }

  return String(value)
}

export function SimpleDescriptions<T>({
  properties,
  value,
}: PropsWithChildren<SimpleDescriptionsProps<T>>) {
  return (
    <Descriptions layout="horizontal" column={1}>
      {properties.map((p) => {
        const spec = (
          typeof p === "string" ? { key: p } : p
        ) as DescriptionProps<T>

        return (
          <Descriptions.Item
            key={String(spec.key)}
            label={spec.label ?? toHeaderCase(String(spec.key))}
            labelStyle={{ minWidth: "300px" }}
          >
            {renderValue(value[spec.key])}
          </Descriptions.Item>
        )
      })}
    </Descriptions>
  )
}
