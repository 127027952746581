import { Button, Form, Input } from "antd"
import React, { useContext, useState } from "react"
import { UpdateClientRequest } from "../../../../common/model/api"
import { ApiContext } from "../../ApiContext"
import { SimpleDescriptions } from "../../components/SimpleDescriptions"
import {
  AdminClientDetailsContext,
  AdminClientDetailsDispatchContext,
} from "./ClientAdminContext"

export const ClientBasicInfoContent: React.FC = () => {
  const api = useContext(ApiContext)
  const { client } = useContext(AdminClientDetailsContext)
  const dispatchClientDetails = useContext(AdminClientDetailsDispatchContext)
  const [mode, setMode] = useState("view")

  const onFinish = (request: UpdateClientRequest) => {
    api.updateClient(client.id, request).then((data) => {
      dispatchClientDetails({ client: data, action: "ready" })
      setMode("view")
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }

  if (!client) {
    return <div></div>
  }

  if (mode === "view") {
    return (
      <div>
        <SimpleDescriptions properties={["id", "name"]} value={client} />
        <Button onClick={() => setMode("edit")}>Edit</Button>
      </div>
    )
  }

  if (mode === "edit") {
    return (
      <div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ name: client.name }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<UpdateClientRequest>
            label="Name"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button type="link" onClick={() => setMode("view")}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }

  return <div>Error</div>
}
