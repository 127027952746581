import { HostedZoneResource } from "../../../../../common/model/data/aws-data"
import { assetContent, basicAssetDetails } from "../../asset-content"

export const awsRoute53HostedZones = assetContent<
  HostedZoneResource,
  HostedZoneResource
>({
  type: "route53/hosted-zone",
  list: {
    exclude: ["CallerReference"],
  },
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["Config"],
      additional: [
        {
          label: "Comment",
          value: () => asset.Config.Comment,
        },
        {
          label: "Private Zone",
          value: () => asset.Config.PrivateZone,
        },
      ],
    }),
  ],
})
