import { App, Button, Space, Table } from "antd"
import dt from "date-and-time"
import prettyMilliseconds from "pretty-ms"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { SyncJobsListDto } from "../../../common/model/api"
import { ApiContext } from "../ApiContext"

const getColumns = (
  onRetry: (clientId: string, timestamp: number) => void,
  onDelete: (clientId: string, timestamp: number) => void,
) => [
  {
    title: "Actions",
    key: "action",
    render: (text, record) => {
      return (
        <Space>
          <Link to={`${record.clientId}/${record.timestamp}`}>View</Link>
          <Button
            type="link"
            disabled={
              record.status !== "failed" && record.status !== "cancelled"
            }
            onClick={() => onRetry(record.clientId, record.timestamp)}
          >
            Retry
          </Button>
          <Button
            type="link"
            onClick={() => onDelete(record.clientId, record.timestamp)}
          >
            Delete
          </Button>
        </Space>
      )
    },
  },
  {
    title: "Client Id",
    dataIndex: "clientId",
    key: "clientId",
  },
  {
    title: "Timestamp",
    dataIndex: "timestamp",
    key: "timestamp",
  },
  {
    title: "Start Time",
    dataIndex: "startTime",
    render: (timestamp) =>
      dt.format(new Date(timestamp * 1000), "YYYY-MM-DD HH:mm"),
  },
  {
    title: "End Time",
    dataIndex: "endTime",
    render: (timestamp) =>
      timestamp
        ? dt.format(new Date(timestamp * 1000), "YYYY-MM-DD HH:mm")
        : "-",
  },
  {
    title: "Elapsed Time",
    render: (timestamp, record) =>
      record.endTime
        ? prettyMilliseconds((record.endTime - record.startTime) * 1000)
        : "-",
  },
  {
    title: "Stage",
    dataIndex: "stage",
    key: "stage",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Reason",
    dataIndex: "reason",
    key: "reason",
  },
  {
    title: "Data partitions",
    dataIndex: "collectDataPartitions",
    key: "collectDataPartitions",
  },
  {
    title: "Aggregate partitions",
    dataIndex: "aggregateDataPartitions",
    key: "aggregateDataPartitions",
  },
  {
    title: "Audit partitions",
    dataIndex: "auditDataPartitions",
    key: "auditDataPartitions",
  },
  {
    title: "Content partitions",
    dataIndex: "buildContentPartitions",
    key: "buildContentPartitions",
  },
]

export const SyncJobsPage: React.FC = () => {
  const { notification } = App.useApp()
  const api = useContext(ApiContext)
  const [syncJobs, setSyncJobs] = useState<SyncJobsListDto>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    api
      .listSyncJobs()
      .then((data) => {
        setSyncJobs(data.sort((a, b) => b.timestamp - a.timestamp))
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }, [api])

  const onRetry = (clientId: string, timestamp: number) => {
    api
      .retrySyncJob(clientId, timestamp)
      .then(() => {
        notification.success({ message: "Sync job retry initiated" })
        api
          .listSyncJobs()
          .then((data) => {
            setSyncJobs(data.sort((a, b) => b.timestamp - a.timestamp))
          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((e) => {
        console.error(e)
        notification.error({
          message: "Failed to retry sync job",
          description: e.message,
        })
      })
  }

  const onDelete = (clientId: string, timestamp: number) => {
    api
      .deleteSyncJob(clientId, timestamp)
      .then(() => {
        notification.success({ message: "Sync job deleted" })
        api
          .listSyncJobs()
          .then((data) => {
            setSyncJobs(data.sort((a, b) => b.timestamp - a.timestamp))
          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((e) => {
        console.error(e)
        notification.error({
          message: "Failed to delete sync job",
          description: e.message,
        })
      })
  }

  const columns = getColumns(onRetry, onDelete)

  return (
    <div>
      <h1>Sync jobs</h1>
      <Table
        loading={loading}
        rowKey={(record) => record.clientId + record.timestamp}
        columns={columns}
        dataSource={syncJobs}
        style={{ paddingTop: "20px" }}
        pagination={{ hideOnSinglePage: true, pageSize: 20 }}
      />
    </div>
  )
}
