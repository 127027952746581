import dt from "date-and-time"
import prettyBytes from "pretty-bytes"
import { LogGroupAsset } from "../../../../../common/model/data/aws-data"
import { assetContent, basicAssetDetails } from "../../asset-content"

export const awsCloudwatchLogGroups = assetContent<
  LogGroupAsset,
  LogGroupAsset
>({
  type: "cloudwatch-logs/log-group",
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["creationTime", "storedBytes"],
      additional: [
        {
          label: "Creation Time",
          value: () =>
            dt.format(new Date(asset.creationTime), "YYYY-MM-DD HH:mm"),
        },
        {
          label: "Stored Size",
          value: () => prettyBytes(asset.storedBytes, { bits: true }),
        },
      ],
    }),
  ],
})
