import { EcsClusterAsset } from "../../../../../common/model/data/aws-data"
import { assetContent } from "../../asset-content"

export const awsEcsClustersContent = assetContent<
  EcsClusterAsset,
  EcsClusterAsset
>({
  type: "ecs/cluster",
  details: (asset) => [
    {
      asset,
      title: "Basic",
      items: [
        {
          label: "Name",
          value: "clusterName",
        },
      ],
    },
  ],
})
