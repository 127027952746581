import _ from "lodash"
import {
  IamUserListAsset,
  IamUserResource,
} from "../../../../../common/model/data/aws-data"
import {
  assetContent,
  basicAssetDetails,
  tableSection,
} from "../../asset-content"

export const awsIamUsersContent = assetContent<
  IamUserListAsset,
  IamUserResource
>({
  type: "iam/user",
  details: (asset) => [
    basicAssetDetails({
      asset,
      exclude: ["accessKeys", "groups", "mfaDevices"],
    }),
    tableSection({ asset, title: "Groups", data: () => asset.groups }),
    tableSection({ asset, title: "MFA Devices", data: () => asset.mfaDevices }),
    tableSection({
      asset,
      title: "Access Keys",
      enabled: !_.isEmpty(asset.accessKeys),
      data: () =>
        asset.accessKeys.map((a) => {
          return {
            AccessKeyId: a.accessKeyMetadata.AccessKeyId,
            Status: a.accessKeyMetadata.Status,
            CreateDate: a.accessKeyMetadata.CreateDate,
            LastUsedDate: a.accessKeyLastUsed?.LastUsedDate,
            LastUsedService: a.accessKeyLastUsed?.ServiceName,
            LastUsedRegion: a.accessKeyLastUsed?.Region,
          }
        }),
    }),
    tableSection({
      asset,
      title: "Policies",
      data: () => asset.policies,
      columns: [
        { label: "Policy Name", value: (a) => a.PolicyName },
        {
          label: "Policy Document",
          value: (a) => {
            return JSON.stringify(
              JSON.parse(decodeURIComponent(a.policyDocument)),
              undefined,
              2,
            )
          },
          displayValue: (v: string) => <pre>{v}</pre>,
        },
      ],
    }),
  ],
})
