import {
  ClientDto,
  CreateClientRequest,
  UpdateClientRequest,
} from "../../../common/model/api"
import { HttpClient } from "./http-client"
import { extractData } from "./response-mappers"

const CLIENTS_PATH = "/api/clients"
export interface ClientsApi {
  listClients: () => Promise<ReadonlyArray<ClientDto>>
  getClient: (id: string) => Promise<ClientDto>
  createClient: (request: CreateClientRequest) => Promise<ClientDto>
  updateClient: (id: string, request: UpdateClientRequest) => Promise<ClientDto>
}

export const clientsApiUsing = ({
  get,
  post,
  put,
}: HttpClient): ClientsApi => ({
  listClients: () => get(CLIENTS_PATH).then(extractData),
  getClient: (id) => get(`${CLIENTS_PATH}/${id}`).then(extractData),
  createClient: (request) => post(CLIENTS_PATH, request).then(extractData),
  updateClient: (id, request) =>
    put(`${CLIENTS_PATH}/${id}`, request).then(extractData),
})
