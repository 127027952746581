import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { App, Button, Descriptions, List } from "antd"
import { FC, ReactNode, useContext } from "react"
import {
  AssetGroupRule,
  AwsSimpleAssetGroupRule,
  AwsSimpleCondition,
} from "../../../../common/model/asset-group-rules"
import { ApiContext } from "../../ApiContext"
import {
  DescriptionSpec,
  SimpleDescriptions,
} from "../../components/SimpleDescriptions"

export const renderOperator = (condition: AwsSimpleCondition): string => {
  switch (condition.operator) {
    case "equals":
      return condition.values
        .map((value) => `${condition.property} == '${value}'`)
        .join(" OR ")
    case "exists":
      return `${condition.property} exists`
    default:
      throw new Error(`Unknown operator: ${condition.operator}`)
  }
}

const renderCondition = (
  condition: AwsSimpleCondition,
  index: number,
): ReactNode => {
  return (
    <Descriptions.Item
      key={index}
      labelStyle={{ minWidth: "300px" }}
      label="Conditions"
    >
      {renderOperator(condition)}
    </Descriptions.Item>
  )
}

type AwsSimpleAssetGroupRuleContentProps = {
  rule: AwsSimpleAssetGroupRule
  onRuleDeleted: () => Promise<void>
  openEditDrawer: (rule: AssetGroupRule) => void
}

const AwsSimpleAssetGroupRuleContent: FC<
  AwsSimpleAssetGroupRuleContentProps
> = ({ rule, onRuleDeleted, openEditDrawer }) => {
  const { modal, notification } = App.useApp()
  const api = useContext(ApiContext)

  const properties: DescriptionSpec<AwsSimpleAssetGroupRule>[] = [
    "id",
    "clientId",
    "product",
    "type",
    "groups",
  ]

  const showConfirmDeleteModal = async () => {
    const confirmed = await modal.confirm({
      title: "Delete asset group rule",
      content: "Delete this asset group rule?",
    })

    if (confirmed) {
      api
        .deleteAssetGroupRule(rule.clientId, rule.id)
        .then(() => {
          notification.success({ message: "Asset group rule deleted" })
          onRuleDeleted()
        })
        .catch((e) => {
          console.error(e)
          notification.error({
            message: "Failed to delete asset group rule",
            description: e.message,
          })
        })
    }
  }

  return (
    <List.Item
      key={rule.id}
      actions={[
        <Button
          type="text"
          title="Edit"
          onClick={() => openEditDrawer(rule)}
          icon={<EditOutlined />}
        />,
        <Button
          type="text"
          title="Delete"
          onClick={showConfirmDeleteModal}
          icon={<DeleteOutlined />}
        />,
      ]}
    >
      <SimpleDescriptions properties={properties} value={rule} />
      <Descriptions layout="horizontal" column={1}>
        {rule.conditions.map((condition, index) =>
          renderCondition(condition, index),
        )}
      </Descriptions>
    </List.Item>
  )
}

interface RenderAssetGroupRuleProps {
  rule: AssetGroupRule
  onRuleDeleted: () => Promise<void>
  openEditDrawer: (rule: AssetGroupRule) => void
}

export const renderAssetGroupRule = ({
  rule,
  onRuleDeleted,
  openEditDrawer,
}: RenderAssetGroupRuleProps): ReactNode => {
  if (rule.product === "aws") {
    if (rule.type === "simple") {
      return (
        <AwsSimpleAssetGroupRuleContent
          rule={rule as AwsSimpleAssetGroupRule}
          onRuleDeleted={onRuleDeleted}
          openEditDrawer={openEditDrawer}
        />
      )
    } else {
      throw new Error(
        `Unknown asset group rule type '${rule.type}' for product '${rule.product}'`,
      )
    }
  } else {
    throw new Error(`Unknown asset group rule product '${rule.product}'`)
  }
}
