import { ParameterAsset } from "../../../../../common/model/data/aws-data"
import { assetContent } from "../../asset-content"

export const awsSsmParameters = assetContent<ParameterAsset, ParameterAsset>({
  type: "ssm/parameter",
  list: {
    exclude: ["Description", "LastModifiedUser"],
    filtered: ["Type", "Tier", "DataType"],
  },
})
