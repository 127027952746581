import { SyncJobDto, SyncJobsListDto } from "../../../common/model/api"
import { HttpClient } from "./http-client"
import { extractData } from "./response-mappers"

export interface SyncJobApi {
  getSyncJob: (clientId: string, timestamp: string) => Promise<SyncJobDto>
  listSyncJobs: () => Promise<SyncJobsListDto>
  startSyncJob: (clientId: string) => Promise<any>
  retrySyncJob: (clientId: string, timestamp: number) => Promise<any>
  deleteSyncJob: (clientId: string, timestamp: number) => Promise<any>
}

export const syncJobApiUsing = ({
  get,
  post,
  put,
  del,
}: HttpClient): SyncJobApi => ({
  getSyncJob: (clientId: string, timestamp: string) =>
    get(`/api/clients/${clientId}/sync-jobs/${timestamp}`).then(extractData),
  listSyncJobs: () => get(`/api/sync-jobs`).then(extractData),
  startSyncJob: (clientId) => post(`/api/clients/${clientId}/sync-jobs`),
  deleteSyncJob: (clientId, timestamp) =>
    del(`/api/clients/${clientId}/sync-jobs/${timestamp}`),
  retrySyncJob: (clientId, timestamp) =>
    put(`/api/clients/${clientId}/sync-jobs/${timestamp}`),
})
