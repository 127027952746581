import { Button, Table } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { AssetGroupRule } from "../../../../common/model/asset-group-rules"
import { ApiContext } from "../../ApiContext"

const columns = [
  {
    title: "Action",
    key: "action",
    render: (text, record) => <Link to={`${record.id}/basic-info`}>View</Link>,
  },
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Client Id",
    dataIndex: "clientId",
    key: "clientId",
  },
]

export const AssetGroupRulesPage: React.FC = () => {
  const api = useContext(ApiContext)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [assetGroupRules, setAssetGroupRules] = useState<
    ReadonlyArray<AssetGroupRule>
  >([])

  useEffect(() => {
    setLoading(true)
    api
      .listAssetGroupRules()
      .then((data) => {
        setAssetGroupRules(data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }, [api])

  return (
    <div>
      <h1>Asset Group Rules</h1>
      <Table
        rowKey={(record) => record.id}
        loading={loading}
        columns={columns}
        dataSource={assetGroupRules}
        style={{ paddingTop: "20px" }}
        pagination={{ hideOnSinglePage: true, pageSize: 20 }}
      />
      <div style={{ paddingTop: "20px" }}>
        <Button onClick={() => navigate("create")}>
          Create Asset Group Rule
        </Button>
      </div>
    </div>
  )
}
