import { Alert } from "antd"
import React from "react"

export const ErrorFallback: React.FC<{
  error: any
  resetErrorBoundary: any
}> = ({ error, resetErrorBoundary }) => {
  const description = (
    <>
      <p>{error.message}</p>
      <pre>{error.stack}</pre>
    </>
  )
  return (
    <div>
      <Alert
        message="Something went wrong"
        description={description}
        type="error"
        showIcon
      />
    </div>
  )
}
