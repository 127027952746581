import { App as AntApp, ConfigProvider, theme } from "antd"
import React, { useState } from "react"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"
import { AppContent } from "./AppContent"
import { AuthenticatedContent } from "./AuthenticatedContent"
import { AdminPage } from "./admin/AdminPage"
import { ClientsPage } from "./admin/ClientsPage"
import { CreateClientPage } from "./admin/CreateClientPage"
import { CreateUserPage } from "./admin/CreateUserPage"
import { SyncJobPage } from "./admin/SyncJobPage"
import { SyncJobsPage } from "./admin/SyncJobsPage"
import { UserPage } from "./admin/UserPage"
import { UsersPage } from "./admin/UsersPage"
import { AssetDataSourcesPage } from "./admin/asset-data-sources/AssetDataSourcesPage"
import { AssetGroupRulesPage } from "./admin/asset-group-rules/AssetGroupRulesPage"
import { BillingConfigsPage } from "./admin/billing-configs/BillingConfigsPage"
import { ClientAssetDataSourcesContent } from "./admin/client/ClientAssetDataSourcesContent"
import { ClientAssetGroupRulesContent } from "./admin/client/ClientAssetGroupRulesContent"
import { ClientBasicInfoContent } from "./admin/client/ClientBasicInfoContent"
import { ClientDetailsContainerContent } from "./admin/client/ClientDetailsContainerContent"
import { AssetDetailsAuditContent } from "./clients/AssetDetailsAuditContent"
import { AssetDetailsContainerContent } from "./clients/AssetDetailsContainerContent"
import { AssetDetailsContent } from "./clients/AssetDetailsContent"
import { AssetDetailsCostsContent } from "./clients/AssetDetailsCostsContent"
import { AssetDetailsDependantsContent } from "./clients/AssetDetailsDependantsContent"
import { AssetDetailsDependenciesContent } from "./clients/AssetDetailsDependenciesContent"
import { AssetDetailsRawContent } from "./clients/AssetDetailsRawContent"
import { AssetsFamilyContent } from "./clients/AssetsFamilyContent"
import { AssetsListContainerContent } from "./clients/AssetsListContainerContent"
import { AssetsListContent } from "./clients/AssetsListContent"
import { AwsProductContent } from "./clients/AwsProductContent"
import { AwsProductSummaryPage } from "./clients/AwsProductSummaryPage"
import { ClientContent } from "./clients/ClientContent"
import { ClientSelectorContent } from "./clients/ClientSelectorPage"
import { ClientsContent } from "./clients/ClientsContent"
import { ProductSelectorContent } from "./clients/ProductSelectorPage"
import { AwsAssetsContent } from "./clients/assets/AwsAssetsContent"
import { AwsAuditContent } from "./clients/audit/AwsAuditContent"
import { AwsAuditFindingsContent } from "./clients/audit/AwsAuditFindingsContent"
import { AwsAuditSettingsContent } from "./clients/audit/AwsAuditSettingsContent"
import { AwsAuditSummaryContent } from "./clients/audit/AwsAuditSummaryContent"
import { ThemeContext, ThemeId } from "./context/ClientsContext"
import { storage } from "./storage/Storage"

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppContent />,
  },
  {
    path: "/app",
    element: <AuthenticatedContent />,
    children: [
      {
        path: "clients",
        element: <ClientsContent />,
        children: [
          {
            element: <ClientSelectorContent />,
            index: true,
          },
          {
            path: ":clientId",
            element: <ClientContent />,
            children: [
              { element: <ProductSelectorContent />, index: true },
              {
                path: "aws-audit",
                element: <AwsAuditContent />,
                children: [
                  { index: true, element: <AwsAuditSummaryContent /> },
                  { path: "findings", element: <AwsAuditFindingsContent /> },
                  { path: "settings", element: <AwsAuditSettingsContent /> },
                ],
              },
              {
                path: "aws-assets",
                element: <AwsAssetsContent />,
              },
              {
                path: "aws",
                element: <AwsProductContent />,
                children: [
                  { index: true, element: <AwsProductSummaryPage /> },
                  {
                    path: ":assetFamily",
                    element: <AssetsFamilyContent />,
                  },
                  {
                    path: ":assetFamily/:assetType",
                    element: <AssetsListContainerContent />,
                    children: [
                      {
                        index: true,
                        element: <AssetsListContent />,
                      },
                      {
                        path: ":assetId",
                        element: <AssetDetailsContainerContent />,
                        children: [
                          {
                            index: true,
                            element: <Navigate to={"details"} />,
                          },
                          {
                            path: "details",
                            element: <AssetDetailsContent />,
                          },
                          {
                            path: "dependants",
                            element: <AssetDetailsDependantsContent />,
                          },
                          {
                            path: "dependencies",
                            element: <AssetDetailsDependenciesContent />,
                          },
                          {
                            path: "cost",
                            element: <AssetDetailsCostsContent />,
                          },
                          {
                            path: "audit",
                            element: <AssetDetailsAuditContent />,
                          },
                          {
                            path: "raw",
                            element: <AssetDetailsRawContent />,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "admin",
        element: <AdminPage />,
      },
      {
        path: "admin/sync-jobs",
        element: <SyncJobsPage />,
      },
      {
        path: "admin/sync-jobs/:clientId/:timestamp",
        element: <SyncJobPage />,
      },
      {
        path: "admin/users",
        element: <UsersPage />,
      },
      {
        path: "admin/users/create",
        element: <CreateUserPage />,
      },
      {
        path: "admin/users/:userId",
        element: <UserPage />,
      },
      {
        path: "admin/clients",
        element: <ClientsPage />,
      },
      {
        path: "admin/clients/:clientId",
        element: <ClientDetailsContainerContent />,
        children: [
          {
            children: [
              {
                index: true,
                element: <Navigate to={"basic-info"} />,
              },
              {
                path: "basic-info",
                element: <ClientBasicInfoContent />,
              },
              {
                path: "asset-data-sources",
                element: <ClientAssetDataSourcesContent />,
              },
              {
                path: "asset-group-rules",
                element: <ClientAssetGroupRulesContent />,
              },
            ],
          },
        ],
      },
      {
        path: "admin/clients/create",
        element: <CreateClientPage />,
      },
      {
        path: "admin/asset-data-sources",
        element: <AssetDataSourcesPage />,
      },
      {
        path: "admin/billing-configs",
        element: <BillingConfigsPage />,
      },
      {
        path: "admin/asset-group-rules",
        element: <AssetGroupRulesPage />,
      },
    ],
  },
])

export const App: React.FC = () => {
  const { defaultAlgorithm, darkAlgorithm } = theme

  const [themeId, setThemeId] = useState<ThemeId>(storage.getThemeId())

  return (
    <AntApp>
      <ThemeContext.Provider value={{ themeId, setThemeId }}>
        <ConfigProvider
          theme={{
            algorithm: themeId === "dark" ? darkAlgorithm : defaultAlgorithm,
          }}
        >
          <RouterProvider router={router} />
        </ConfigProvider>
      </ThemeContext.Provider>
    </AntApp>
  )
}
